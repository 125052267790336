import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

/**
 * @title Chips Autocomplete
 */
@Component({
  selector: 'chip-multiselect',
  templateUrl: './chip-multiselect.component.html',
  styleUrls: ['./chip-multiselect.component.scss']
})
export class ChipMultiselectComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  formCtrl = new UntypedFormControl();

  @ViewChild('optionInput') optionInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @Input() selectedOptions = [];
  @Input() allOptions = [];
  @Input() onChange = () => { };
  @Input() name = 'option';
  filteredOptions: Observable<string[]>;

  constructor() {
  }

  ngOnInit(): void {
    this.filteredOptions = this.formCtrl.valueChanges.pipe(
      startWith(null),
      map((option: string | null) => option ? this._filter(option) : this.allOptions.slice()))

  }

  recalculateAuto() {
    this.formCtrl.enable();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our option

    if (this.allOptions.indexOf(value.toUpperCase()) === -1 || this.selectedOptions.indexOf(value.toUpperCase()) !== -1) {
      return;
    }

    if ((value || '').trim()) {
      this.selectedOptions.push(value.trim().toUpperCase());
      this.onChange();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    (<HTMLElement>document.activeElement).blur();

    this.formCtrl.setValue(null);
  }

  remove(opt: string): void {
    const index = this.selectedOptions.indexOf(opt);

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.onChange();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedOptions.push(event.option.viewValue);
    this.optionInput.nativeElement.value = '';
    this.formCtrl.setValue(null);
    (<HTMLElement>document.activeElement).blur();
    this.onChange();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allOptions.filter(opt => opt.toLowerCase().indexOf(filterValue) === 0);
  }
}
