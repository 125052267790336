

import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpClientModule,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DealerMappingService {
  // private dealerUrl = 'http://localhost:1433/api/dealer';
  private dealerUrl: string;
  apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
    this.dealerUrl = this.apiUrl + "api/dealer";
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
  };
  getDealerData(): Observable<any> {
    return this.http.get<any>(this.dealerUrl);
  }

  insertRecords(records: any): Observable<any> {
    return this.http.post<any>(this.dealerUrl, records);
  }

  deleteRecord(record: number[]): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: {
        Id: record,
      },
    };
    return this.http.delete<any>(this.dealerUrl, options);
  }
  // fileUploadService(file): Observable<any> {
  //   const formData = new FormData();
  //   formData.append(
  //     "myfile",
  //     file,
  //     file.name
  //   );
  //   console.log('file', formData);
  //   const headers = new Headers({
  //     'Content-Type': 'multipart/form-data',
  //     'Access-Control-Allow-Credentials': 'true'
  //   });
  //   return this.http.post<any>('http://localhost:1433/api/trim_fileupload', formData);
  // }

  // method: 'post',
  // url: 'https://www.stellantisdigitalhub.com/api/v1/apply',
  // data: jobApplicationForm,
  //  headers :{'Content-Type': 'multipart/form-data' }

}
