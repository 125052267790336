<footer>
  <span class="footerSpan">
    &copy; {{ currentYear }} STELLANTIS,
    <span>{{ env }} </span>
    <span>{{ version }}</span>
  </span>
  <span class="webView">

    <a *ngIf="!isMopar" class="provideFeedback"
      href="https://st11798p010.blob.core.windows.net/$web/assets/BusinessAnalyticsToolkit.pdf"
      target='_blank'>Reference
      Guide</a>
    <a *ngIf="!isMopar" class="provideFeedback"
      href="https://st11798p010.blob.core.windows.net/$web/assets/user_guide.pdf" target='_blank'>User Guide </a>
    <a *ngIf="!isMopar" class="provideFeedback"
      href="https://st11798p010.blob.core.windows.net/$web/assets/release_notes/dai_release_notes.pdf"
      target='_blank'>Release Notes</a>


    <span *ngIf="isAdmin" class="provideFeedback" (click)="setPopupText()"> Set Popup Text</span>
    <span *ngIf="isAdmin" class="addReport" (click)="addReport()"> Add Report
    </span>
    <span *ngIf="isAdmin && !isMopar" class="addReport" (click)="goldenTrim()"> Golden Trim</span>
    <span *ngIf="isAdmin && !isMopar" class="addReport" (click)="statusReport()"> Pipeline Status</span>
    <span class="addReport" *ngIf="route !== '/admin' && isDAITeam" (click)="gotoAdmin()"> Admin</span>
    <span class="addReport" *ngIf="route  == '/admin' && isDAITeam" (click)="gotoDashBoard()"> Home</span>
    <span class="provideFeedback" (click)="provideFeedback()"> Submit Ticket </span>

  </span>


  <div class="mobileView">
    <div class="firstDiv">
      <a *ngIf="!isMopar" class="provideFeedback"
        href="https://st11798p010.blob.core.windows.net/$web/assets/BusinessAnalyticsToolkit.pdf"
        target='_blank'>Reference
        Guide</a>
      <a *ngIf="!isMopar" class="provideFeedback"
        href="https://st11798p010.blob.core.windows.net/$web/assets/user_guide.pdf" target='_blank'>User Guide </a>
      <a *ngIf="!isMopar" class="provideFeedback"
        href="https://st11798p010.blob.core.windows.net/$web/assets/release_notes/dai_release_notes.pdf"
        target='_blank'>Release Notes</a>
    </div>
    <div class="secondDiv">
      <span *ngIf="isAdmin" class="provideFeedback" (click)="setPopupText()"> Set Popup Text</span>
      <span *ngIf="isAdmin" class="addReport" (click)="addReport()"> Add Report
      </span>
      <span *ngIf="isAdmin && !isMopar" class="addReport" (click)="statusReport()"> Pipeline Status</span>
      <span class="provideFeedback" (click)="goldenTrim()"> Golden Trim</span>

      <span class="provideFeedback" (click)="provideFeedback()"> Submit Ticket </span>

    </div>
  </div>
  <div class="footerText">
    &copy; {{ currentYear }} STELLANTIS, {{ env }} {{ version }}
  </div>
</footer>