<div class="model-header" class="modalClass">
  <button type="button" class="close" [mat-dialog-close]>&times;</button>
  <h1 mat-dialog-title>Are you sure you want to delete?</h1>
  <div *ngIf="data.sentIds && data.page == 'gold_std'" class="table_scroll">
    <table>
      <tr>
        <th>S.No</th>
        <th>c_mfg_recode</th>
        <th>c_make_recode</th>
        <th>c_mod_recode</th>
        <th>c_seg_recode</th>
        <th>c_trim</th>
      </tr>
      <tr *ngFor="let obj of data.sentIds;let index = index">
        <td>{{index+1}}</td>
        <td>{{obj.c_mfg_recode}}</td>
        <td>{{obj.c_make_recode}}</td>
        <td>{{obj.c_mod_recode}}</td>
        <td>{{obj.c_seg_recode}}</td>
        <td>{{obj.c_trim}}</td>
      </tr>

    </table>
  </div>
  <div *ngIf="data.sentIds && data.page == 'gold_map'" class="table_scroll">
    <table>
      <tr>
        <th>S.No</th>
        <th>source_make</th>
        <th>source_model</th>
        <th>gold_standard_make</th>
        <th>gold_standard_model</th>
      </tr>
      <tr *ngFor="let obj of data.sentIds;let index = index">
        <td>{{index+1}}</td>
        <td>{{obj.source_make}}</td>
        <td>{{obj.source_model}}</td>
        <td>{{obj.gold_standard_make}}</td>
        <td>{{obj.gold_standard_model}}</td>
      </tr>

    </table>
  </div>
  <div *ngIf="data.sentIds && data.page == 'dealer_map'" class="table_scroll">
    <table>
      <tr>
        <th>S.No</th>
        <th>I_DLR_DIM</th>
        <th>IDR_dealername</th>
        <th>dealer_id_is</th>
        <th>seller_name_ss</th>
      </tr>
      <tr *ngFor="let obj of data.sentIds;let index = index;">

        <td>{{index+1}}</td>
        <td>{{obj.I_DLR_DIM}}</td>
        <td>{{obj.IDR_dealername}}</td>
        <td>{{obj.dealer_id_is}}</td>
        <td>{{obj.seller_name_ss}}</td>
      </tr>

    </table>
  </div>

  <div mat-dialog-actions class="centerClass">
    <button mat-button [mat-dialog-close]>Cancel</button>
    <button class="delete-button" mat-button [mat-dialog-close]="true">Delete</button>
  </div>
</div>