<h2 class="workstream-header">Adobe Archive</h2>

<div *ngIf="!environment.mopar" [hidden]="leftIframeIsOpen || rightIframeIsOpen" class="workstream-grid">
  <div class="workstream">
    <div *ngFor="let date of jeepDates" class="table">
      <label *ngIf="date.monthHeader">{{date.text}}</label>
      <a *ngIf="!date.monthHeader" href={{date.suffix}}>{{date.text}}</a>
      <br>
    </div>
  </div>
  <div class="workstream">
    <div *ngFor="let date of ramDates" class="table">
      <label *ngIf="date.monthHeader">{{date.text}}</label>

      <a href={{date.suffix}} *ngIf="!date.monthHeader">{{date.text}}</a>
      <br>
    </div>
  </div>
</div>