import { Injectable, Injector } from '@angular/core';
import { Subject, Observable, ReplaySubject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retryWithBackoff } from 'src/rxjs-helpers';
import { catchError } from 'rxjs/operators';
import { UnderperformingKpis } from '../models/underperformingKpis';

@Injectable({
  providedIn: 'root'
})
export class KPIService {

  name: string;
  userID: string;
  userEmail: string;
  brand: string;
  apiUrl: string;

  link: string;
  prod: boolean;
  pinMarketCache: any = {};
  pinMarketLeaseCache: any = {};
  pinMarketRetailCache: any = {};

  competitiveShareCache: any = {};
  competitiveLeaseShareCache: any = {};
  competitiveRetailShareCache: any = {};
  competitiveShareCacheByPinMkt: any = {};
  competitiveRetailShareCacheByPinMkt: any = {};
  competitiveLeaseShareCacheByPinMkt: any = {};


  leadsBreakdownCache: any = {};


  public name$ = new Subject<string>();
  public userID$ = new Subject<string>();
  public filter$ = new ReplaySubject<any>();

  private http = this.injector.get(HttpClient);

  constructor(private injector: Injector) {
    this.apiUrl = environment.apiUrl;
  }
  escape(s) {
    return s ? s.replace(/\\/g, '\\\\').replace(/\n/g, '\\n').replace(/\t/g, '\\t').replace(/\v/g, '\\v').replace(/'/g, "\\'").replace(/"/g, '\\"').replace(/[\x00-\x1F\x80-\x9F]/g, hex).split('/').join('2F2F') : s;
    function hex(c) { var v = '0' + c.charCodeAt(0).toString(16); return '\\x' + v.substr(v.length - 2); }
  }
  setFilter(brand, nameplate, bc, includeEmail) {
    if (brand && nameplate && bc) {
      this.filter$.next({ brand, nameplate, bc, includeEmail })
      return this.getNameplateUnderperformingKPIs(nameplate, bc);
    }
  }

  getBCs(): Observable<any[]> {
    const email = JSON.parse(localStorage.getItem('msalUser')).idTokenClaims.email;
    return this.http.get<any[]>(environment.apiUrl + `api/bcs/${email}`);
  }

  getNameplateTotalKPIs(nameplate, dma = ''): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + `api/kpi/totals/${nameplate}/${dma}`);
  }

  getNameplateUnderperformingKPIs(nameplate, dma = ''): Observable<UnderperformingKpis> {
    return this.http.get<any>(environment.apiUrl + `api/kpi/underperforming/${nameplate}/${dma}`);
  }

  getInsights(nameplate, includeEmail, bc): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `api/kpi/insights/${nameplate}/${includeEmail}/${bc}`);
  }

  getCompetitiveShare(nameplate, bc, pinmkt = null): Observable<any> {
    if (pinmkt) {

      if (this.competitiveShareCacheByPinMkt[nameplate] && this.competitiveShareCacheByPinMkt[nameplate][bc] && this.competitiveShareCacheByPinMkt[nameplate][bc][pinmkt]) {
        return new Observable((observer) => {
          observer.next(this.competitiveShareCache[nameplate]?.bc?.pinmkt)
          observer.complete()
        })
      } else {

        const subject = new Subject<any>();
        subject.subscribe(function (v) {
          if (!this.competitiveShareCacheByPinMkt[nameplate]) this.competitiveShareCacheByPinMkt[nameplate] = {};
          if (!this.competitiveShareCacheByPinMkt[nameplate][bc]) this.competitiveShareCacheByPinMkt[nameplate][bc] = {};
          if (!this.competitiveShareCacheByPinMkt[nameplate][bc][pinmkt]) this.competitiveShareCacheByPinMkt[nameplate][bc][pinmkt] = {};

          this.competitiveShareCacheByPinMkt[nameplate][pinmkt] = v
        }.bind(this))

        this.http.get<any>(environment.apiUrl + `api/kpi/competitiveShare/${nameplate}/${bc}/${this.escape(pinmkt)}`)
          .subscribe(subject);
        return subject
      }
    } else
      if (this.competitiveShareCache[nameplate] && this.competitiveShareCache[nameplate][bc]) {
        return new Observable((observer) => {
          observer.next(this.competitiveShareCache[nameplate]?.bc)
          observer.complete()
        })
      } else {

        const subject = new Subject<any>();
        subject.subscribe(function (v) {
          if (!this.competitiveShareCache[nameplate]) this.competitiveShareCache[nameplate] = {};
          if (!this.competitiveShareCache[nameplate][bc]) this.competitiveShareCache[nameplate][bc] = {};

          this.competitiveShareCache[nameplate][bc] = v
        }.bind(this))

        this.http.get<any>(environment.apiUrl + `api/kpi/competitiveShare/${nameplate}/${bc}`)
          .subscribe(subject);
        return subject

      }
  }

  getLeadsBreakdown(nameplate, bc): Observable<any> {
    if (this.leadsBreakdownCache[nameplate] && this.leadsBreakdownCache[nameplate][bc]) {
      return new Observable((observer) => {
        observer.next(this.leadsBreakdownCache[nameplate]?.bc)
        observer.complete()
      })
    } else {

      const subject = new Subject<any>();
      subject.subscribe(function (v) {
        if (!this.leadsBreakdownCache[nameplate]) this.leadsBreakdownCache[nameplate] = {};
        if (!this.leadsBreakdownCache[nameplate][bc]) this.leadsBreakdownCache[nameplate][bc] = {};

        this.leadsBreakdownCache[nameplate][bc] = v
      }.bind(this))

      this.http.get<any>(environment.apiUrl + `api/kpi/leadsBreakdown/${nameplate}/${bc}`)
        .subscribe(subject);
      return subject

    }
  }

  getCompetitiveLeaseShare(nameplate, bc, pinmkt = null): Observable<any> {
    // debugger;
    if (!!pinmkt) {
      if (this.competitiveLeaseShareCacheByPinMkt[nameplate] && this.competitiveLeaseShareCacheByPinMkt[nameplate][bc] && this.competitiveLeaseShareCacheByPinMkt[nameplate][bc][pinmkt]) {
        return new Observable((observer) => {
          observer.next(this.competitiveShareCache[nameplate]?.bc?.pinmkt)
          observer.complete()
        })
      } else {

        const subject = new Subject<any>();
        subject.subscribe(function (v) {
          if (!this.competitiveLeaseShareCacheByPinMkt[nameplate]) this.competitiveLeaseShareCacheByPinMkt[nameplate] = {};
          if (!this.competitiveLeaseShareCacheByPinMkt[nameplate][bc]) this.competitiveLeaseShareCacheByPinMkt[nameplate][bc] = {};
          if (!this.competitiveLeaseShareCacheByPinMkt[nameplate][bc][pinmkt]) this.competitiveLeaseShareCacheByPinMkt[nameplate][bc][pinmkt] = {};

          this.competitiveLeaseShareCacheByPinMkt[nameplate][pinmkt] = v
        }.bind(this))

        this.http.get<any>(environment.apiUrl + `api/kpi/competitiveLeaseShare/${nameplate}/${bc}/${this.escape(pinmkt)}`)
          .subscribe(subject);
        return subject
      }

    } else
      if (this.competitiveLeaseShareCache[nameplate] && this.competitiveLeaseShareCache[nameplate][bc]) {
        return new Observable((observer) => {
          observer.next(this.competitiveLeaseShareCache[nameplate]?.bc)
          observer.complete()
        })
      } else {

        const subject = new Subject<any>();
        subject.subscribe(function (v) {
          if (!this.competitiveLeaseShareCache[nameplate]) this.competitiveLeaseShareCache[nameplate] = {};
          if (!this.competitiveLeaseShareCache[nameplate][bc]) this.competitiveLeaseShareCache[nameplate][bc] = {};

          this.competitiveLeaseShareCache[nameplate][bc] = v
        }.bind(this))

        this.http.get<any>(environment.apiUrl + `api/kpi/competitiveLeaseShare/${nameplate}/${bc}`)
          .subscribe(subject);
        return subject

      }
  }

  getCompetitiveRetailShare(nameplate, bc, pinmkt = null): Observable<any> {
    if (pinmkt) {
      if (this.competitiveRetailShareCacheByPinMkt[nameplate] && this.competitiveRetailShareCacheByPinMkt[nameplate][bc] && this.competitiveRetailShareCacheByPinMkt[nameplate][bc][pinmkt]) {
        return new Observable((observer) => {
          observer.next(this.competitiveShareCache[nameplate]?.bc?.pinmkt)
          observer.complete()
        })
      } else {

        const subject = new Subject<any>();
        subject.subscribe(function (v) {
          if (!this.competitiveRetailShareCacheByPinMkt[nameplate]) this.competitiveRetailShareCacheByPinMkt[nameplate] = {};
          if (!this.competitiveRetailShareCacheByPinMkt[nameplate][bc]) this.competitiveRetailShareCacheByPinMkt[nameplate][bc] = {};
          if (!this.competitiveRetailShareCacheByPinMkt[nameplate][bc][pinmkt]) this.competitiveRetailShareCacheByPinMkt[nameplate][bc][pinmkt] = {};

          this.competitiveRetailShareCacheByPinMkt[nameplate][pinmkt] = v
        }.bind(this))

        this.http.get<any>(environment.apiUrl + `api/kpi/competitiveRetailShare/${nameplate}/${bc}/${this.escape(pinmkt)}`)
          .subscribe(subject);
        return subject
      }

    } else
      if (this.competitiveRetailShareCache[nameplate] && this.competitiveRetailShareCache[nameplate][bc]) {
        return new Observable((observer) => {
          observer.next(this.competitiveRetailShareCache[nameplate]?.bc)
          observer.complete()
        })
      } else {

        const subject = new Subject<any>();
        subject.subscribe(function (v) {
          if (!this.competitiveRetailShareCache[nameplate]) this.competitiveRetailShareCache[nameplate] = {};
          if (!this.competitiveRetailShareCache[nameplate][bc]) this.competitiveRetailShareCache[nameplate][bc] = {};

          this.competitiveRetailShareCache[nameplate][bc] = v
        }.bind(this))

        this.http.get<any>(environment.apiUrl + `api/kpi/competitiveRetailShare/${nameplate}/${bc}`)
          .subscribe(subject);
        return subject

      }
  }

  getPinMarketLeaseShare(nameplate, bc): Observable<any> {

    if (this.pinMarketLeaseCache[nameplate] && this.pinMarketLeaseCache[nameplate][bc]) {
      return new Observable((observer) => {
        observer.next(this.pinMarketLeaseCache[nameplate]?.bc)
        observer.complete()
      })
    } else {

      const subject = new Subject<any>();
      subject.subscribe(function (v) {
        if (!this.pinMarketLeaseCache[nameplate]) this.pinMarketLeaseCache[nameplate] = {};
        if (!this.pinMarketLeaseCache[nameplate][bc]) this.pinMarketLeaseCache[nameplate][bc] = {};

        this.pinMarketLeaseCache[nameplate][bc] = v
      }.bind(this))

      this.http.get<any>(environment.apiUrl + `api/kpi/pinMarketLeaseShare/${nameplate}/${bc}`)
        .subscribe(subject);
      return subject

    }
    //return this.http.get<any>(environment.apiUrl + `api/kpi/pinMarketLeaseShare/${nameplate}/${bc}`)
  }
  getPinMarketRetailShare(nameplate, bc): Observable<any> {
    if (this.pinMarketRetailCache[nameplate] && this.pinMarketRetailCache[nameplate][bc]) {
      return new Observable((observer) => {
        observer.next(this.pinMarketRetailCache[nameplate]?.bc)
        observer.complete()
      })
    } else {

      const subject = new Subject<any>();
      subject.subscribe(function (v) {
        if (!this.pinMarketRetailCache[nameplate]) this.pinMarketRetailCache[nameplate] = {};
        if (!this.pinMarketRetailCache[nameplate][bc]) this.pinMarketRetailCache[nameplate][bc] = {};

        this.pinMarketRetailCache[nameplate][bc] = v
      }.bind(this))

      this.http.get<any>(environment.apiUrl + `api/kpi/pinMarketRetailShare/${nameplate}/${bc}`)
        .subscribe(subject);
      return subject

    }
  }
  getPinMarketShare(nameplate, bc): Observable<any> {
    if (this.pinMarketCache[nameplate] && this.pinMarketCache[nameplate][bc]) {
      return new Observable((observer) => {
        observer.next(this.pinMarketCache[nameplate]?.bc)
        observer.complete()
      })
    } else {

      const subject = new Subject<any>();
      subject.subscribe(function (v) {
        if (!this.pinMarketCache[nameplate]) this.pinMarketCache[nameplate] = {};
        if (!this.pinMarketCache[nameplate][bc]) this.pinMarketCache[nameplate][bc] = {};

        this.pinMarketCache[nameplate][bc] = v
      }.bind(this))

      this.http.get<any>(environment.apiUrl + `api/kpi/pinMarketShare/${nameplate}/${bc}`)
        .subscribe(subject);
      return subject

    }
  }

  getKStock(nameplate): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `api/kpi/kStock/${nameplate}`)
  }

  getNameplates(): Observable<any[]> {
    const email = JSON.parse(localStorage.getItem('msalUser')).email;
    return this.http.get<any[]>(environment.apiUrl + `api/nameplates/${email}`);
  }

  logError(error = ''): Observable<any> {
    const err = { name: error };
    const errorLink = this.apiUrl + 'api/logs';
    return this.http.post(errorLink, err);

  }

  getKpi(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + 'api/kpi/');
  };
}
