import { Component, OnInit, Input } from '@angular/core';
import { Alert } from 'src/app/models/alert';
import { AlertPreference } from 'src/app/models/alertPreference';

@Component({
  selector: 'app-alert-preference',
  templateUrl: './alert-preference.component.html',
  styleUrls: ['./alert-preference.component.scss']
})
export class AlertPreferenceComponent implements OnInit {

  @Input() alertPreference: AlertPreference;
  constructor() { }

  ngOnInit() {
  }

}
