<mat-form-field class="chip-list">
  <mat-chip-list #chipList aria-label="selection'">
    <mat-chip *ngFor="let option of selectedOptions" [selectable]="selectable" [removable]="removable"
      (removed)="remove(option)">
      {{option}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input placeholder="New {{name}}..." #optionInput (focus)="recalculateAuto()" [formControl]="formCtrl" [matAutocomplete]="auto"
      [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{option}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>