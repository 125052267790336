<h2 class="workstream-header">Submitting A Ticket</h2>

<div *ngIf="!environment.mopar" [hidden]="leftIframeIsOpen || rightIframeIsOpen" class="workstream-grid">
  <button onclick="location.href='https://st11798p010.blob.core.windows.net/$web/assets/DriveITTicketSubmissionInstructions.pptx'"
    mat-raised-button color="primary" class='ticket'>Instructions</button>

  <button onclick="location.href='https://fiatchrysler.service-now.com/driveit'" mat-raised-button color="primary"
    class='ticket'>Submission
    Link</button>


</div>
<app-spinner-overlay overlay="true" *ngIf="loader">
</app-spinner-overlay>

<span style="clear: both;"></span>

<div class="dual-iframe-holder">
  <div [style.width.%]="iframeWidth" class="iframe-holder" style="float:left">
    <button (click)="closeLeftIframe()" [hidden]="!leftIframeIsOpen" mat-flat-button id="IdrButton"
      class="iframe-close-button">
      Close
    </button>

    <iframe title="leftIframe" *ngIf="leftIframeIsOpen" #leftIframe height="800px"
      [src]="leftIframeUrl | safe: 'resourceUrl'" id="IDR"></iframe>
  </div>
  <div class="iframe-spacer"></div>
  <div [style.width.%]="iframeWidth" class="iframe-holder" style="float:right">
    <button (click)="closeRightIframe()" [hidden]="!rightIframeIsOpen" mat-flat-button id="MddButton"
      class="iframe-close-button">
      Close
    </button>
    <iframe title="rightIframe" *ngIf="rightIframeIsOpen" #rightIframe height="800px"
      [src]="rightIframeUrl | safe: 'resourceUrl'" id="MDD"></iframe>
  </div>
</div>