import { Inject, Injectable, Injector } from '@angular/core';
import { MsalService, MsalBroadcastService, MsalGuardConfiguration, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { DataService } from 'src/app/services/data.service';
import { Subject, throwError } from 'rxjs';


import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { UserService } from './user.service';
import { filter } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationResult, EventMessage, EventType, InteractionRequiredAuthError, RedirectRequest } from '@azure/msal-browser';
declare let gtag: Function;

@Injectable()
export class StartupService {
  private readonly _destroying$ = new Subject<void>();

  private failureSubscription: Subscription;
  private successSubscription: Subscription;
  //  private authService2: Msal.UserAgentApplication
  //msalService: MsalService;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private dataService: DataService,
    private userService: UserService
  ) {

  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  // This is the method you want to call at bootstrap
  // Important: It should return a Promise

  async load(): Promise<any> {
    if ((window.document as any).documentMode) {
      // Do IE stuff
      console.error('Internet Explorer is no longer a supported browser for security reasons. We support modern browsers and optimize for Chrome.')
    }
    return new Promise((resolve, reject) => {

      this.authService.handleRedirectObservable().subscribe({
        next: (result: AuthenticationResult) => {
          console.log('result', result)
          if (result) {
            this.authService.instance.setActiveAccount(result.account);
            console.log('acquireTokenSilent', this.authService.instance)
            this.authService.instance.acquireTokenSilent({ scopes: [], extraQueryParameters: { resource: environment.resource } }).then(tokenResponse => {
              console.log('acquiredtokensilent')
              localStorage.setItem('token', JSON.stringify(tokenResponse.accessToken));

              // Do something with the tokenResponse
            })
          } else {

            if (
              this.authService.instance.getAllAccounts().length == 0
            ) {
              console.log('no account')
              console.log(this.authService.instance)

              this.authService.loginRedirect({ scopes: [], extraQueryParameters: { resource: environment.resource } });
            } else {


              console.log('using account')
              this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
              this.authService.instance.acquireTokenSilent({ scopes: [], extraQueryParameters: { resource: environment.resource } }).then(tokenResponse => {

                console.log('acquiredtokensilent')
                localStorage.setItem('token', JSON.stringify(tokenResponse.accessToken));
              }).catch(error => {
                this.authService.loginRedirect({ scopes: [], extraQueryParameters: { resource: environment.resource } })
              }
              )
            }
          }

          // Sets login display when using redirects
        }
        ,
        error: (error) => {
          console.log(error)
        }
      });
      if (
        this.authService.instance.getAllAccounts().length == 0
      ) {

        console.log(this.authService.instance)

        this.authService.loginRedirect({ scopes: [], extraQueryParameters: { resource: environment.resource } });
      }
      else {
        this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);


      }


      this.failureSubscription = this.msalBroadcastService.msalSubject$
        .pipe(
          // Optional filtering of events
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          console.log('failure result', result)
          this.authService.loginRedirect({ scopes: [], extraQueryParameters: { resource: environment.resource } })
          this.dataService.logError(result.payload && result.payload.toString());

          ;
        })

      this.successSubscription = this.msalBroadcastService.msalSubject$
        .pipe(
          // Optional filtering of events.
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          console.log('success subscription', result)
          // 'msal:acquireTokenSuccess',
          const payload = (result.payload as AuthenticationResult);
          const token = payload.accessToken;
          localStorage.setItem('token', JSON.stringify(token));
          const msalUser = payload.account;
          console.log(msalUser)
          localStorage.setItem('msalUser', JSON.stringify(msalUser))
          const gtagId = environment.envName === 'prod' ? 'UA-146915285-1' : 'UA-146915285-2';
          gtag('config', gtagId, { user_id: msalUser.homeAccountId });
          Promise.all([this.userService.insertNewUser().toPromise(), this.dataService.getIconLibrary().toPromise()])
            .then((values) => {
              // debugger;
              let isDAITeam = 'false'
              let isAdmin = 'false';
              const userGroups = JSON.parse(localStorage.getItem('msalUser')).idTokenClaims.group
              if ((userGroups.includes('cag\\BI_PORTAL_ADMIN') && !environment.mopar)
                || (environment.mopar && !environment.cx && userGroups.includes('cag\\NAPP_FIM_MOPAR_PORTAL_ADMIN'))
                || (environment.cx && userGroups.includes('cag\\CX_PORTAL_ADMIN'))
              ) {
                isAdmin = 'true';
              }

              if ((userGroups && userGroups.includes && userGroups.includes('cag\\BI_PORTAL_ADMIN') && !environment.mopar) || (environment.mopar && userGroups && userGroups.includes && userGroups.includes('NAPP_FIM_MOPAR_PORTAL_ADMIN'))) {
                isAdmin = 'true';
              }
              if (userGroups && userGroups.includes && userGroups.includes('cag\\BI_PORTAL_DAITEAM') && !environment.mopar) {
                isDAITeam = 'true';
              }

              localStorage.setItem('isAdmin', isAdmin);
              localStorage.setItem('isDAITeam', isDAITeam);
              localStorage.setItem('icons', JSON.stringify(values[1].body));
              resolve(true);
            })
            .catch((err: any) => Promise.resolve(false));
        }
        )


    })
  }
}