<div class='kpi'
  *ngIf="underperformingData && underperformingData.values && underperformingData.values.length; else comingSoon">
  <table>
    <tr>
      <th class="wideHeader alignRight" title="{{ toTitleCase(underperformingData?.nameplate) }} {{
            underperformingData?.title
          }}">

        {{
            capitalizeAcronyms(toTitleCase(intelligentShortening(
              35,
              underperformingData?.nameplate + " " + underperformingData?.title
            )))
          }}
        <img src="https://st11798p010.blob.core.windows.net/$web/assets/images/launch.svg" (click)="launchReport()"
          class='launch-report' />
      </th>
      <th *ngIf="underperformingData?.values[0]?.cpos">{{ "CPOS" }}</th>
      <th>{{ inventory ? "Days Supply" : "MoM"}}</th>
      <th *ngIf="!inventory">YoY</th>
      <th>3 Mo. Trend</th>

    </tr>

    <tr *ngFor="
          let data of underperformingData.values | slice: 0:5;
          let i = index
        ">

      <td class="alignRight" title="{{ data?.location }}">
        {{ intelligentShortening(25, data?.location) }}
      </td>
      <td *ngIf="data?.cpos" class="alignRight" title="{{ data?.cpos }}">
        {{ intelligentShortening(25, data?.cpos) }}
      </td>
      <td *ngIf="!inventory">
        {{ data?.monthOverMonthValue !== null ? data?.monthOverMonthValue.toFixed(1) + suffix : "No Data" }}
      </td>
      <td *ngIf="inventory">
        {{ data?.monthOverMonthValue !== null ? data?.monthOverMonthValue.toFixed(0) + suffix : "No Data" }}
      </td>
      <td *ngIf="!inventory">{{ data?.yearOverYearValue ? data?.yearOverYearValue?.toFixed(1) + suffix : "No Data" }}
      </td>
      <!-- <td>
          <img
            src="{{
              data?.threeMonthTrend > 0
                ? 'https://st11798p010.blob.core.windows.net/$web/assets/images/trending_up.svg'
                : 'https://st11798p010.blob.core.windows.net/$web/assets/images/trending_down.svg'
            }}"
          />
        </td> -->

      <td>
        <div class="chart">
          <month-trend-chart [dataPoints]="[
                data.thirdMonthValue,
                data.secondMonthValue,
                data.firstMonthValue
              ]">
          </month-trend-chart>
        </div>
      </td>

      <!-- <td><div id='divChart'>
          <canvas id='myChart'>
            {{myChart}}

          </canvas></div>
        </td> -->
    </tr>
  </table>
</div>
<ng-template #comingSoon>
  <div class='kpi'>{{(underperformingData && underperformingData.errorText) || "Data not available"}}</div>
</ng-template>