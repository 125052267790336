<button mat-raised-button color="primary" class="exportBut" (click)="exportexcel()">Export
  <mat-icon>cloud_download</mat-icon>
</button>

<div id="container" class="container">

  <table>
    <thead>
      <tr>
        <th colspan="1" style="border: none;"></th>
        <th colspan="7">{{monthStringify() + " " + month.toString().slice(0,4)}}</th>
        <th colspan="5">CYTD</th>
        <th colspan="3">CY</th>
      </tr>
      <tr>
        <th style="border: none;"></th>
        <th>{{getBudgetString()}}</th>
        <th>Last Year Sales</th>
        <th>Plan</th>
        <th>Actual Sales</th>
        <th>Sale % of {{getBudgetString()}}</th>
        <th>Sales % of Plan</th>
        <th>Sales % vs LY</th>

        <th>{{getBudgetString()}}</th>
        <th>Last Year Sales</th>
        <th>Actual Sales</th>
        <th>Sale % of {{getBudgetString()}}</th>
        <th>Sales % vs LY</th>

        <th>{{getBudgetString()}}</th>
        <th>Last Year Sales</th>
        <th>Plan</th>
      </tr>
    </thead>
    <tbody>



      <tr *ngFor="let obj of jeepGrid" class="no-bottom-border">
        <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}" class="tableRow">{{
          obj.NAMEPLATE == 'TOTAL' ? (obj.COUNTRY == 'TOTAL' ? obj.REGION : obj.COUNTRY) :
          obj.NAMEPLATE
          }} </td>

        <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}">{{obj.BUDGET.toLocaleString()}} </td>
        <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}">{{obj.LASTYEARSSALES.toLocaleString()}} </td>
        <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}">{{obj.PLAN.toLocaleString()}} </td>
        <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}"> {{obj.SALES.toLocaleString()}}</td>
        <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}"
          [ngStyle]="{'color': ((obj.SALESPERCENTOFBUDGET)*100).toFixed(2) <= 99.5 ? 'red' : '' }">
          {{((obj.SALESPERCENTOFBUDGET)*100).toFixed(0).toLocaleString()}} %</td>
        <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}"
          [ngStyle]="{'color': ((obj.SALESPERCENTOFPLAN)*100).toFixed(2) <= 99.5 ? 'red' : '' }">{{
          ((obj.SALESPERCENTOFPLAN)*100).toFixed(0).toLocaleString()}}% </td>
        <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}"
          [ngStyle]="{'color': ((obj.SALESPERCENTOFLASTYEAR)*100).toFixed(2) <= 99.5 ? 'red' : '' }">
          {{((obj.SALESPERCENTOFLASTYEAR)*100).toFixed(0).toLocaleString()}}%
        </td>

        <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}"> {{obj.CYTDBUDGET.toLocaleString()}}</td>
        <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}"> {{obj.LYTDSALES.toLocaleString()}}</td>
        <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}">{{obj.CYTDSALES.toLocaleString()}} </td>
        <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}"
          [ngStyle]="{'color': ((obj.CYTDSALESPERCENTOFBUDGET)*100).toFixed(2) <= 99.5 ? 'red' : '' }">
          {{((obj.CYTDSALESPERCENTOFBUDGET)*100).toFixed(0).toLocaleString()}}% </td>
        <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'} "
          [ngStyle]="{'color': ((obj.CYTDSALESPERCENTOFLASTYEAR)*100).toFixed(2) <= 99.5 ? 'red' : '' }">
          {{((obj.CYTDSALESPERCENTOFLASTYEAR)*100).toFixed(0).toLocaleString()}}%
        </td>

        <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}"> {{obj.CYBUDGET.toLocaleString()}} </td>
        <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}">{{obj.LYSALES.toLocaleString()}} </td>
        <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}">{{obj.CYPLAN.toLocaleString()}} </td>
      </tr>
    </tbody>
  </table>
</div>