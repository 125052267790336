<div class='kpi' *ngIf="insights">
  <table *ngIf="insights.segmentVolume.values.length > 0">
    <thead>
      <tr class='bc-headers'>
        <td>Metric</td>
        <td *ngFor=" let data of insights.segmentVolume.values">{{data.c_pin_mkt}}</td>
        <td>Updated</td>
      </tr>
    </thead>
    <tbody>
      <tr class='category-header'>
        <th>MTD Segment Volume (JDP)</th>
        <td *ngFor="
    let data of insights.segmentVolume.values | slice: 0:10;
    let i = index
  ">{{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 0})}}

        </td>
        <td [attr.rowspan]="insights.share.values.length ? 16 : 2">
          {{this.jobLogs['JDPA_SALES_MTD_SUMMARY']}}
        </td>
      </tr>
      <tr *ngIf="mom == 'MoM'">
        <td>{{insights.share.segmentName || 'Month Over Month'}}</td>
        <td *ngFor="
      let data of insights.segmentVolume.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.monthOverMonthValue < 0}">
          {{data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%

        </td>
      </tr>
      <tr *ngIf="mom == 'YoY'">
        <td>{{insights.share.segmentName || 'Year Over Year'}}</td>
        <td *ngFor="
      let data of insights.segmentVolume.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.yearOverYearValue < 0}">
          {{data.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%

        </td>
      </tr>
      <ng-container *ngIf="insights.share.values.length">
        <tr class='category-header'>
          <th>Share</th>
        </tr>
        <tr>
          <td>{{insights.share.segmentName || insights.share.nameplate?.toUpperCase()}}</td>
          <!-- -->
          <td *ngFor="
    let data of insights.share.values | slice: 0:10;
    let i = index
  " class='right'>
            {{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%

            <div class="dropdown-content">
              <app-insight-competitive-popup [bc]=data.i_bc_id [nameplate]=insights.share.nameplate
                [pinmkt]=data.c_pin_mkt>
              </app-insight-competitive-popup>
            </div>
          </td>
        </tr>
        <tr *ngIf="mom == 'MoM'">
          <td>{{insights.share.monthOverMonth || 'Month Over Month'}}</td>
          <td *ngFor=" let data of insights.share.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
      data.monthOverMonthValue < 0}" class='right'>
            {{data.monthOverMonthValue?.toLocaleString(undefined,
            {maximumFractionDigits: 1})
            }}ppt
            <div class="dropdown-content">
              <app-insight-competitive-popup [bc]=data.i_bc_id [nameplate]=insights.share.nameplate
                [pinmkt]=data.c_pin_mkt [mom]=mom>
              </app-insight-competitive-popup>
            </div>
          </td>
        </tr>

        <tr *ngIf="mom == 'YoY'">
          <td>{{insights.share.monthOverMonth || 'Year Over Year'}}</td>
          <td *ngFor=" let data of insights.share.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
      (data.yearOverYearValue) < 0}" class='right'>{{
            (data.yearOverYearValue)?.toLocaleString(undefined,
            {maximumFractionDigits: 1})
            }}ppt
            <div class="dropdown-content">
              <app-insight-competitive-popup [bc]=data.i_bc_id [nameplate]=insights.share.nameplate
                [pinmkt]=data.c_pin_mkt [mom]=mom>
              </app-insight-competitive-popup>
            </div>
          </td>
        </tr>

        <tr>
          <td>{{insights.share.segmentName || 'Volume Impact of Share'}}</td>
          <td *ngFor="
      let data of insights.share.values | slice: 0:10;
      let i = index
    ">
            {{
            mom == 'MoM' ?
            (data.monthOverMonthVolumeImpact)?.toLocaleString(undefined,
            {maximumFractionDigits: 0})
            :
            (data.yearOverYearVolumeImpact)?.toLocaleString(undefined,
            {maximumFractionDigits: 0})

            }}
          </td>

        </tr>
        <ng-container
          *ngIf="toTitleCase(insights.share.nameplate) == 'Ram Ld' || toTitleCase(insights.share.nameplate) == 'Pacifica Total'">
          <tr>
            <td>{{insights.share.nameplate == 'RAM LD' ? 'DT' : 'PACIFICA GAS'}}</td>
            <!-- -->
            <td *ngFor="
        let data of insights.customSplit1.values | slice: 0:10;
        let i = index
      ">
              {{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%
            </td> <!-- -->
            <!--<ng-container *ngFor="
          let data of insights.share.values | slice: 0:10;
          let i = index">
            <td insight
              [data]="((data.currentMonthValue / insights.segmentVolume.values[i].currentMonthValue) * 100)?.toLocaleString(undefined, {maximumFractionDigits: 1}) + '%'"
              [bc]="i" [nameplate]="insights.share.nameplate" [popupType]="'Competitive'">
            </td>
          </ng-container> -->

          </tr>
          <tr *ngIf="mom == 'MoM'">
            <td>{{insights.share.monthOverMonth || 'Month Over Month'}}</td>
            <td *ngFor=" let data of insights.customSplit1.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
          (data.monthOverMonthValue) < 0}">{{(data.monthOverMonthValue)?.toLocaleString(undefined,
              {maximumFractionDigits: 1})
              }}ppt

            </td>
          </tr>

          <tr *ngIf="mom == 'YoY'">
            <td>{{insights.share.monthOverMonth || 'Year Over Year'}}</td>
            <td *ngFor=" let data of insights.customSplit1.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
          (data.yearOverYearValue) < 0}">{{data.yearOverYearValue?.toLocaleString(undefined,
              {maximumFractionDigits: 1})
              }}ppt

            </td>
          </tr>
          <tr>
            <td>{{insights.share.nameplate == 'RAM LD' ? 'DS' : 'PACIFICA HYBRID'}}</td>
            <!-- -->
            <td *ngFor="
      let data of insights.customSplit2.values | slice: 0:10;
      let i = index
    ">
              {{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%
            </td> <!-- -->
          </tr>
          <tr *ngIf="mom == 'MoM'">
            <td>{{insights.share.monthOverMonth || 'Month Over Month'}}</td>
            <td *ngFor=" let data of insights.customSplit2.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
        (data.monthOVerMonthValue) < 0}">{{data.monthOverMonthValue?.toLocaleString(undefined,
              {maximumFractionDigits: 1})
              }}ppt

            </td>
          </tr>

          <tr *ngIf="mom == 'YoY'">
            <td>{{insights.share.monthOverMonth || 'Year Over Year'}}</td>
            <td *ngFor=" let data of insights.customSplit2.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
        data.yearOverYearValue < 0}">{{data.yearOverYearValue?.toLocaleString(undefined,
              {maximumFractionDigits: 1})
              }}ppt

            </td>
          </tr>

        </ng-container>

        <ng-container *ngIf="toTitleCase(insights.share.nameplate) == 'Grand Cherokee Total'">
          <tr>
            <td>{{'GRAND CHEROKEE WK'}}</td>
            <td *ngFor="
      let data of insights.customSplit1.values | slice: 0:10;
      let i = index
    ">
              {{((data.currentMonthValue / (insights.segmentVolume.values[i].currentMonthValue * 1.0)) *
              100)?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%
            </td>

          </tr>
          <tr *ngIf="mom == 'MoM'">
            <td>{{insights.share.monthOverMonth || 'Month Over Month'}}</td>
            <td *ngFor=" let data of insights.customSplit1.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
        (data.currentMonthValue / insights.segmentVolume.values[i].currentMonthValue) - (data.lastMonthValue /
        insights.segmentVolume.values[i].lastMonthValue) < 0}">{{(((data.currentMonthValue /
              insights.segmentVolume.values[i].currentMonthValue)
              -(data.lastMonthValue / insights.segmentVolume.values[i].lastMonthValue)) *
              100)?.toLocaleString(undefined,
              {maximumFractionDigits: 1})
              }}ppt

            </td>
          </tr>

          <tr *ngIf="mom == 'YoY'">
            <td>{{insights.share.monthOverMonth || 'Year Over Year'}}</td>
            <td *ngFor=" let data of insights.customSplit1.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
        (((data.currentMonthValue / insights.segmentVolume.values[i].currentMonthValue) -(data.lastMonthValue /
        insights.segmentVolume.values[i].lastYearValue)) * 100) < 0}">{{(((data.currentMonthValue /
              insights.segmentVolume.values[i].currentMonthValue)
              -(data.lastMonthValue / insights.segmentVolume.values[i].lastYearValue)) * 100)?.toLocaleString(undefined,
              {maximumFractionDigits: 1})
              }}ppt

            </td>
          </tr>
          <tr>
            <td>{{'GRAND CHEROKEE WL'}}</td>
            <td *ngFor="
    let data of insights.customSplit2.values | slice: 0:10;
    let i = index
  ">
              {{((data.currentMonthValue / (insights.segmentVolume.values[i].currentMonthValue * 1.0)) *
              100)?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%
            </td> <!-- -->
          </tr>
          <tr *ngIf="mom == 'MoM'">
            <td>{{insights.share.monthOverMonth || 'Month Over Month'}}</td>
            <td *ngFor=" let data of insights.customSplit2.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
      (data.currentMonthValue / insights.segmentVolume.values[i].currentMonthValue) - (data.lastMonthValue /
      insights.segmentVolume.values[i].lastMonthValue) < 0}">{{(((data.currentMonthValue /
              insights.segmentVolume.values[i].currentMonthValue)
              -(data.lastMonthValue / insights.segmentVolume.values[i].lastMonthValue)) *
              100)?.toLocaleString(undefined,
              {maximumFractionDigits: 1})
              }}ppt

            </td>
          </tr>

          <tr *ngIf="mom == 'YoY'">
            <td>{{insights.share.monthOverMonth || 'Year Over Year'}}</td>
            <td *ngFor=" let data of insights.customSplit2.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
      (((data.currentMonthValue / insights.segmentVolume.values[i].currentMonthValue) -(data.lastMonthValue /
      insights.segmentVolume.values[i].lastYearValue)) * 100) < 0}">{{(((data.currentMonthValue /
              insights.segmentVolume.values[i].currentMonthValue)
              -(data.lastMonthValue / insights.segmentVolume.values[i].lastYearValue)) * 100)?.toLocaleString(undefined,
              {maximumFractionDigits: 1})
              }}ppt

            </td>
          </tr>


        </ng-container>





        <tr class='category-header'>
          <th>Share Type</th>
        </tr>
        <tr>
          <td>{{insights.share.segmentName || 'Total Mkt Share'}}</td>
          <!-- -->
          <td *ngFor="
    let data of insights.share.values | slice: 0:10;
    let i = index
  ">
            {{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%

          </td>
        </tr>
        <tr>
          <td>{{insights.share.monthOverMonth || 'Retail Share %'}}</td>
          <td *ngFor="
      let data of insights.retailShare.values | slice: 0:10;
      let i = index
    ">{{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})
            }}%

          </td>
        </tr>
        <tr *ngIf="mom == 'MoM'">
          <td>{{insights.share.monthOverMonth || 'Retail Share MoM'}}</td>
          <td *ngFor="
      let data of insights.retailShare.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.monthOverMonthValue < 0}" class='right'>
            {{data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})
            }}ppt
            <div class="dropdown-content">
              <app-insight-competitive-popup [bc]=data.i_bc_id [nameplate]=insights.share.nameplate
                [pinmkt]=data.c_pin_mkt [mom]=mom [salesType]='"Retail"'>
              </app-insight-competitive-popup>
            </div>
          </td>
        </tr>

        <tr *ngIf="mom == 'YoY'">
          <td>{{insights.share.monthOverMonth || 'Retail Share YoY'}}</td>
          <td *ngFor="
      let data of insights.retailShare.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.yearOverYearValue < 0}" class='right'>
            {{data.yearOverYearValue?.toLocaleString(undefined,
            {maximumFractionDigits: 1})
            }}ppt

            <div class="dropdown-content">
              <app-insight-competitive-popup [bc]=data.i_bc_id [nameplate]=insights.share.nameplate
                [pinmkt]=data.c_pin_mkt [mom]=mom [salesType]='"Retail"'>
              </app-insight-competitive-popup>
            </div>
          </td>
        </tr>

        <tr>
          <td>{{insights.share.monthOverMonth || 'Volume Impact Of Share'}}</td>
          <td *ngFor="
      let data of insights.retailShare.values | slice: 0:10;
      let i = index
    ">

            {{
            mom == "MoM" ?
            data.monthOverMonthVolumeImpact?.toLocaleString(undefined,
            {maximumFractionDigits: 0})
            :
            data.yearOverYearVolumeImpact?.toLocaleString(undefined,
            {maximumFractionDigits: 0})
            }}

          </td>
        </tr>

        <tr>
          <td>{{insights.share.monthOverMonth || 'Lease Share %'}}</td>
          <td *ngFor="
      let data of insights.leaseShare.values | slice: 0:10;
      let i = index
    ">{{
            data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})
            }}%


          </td>
        </tr>
        <tr *ngIf="mom=='MoM'">
          <td>{{insights.share.monthOverMonth || 'Lease Share MoM'}}</td>
          <td *ngFor="
      let data of insights.leaseShare.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.monthOverMonthValue < 0}" class='right'>
            {{data.monthOverMonthValue?.toLocaleString(undefined,
            {maximumFractionDigits: 1})
            }}ppt
            <div class="dropdown-content">
              <app-insight-competitive-popup [bc]=data.i_bc_id [nameplate]=insights.share.nameplate
                [pinmkt]=data.c_pin_mkt [mom]=mom [salesType]='"Lease"'>
              </app-insight-competitive-popup>
            </div>
          </td>
        </tr>
        <tr *ngIf="mom=='YoY'">
          <td>{{insights.share.monthOverMonth || 'Lease Share YoY'}}</td>
          <td *ngFor="
      let data of insights.leaseShare.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.yearOverYearValue < 0}" class='right'>
            {{data.yearOverYearValue?.toLocaleString(undefined,
            {maximumFractionDigits: 1})
            }}ppt
            <div class="dropdown-content">
              <app-insight-competitive-popup [bc]=data.i_bc_id [nameplate]=insights.share.nameplate
                [pinmkt]=data.c_pin_mkt [mom]=mom [salesType]='"Lease"'>
              </app-insight-competitive-popup>
            </div>
          </td>
        </tr>


        <tr>
          <td>{{insights.share.monthOverMonth || 'Volume Impact Of Share'}}</td>
          <td *ngFor="
      let data of insights.leaseShare.values | slice: 0:10;
      let i = index
    ">
            {{
            mom == "MoM" ?
            data.monthOverMonthVolumeImpact?.toLocaleString(undefined,
            {maximumFractionDigits: 0})
            :
            data.yearOverYearVolumeImpact?.toLocaleString(undefined,
            {maximumFractionDigits: 0})

            }} </td>
        </tr>
        <tr class='category-header'>
          <th>Model Detail</th>
        </tr>

        <ng-container *ngIf="mom == 'MoM'">
          <tr>
            <td>{{insights.modelShare.values[0]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 0:10;
        let i = index
      " [ngClass]="{'negative': data.monthOverMonthValue < 0}">
              {{data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>

          <tr *ngIf="insights.modelShare.values[10]">
            <td>{{insights.modelShare.values[10]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 10:20;
        let i = index
      " [ngClass]="{'negative': data.monthOverMonthValue < 0}">{{
              data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits:1
              })
              }}ppt</td>
          </tr>

          <tr *ngIf="insights.modelShare.values[20]">
            <td>{{insights.modelShare.values[20]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 20:30;
        let i = index
      " [ngClass]="{'negative': data.monthOverMonthValue < 0}">{{data.monthOverMonthValue?.toLocaleString(undefined,
              {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>

          <tr *ngIf="insights.modelShare.values[30]">
            <td>{{insights.modelShare.values[31]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 30:40;
        let i = index
      " [ngClass]="{'negative': data.monthOverMonthValue < 0}">{{
              data.monthOverMonthValue?.toLocaleString(undefined,
              {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>

          <tr *ngIf="insights.modelShare.values[40]">
            <td>{{insights.modelShare.values[40]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 40:50;
        let i = index
      " [ngClass]="{'negative': data.monthOverMonthValue < 0}">{{
              data.monthOverMonthValue?.toLocaleString(undefined,
              {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>
        </ng-container>

        <ng-container *ngIf="mom == 'YoY'">
          <tr>
            <td>{{insights.modelShare.values[0]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 0:10;
        let i = index
      " [ngClass]="{'negative': data.yearOverYearValue < 0}">
              {{data.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>

          <tr *ngIf="insights.modelShare.values[10]">
            <td>{{insights.modelShare.values[10]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 10:20;
        let i = index
      " [ngClass]="{'negative': data.yearOverYearValue < 0}">{{
              data.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits:
              1 })
              }}ppt</td>
          </tr>

          <tr *ngIf="insights.modelShare.values[20]">
            <td>{{insights.modelShare.values[20]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 20:30;
        let i = index
      " [ngClass]="{'negative': data.yearOverYearValue < 0}">{{data.yearOverYearValue?.toLocaleString(undefined,
              {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>

          <tr *ngIf="insights.modelShare.values[30]">
            <td>{{insights.modelShare.values[31]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 30:40;
        let i = index
      " [ngClass]="{'negative': data.yearOverYearValue < 0}">{{
              data.yearOverYearValue?.toLocaleString(undefined,
              {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>

          <tr *ngIf="insights.modelShare.values[40]">
            <td>{{insights.modelShare.values[40]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 40:50;
        let i = index
      " [ngClass]="{'negative': data.yearOverYearValue < 0}">{{
              data.yearOverYearValue?.toLocaleString(undefined,
              {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>
        </ng-container>


      </ng-container>



      <!-- <tr class='category-header'>
        <th>{{mom}} Demand</th>
      </tr>
      <tr *ngIf="mom == 'MoM'">
        <td>{{insights.share.monthOverMonth || 'Visits'}}</td>
        <td *ngFor="
      let data of insights.visits.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.monthOverMonthValue < 0}" title='{{data.monthOverMonthDiff}}'>
          {{data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%</td>
        <td>
          {{this.jobLogs['visits Data Factory Pipeline']}}
        </td>
      </tr>
      <tr *ngIf="mom == 'YoY'">
        <td>{{insights.share.monthOverMonth || 'Visits'}}</td>
        <td *ngFor="
      let data of insights.visits.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.yearOverYearValue < 0}" title='{{data.yearOverYearDiff}}'>
          {{data.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%</td>
        <td>
          {{this.jobLogs['visits Data Factory Pipeline']}}
        </td>
      </tr>



      <tr *ngIf="mom == 'MoM'">
        <td>{{insights.share.monthOverMonth || 'LFAs'}}</td>
        <td *ngFor="
      let data of insights.lfas.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.monthOverMonthValue < 0}" title='{{data.monthOVerMonthDiff}}'>
          {{data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%</td>
        <td>
          {{this.jobLogs['lfa Data Factory Pipeline']}}
        </td>
      </tr>
      <tr *ngIf="mom == 'YoY'">
        <td>{{insights.share.monthOverMonth || 'LFAs'}}</td>
        <td *ngFor="
      let data of insights.lfas.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.yearOverYearValue < 0}" title='{{data.yearOverYearDiff}}'>
          {{data.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%</td>
        <td>
          {{this.jobLogs['lfa Data Factory Pipeline']}}
        </td>
      </tr>

      <tr *ngIf=' mom=="MoM"'>
        <td>{{insights.share.monthOverMonth || 'Incentive Page Visits'}}</td>
        <td *ngFor=" let data of insights.incentives.values | slice: 0:10; let
        i=index " title=' {{data.monthOverMonthDiff}}' [ngClass]=" {'negative': data.monthOverMonthValue < 0}">
          {{data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%</td>
        <td>
          {{this.jobLogs['incentives Data Factory Pipeline']}}
        </td>
      </tr>

      <tr *ngIf="mom== 'YoY'">
        <td>{{insights.share.monthOverMonth || 'Incentive Page Visits'}}</td>
        <td *ngFor="
      let data of insights.incentives.values | slice: 0:10;
      let i = index
    " title='{{data.yearOverYearDiff}}' [ngClass]="{'negative': data.yearOverYearValue < 0}">
          {{data.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%</td>
        <td>
          {{this.jobLogs['incentives Data Factory Pipeline']}}
        </td>
      </tr> -->

    </tbody>

  </table>
</div>