import { Component, OnInit, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Pipe } from '@angular/core';
import { KPIService } from 'src/app/services/kpi.service';

@Component({
  selector: 'management-review',
  templateUrl: './nameplate-recap.component.html',
  styleUrls: ['./nameplate-recap.component.scss'],
  providers: [Pipe]
})
export class NameplateRecapComponent implements OnInit {
  iframeWidth = 100;
  managementReviewUrl1: SafeResourceUrl;
  managementReviewUrl2: SafeResourceUrl;
  managementReviewUrl3: SafeResourceUrl;
  managementReviewUrl4: SafeResourceUrl;



  nameplateRecapUrl: SafeResourceUrl;
  shareWalkUrl: SafeResourceUrl;

  @ViewChild('idr', { static: true }) idr;
  @ViewChild('mdd', { static: true }) mdd;
  mtdShareUrl: string;
  demandMoMUrl1: string;
  profitability: string;
  estimatedShare: string;
  constructor(public kpiService: KPIService) {
    let segment = 'Premium Car*';
    this.kpiService.filter$.subscribe(({ brand = 'ALFA ROMEO', nameplate = 'GIULIA', bc = '10' }) => {
      let bcMap = ['', 'CALIFORNIA', 'DENVER', 'GREAT LAKES', 'MID ATLANTIC', 'MIDWEST', 'NORTHEAST', 'SOUTHEAST', 'SOUTHWEST', 'WEST', 'NATIONAL']
      let businessCenter = bcMap[bc];
      this.shareWalkUrl = `https://discovery-ba.extra.chrysler.com/QvAJAXZfc/singleobject.htm?document=share%20walk%5Cshare%20walk.qvw&host=QVS%40shmwp146&object=CH32&select=LB37,${brand}&select=LB38,${nameplate}&select=LB39,${businessCenter}`
      this.managementReviewUrl1 = `https://discovery-ba.extra.chrysler.com/QvAJAXZfc/singleobject.htm?document=commercial%20analytics%5Cmanagement%20review.qvw&host=QVS%40shmwp146&object=CH51&select=LB01,${brand}&select=LB02,${nameplate}&select=LB03,${businessCenter}`
      this.managementReviewUrl2 = `https://discovery-ba.extra.chrysler.com/QvAJAXZfc/singleobject.htm?document=commercial%20analytics%5Cmanagement%20review.qvw&host=QVS%40shmwp146&object=CH50&select=LB01,${brand}&select=LB02,${nameplate}&select=LB03,${businessCenter}`
      this.managementReviewUrl3 = `https://discovery-ba.extra.chrysler.com/QvAJAXZfc/singleobject.htm?document=commercial%20analytics%5Cmanagement%20review.qvw&host=QVS%40shmwp146&object=CH52&select=LB01,${brand}&select=LB02,${nameplate}&select=LB03,${businessCenter}`
      this.managementReviewUrl4 = `https://discovery-ba.extra.chrysler.com/QvAJAXZfc/singleobject.htm?document=commercial%20analytics%5Cmanagement%20review.qvw&host=QVS%40shmwp146&object=CH53&select=LB01,${brand}&select=LB02,${nameplate}&select=LB03,${businessCenter}`
      this.nameplateRecapUrl = `http://odmwd002.servers.chrysler.com/QvAJAXZfc/singleobject.htm?document=nameplate_recap%5Cnameplate_recap_dashboard-list%20box.qvw&host=QVS%40odmwd002&object=CH16&select=LB03,${brand}&select=LB02,${nameplate}&select=LB01,${businessCenter}`
      this.mtdShareUrl = `https://discovery-ba.extra.chrysler.com/QvAJAXZfc/opendoc.htm?document=idr_sub%5Cestimated%20metrics-mtd.qvw&host=QVS%40shmwp146&object=CH419&object=&select=LB120,${""}`;
      this.demandMoMUrl1 = `https://discovery-ba.extra.chrysler.com/QvAJAXZfc/opendoc.htm?document=marketing%20demand%5Cmarketing%20demand%20insights%20%26%20analytics.qvw&host=QVS%40shmwp146&object=&select=LB135,'${brand}'&select=LB136,'${nameplate}'&select=LB137,'${businessCenter}'`
      this.profitability = `https://discovery-ba.extra.chrysler.com/QvAJAXZfc/opendoc.htm?document=idr_sub%5Cprofitability.qvw&host=QVS%40shmwp146&object=&select=LB44,'${brand}'&select=LB45,'${nameplate}'&select=LB46,'${segment}'&select=LB47,'${businessCenter}'`
      this.estimatedShare = `https://discovery-ba.extra.chrysler.com/QvAJAXZfc/singleobject.htm?document=idr_sub%5Cestimated%20metrics-mtd.qvw&host=QVS%40shmwp146&object=CH418&select=LB44,'${brand}'&select=LB45,'${nameplate}'&select=LB46,'${segment}'&select=LB47,'${businessCenter}'`
    })
  }

  ngOnInit() {
    //this.shareWalkUrl = 'http://ahmwt001.servers.chrysler.com/QvAJAXZfc/singleobject.htm?document=share%20walk%5Cshare%20walk.qvw&host=QVS%40ahmwt001&object=CH32'
    //this.nameplateRecapUrl = `http://odmwd002.servers.chrysler.com/QvAJAXZfc/singleobject.htm?document=nameplate_recap%5Cnameplate_recap_dashboard-list%20box.qvw&host=QVS%40odmwd002&object=CH16`
    //this.managementReviewUrl1 = 'https://discovery-ba.extra.chrysler.com/QvAJAXZfc/singleobject.htm?document=commercial%20analytics%5Cmanagement%20review.qvw&host=QVS%40shmwp146&object=CH51&select=LB37,${brand}&select=LB38,${nameplate}&select=LB39,${bc}'
    //this.managementReviewUrl2 = 'https://discovery-ba.extra.chrysler.com/QvAJAXZfc/singleobject.htm?document=commercial%20analytics%5Cmanagement%20review.qvw&host=QVS%40shmwp146&object=CH50&select=LB37,${brand}&select=LB38,${nameplate}&select=LB39,${bc}'
    //this.managementReviewUrl3 = 'https://discovery-ba.extra.chrysler.com/QvAJAXZfc/singleobject.htm?document=commercial%20analytics%5Cmanagement%20review.qvw&host=QVS%40shmwp146&object=CH52&select=LB37,${brand}&select=LB38,${nameplate}&select=LB39,${bc}'
    //this.managementReviewUrl4 = 'https://discovery-ba.extra.chrysler.com/QvAJAXZfc/singleobject.htm?document=commercial%20analytics%5Cmanagement%20review.qvw&host=QVS%40shmwp146&object=CH53&select=LB37,${brand}&select=LB38,${nameplate}&select=LB39,${bc}'

  }

  calculateShareWalk() {
    return this.shareWalkUrl;
  }


}
