import { Injectable, Injector } from '@angular/core';
import { Subject, Observable, ReplaySubject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retryWithBackoff } from 'src/rxjs-helpers';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  apiUrl: string;

  private http = this.injector.get(HttpClient);

  constructor(private injector: Injector) {
    this.apiUrl = environment.apiUrl;
  }

  logError(message: string, stack: string): Observable<any> {
    console.log("error message", message)
    const errorLink = this.apiUrl + 'api/errors/log';
    return this.http.post(errorLink, { message, severity: 'Error', stack })
      ;
  }

}
