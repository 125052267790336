<nav class="navbar navbar-defualt">
  <header>
    <!-- Global site tag (gtag.js) - Google Analytics -->
    <div class="logo"><a href="."><img class="logo" alt="FCA logo"
          src="https://st11798p010.blob.core.windows.net/$web/assets/images/Logo_Stellantis_Neg_RGB.png"></a></div>
    <h1 class="title">{{environment.mopar ? (environment.cx ? "CX Gateway" : "Mopar B.I. Gateway"):"B.I.Gateway"}}
    </h1>
    <div class="head-section">
      <span class="User"> Welcome, {{ name }}</span>
    </div>
  </header>
</nav>