<div class="kpi-group-wrapper kpi-filter-wrapper">
  <div class='filter-select'>
    <mat-label>Brand</mat-label>
    <mat-select (selectionChange)="onBrandFilterChanged()" [(value)]="selectedBrand" class="kpi-brand-select">
      <mat-option *ngFor="let brand of brandList" [value]="brand"> {{brand.toString().toUpperCase()}}
      </mat-option>
    </mat-select>
  </div>
  <div class='filter-select'>
    <mat-label>Nameplate</mat-label>
    <mat-select (selectionChange)="onFilterChanged()" [(value)]="selectedNameplate" class="kpi-nameplate-select">
      <mat-option *ngFor="let nameplate of nameplateList" [value]="nameplate"> {{nameplate.toString().toUpperCase()}}
      </mat-option>
    </mat-select>
  </div>
  <div class='filter-select'>
    <mat-label>Business Center</mat-label>
    <mat-select (selectionChange)="onFilterChanged()" [(value)]="selectedBC" class="kpi-nameplate-select"
      [compareWith]="matchBcId">
      <mat-option *ngFor="let bc of bcList" [value]="bc.i_bc_id"> {{bc.n_bc_name.toString().toUpperCase()}}
      </mat-option>
    </mat-select>
  </div>
  <div class='filter-select' [ngClass]="{'isHidden': !hideBC}">
    <mat-label>Demand Metrics</mat-label>
    <mat-select (selectionChange)="onFilterChanged()" [(value)]="includeEmail" class="kpi-nameplate-select">
      <mat-option [value]="'true'">Include Email
      </mat-option>
      <mat-option [value]="'false'">Exclude Email
      </mat-option>
    </mat-select>
  </div>
</div>