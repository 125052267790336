import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-jeep-grid',
  templateUrl: './jeep-grid.component.html',
  styleUrls: ['./jeep-grid.component.scss']
})
export class JeepGridComponent implements OnInit {

  constructor() { }
  @Input() jeepGrid;
  ngOnInit(): void {
    // console.log('jeepGridData', this.jeepGrid);
  }

}
