import { Component, OnInit, Input } from "@angular/core";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Color, Label } from "ng2-charts";
import { KpiGridComponent } from "../../views/kpi-grid/kpi-grid.component";

@Component({
  selector: "month-trend-chart",
  templateUrl: "./month-trend-chart.component.html",
  styleUrls: ["./month-trend-chart.component.scss"],
})
export class MonthTrendChartComponent implements OnInit {
  @Input() set dataPoints(dataPoints: any[]) {
    this.lineChartData[0].data = dataPoints;
  }

  public lineChartData: ChartDataSets[] = [{ data: [1, 2, 3] }];
  public lineChartLabels: Label[] = ["", "", ""];
  public lineChartOptions: ChartOptions = {
    elements: {
      line: {
        tension: 0,
      },
    },
    responsive: true,
    aspectRatio: 3,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            drawOnChartArea: false,
            tickMarkLength: 1
          }
        },
      ],
      xAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            drawOnChartArea: false,
            tickMarkLength: 1
          }
        },
      ]
    },
  };
  public lineChartColors: Color[] = [
    {
      borderColor: "black",
      backgroundColor: this.kpiGridComponent.chartColor(),
    },
  ];
  public lineChartLegend: boolean = false;
  public lineChartType: ChartType = "line";
  public lineChartPlugins = [];

  constructor(public kpiGridComponent: KpiGridComponent) { }

  ngOnInit() {
    this.kpiGridComponent.chartColor();
  }
}
