<div class="kpi-wrapper">

  <mat-tab-group class="tabClass" (selectedTabChange)="getRegion($event)">
    <mat-tab *ngFor="let obj of getRegionsData ; let index = index" [label]="obj.endpoint" endpoint="obj.endpoint">
      <ng-template mat-tab-label>
        <span>{{obj.label}}</span>
      </ng-template>
      <br>
      <select mat-raised-button color="primary" class="dateSelect" [(ngModel)]="month" (ngModelChange)="getRegion({})">
        <option *ngFor="let month of months">{{month}}</option>
      </select>
      <br>
      <app-jeep-region-view [jeepGrid]="jeepGrid" [month]="month"></app-jeep-region-view>
    </mat-tab>
  </mat-tab-group>
  <app-spinner-overlay overlay="true" *ngIf="loader">
  </app-spinner-overlay>

</div>