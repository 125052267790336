import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
export class ExcelService {
  constructor() { }
  public exportAsExcelFile(json: any[], json2: any[], json3: any[], filename: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json);
    const worksheet2: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json2);
    const worksheet3: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json3);
    worksheet['!cols'] = fitToColumn(json);
    worksheet2['!cols'] = fitToColumn(json2);
    worksheet3['!cols'] = fitToColumn(json3);
    function fitToColumn(arrayOfArray) {
      // get maximum character of each column
      return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
    }

    //console.log(worksheet)
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "State of Share MoM");
    XLSX.utils.book_append_sheet(workbook, worksheet2, "State of Share YoY");
    XLSX.utils.book_append_sheet(workbook, worksheet3, "Hover Fields");


    XLSX.writeFile(workbook, filename + '.xlsb')

    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportBCsAsExcelFile(json: any[], json2: any[], json3: any[], filename): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json);
    const worksheet2: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json2);
    const worksheet3: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json3);
    worksheet['!cols'] = fitToColumn(json);
    worksheet2['!cols'] = fitToColumn(json2);
    worksheet3['!cols'] = fitToColumn(json3);
    function fitToColumn(arrayOfArray) {
      // get maximum character of each column
      return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
    }

    //console.log(worksheet)
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "State of Share MoM");
    XLSX.utils.book_append_sheet(workbook, worksheet2, "State of Share YoY");
    XLSX.utils.book_append_sheet(workbook, worksheet3, "Hover Fields");


    XLSX.writeFile(workbook, filename + '.xlsb')

    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //this.saveAsExcelFile(excelBuffer, excelFileName);
  }



  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}