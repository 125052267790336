import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Pipe } from '@angular/core';
import { SafeResourceUrl, Title } from '@angular/platform-browser';
import { DataService } from 'src/app/services/data.service';
// import { Report } from './model/report';
import { ReportAdminComponent } from '../../layout/report-admin/report-admin.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DrawerService } from 'src/app/services/drawer.service';
import { Subject, Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
@Component({
  selector: 'app-adobe-archive',
  templateUrl: './adobearchive.component.html',
  styleUrls: ['./adobearchive.component.scss']
})
export class AdobeArchiveComponent implements OnInit, OnDestroy {
  public searchText = '';
  // public reports = Reports;
  public reports: any;
  public environment;
  public TextAnalyticsLinks;
  public SheetsReportsLinks;
  public TechnicalServicesLinks;
  public CXLinks;
  public CustomerExperienceLinks;
  leftIframeIsOpen = false;
  rightIframeIsOpen = false;
  loader = true;

  @ViewChild('leftIframe', { static: true }) leftIframe;
  @ViewChild('rightIframe', { static: true }) rightIframe;

  rightIframeUrl: SafeResourceUrl;
  leftIframeUrl: SafeResourceUrl;
  iframeWidth = 100;
  isAdmin = false;
  jeepDates = [];
  ramDates = [];
  isMobile: boolean;
  CDPLinks: any;
  constructor(
    public reportService: DataService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private drawerService: DrawerService,
    private loaderService: LoaderService,
    private userService: UserService,
    private titleService: Title
  ) {
    this.environment = environment;
  }

  ngOnInit() {
    console.log(localStorage.getItem('isAdmin'))
    this.isAdmin = localStorage.getItem('isAdmin') === 'true';

    let x = 1;
    let date = moment().format('MM-DD-YYYY');
    let jeepMonth = moment().format('MMMM')
    this.jeepDates.push({ monthHeader: true, text: moment().format('MMMM') })
    while (date > '08-02-2023') {
      date = moment().subtract(x, 'days').format('MM-DD-YYYY')
      if (jeepMonth !== moment().subtract(x, 'days').format('MMMM')) {
        this.jeepDates.push({ monthHeader: true, text: moment().subtract(x, 'days').format('MMMM') })
        jeepMonth = moment().subtract(x, 'days').format('MMMM')
      }
      this.jeepDates.push({ monthHeader: false, suffix: 'https://st11798p010.blob.core.windows.net/$web/assets/E-Shop - Jeep ' + date + '.xlsx', text: 'Jeep ' + date })
      x++;
    }

    let y = 1;
    let ramDate = moment().format('MM-DD-YYYY');
    let ramMonth = moment().format('MMMM')
    this.ramDates.push({ monthHeader: true, text: moment().format('MMMM') })

    while (ramDate > '08-02-2023') {
      if (ramMonth !== moment().subtract(y, 'days').format('MMMM')) {
        this.ramDates.push({ monthHeader: true, text: moment().subtract(y, 'days').format('MMMM') })
        ramMonth = moment().subtract(y, 'days').format('MMMM')
      }

      ramDate = moment().subtract(y, 'days').format('MM-DD-YYYY')
      this.ramDates.push({ monthHeader: false, suffix: 'https://st11798p010.blob.core.windows.net/$web/assets/E-Shop - Ram ' + ramDate + '.xlsx', text: 'Ram ' + ramDate })
      y++;
    }

    console.log(date)

  }
  //code for NgOnDestroy 
  subscription: Subscription;
  ngOnDestroy() {
    this.subscription.unsubscribe();
    console.log('calling unsubscribe')
  }






  // editReport(report: Report, event: Event) {
  //   event.preventDefault();
  //   const dialogRef = this.dialog.open(ReportAdminComponent, {
  //     width: '480px',
  //     data: report
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(result);
  //     if (result?.delete) {
  //       this.reportService.deleteReport(result.model);
  //     } else if (result) {
  //       this.reportService.editReport(result)
  //     }
  //   });
  // }


  // openSnackBar() {
  //   this.snackBar.openFromComponent(RequestSnackbarComponent, {
  //     duration: 5 * 1000,
  //   });
  // }

}
