import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { IndustryStandardMapService } from 'src/app/services/industry-standard-map.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, Subject } from 'rxjs';
export interface Element {
  source_make: string;
  source_model: string;
  source_trim: string;
  gold_standard_make: string;
  gold_standard_model: string;
  gold_standard_trim: string;


}
const COLUMNS_SCHEMA = [
  {
    key: 'isSelected',
    type: 'isSelected',
    label: '',
  },
  // {
  //   key: 'Id',
  //   type: 'number',
  //   label: 'Id',
  // },
  {
    key: 'source_make',
    type: 'text',
    label: 'source_make*',
  },
  {
    key: 'source_model',
    type: 'text',
    label: 'source_model*',
  },
  {
    key: 'source_trim',
    type: 'text',
    label: 'source_trim',
  },
  {
    key: 'gold_standard_make',
    type: 'text',
    label: 'gold_standard_make*',
  },
  {
    key: 'gold_standard_model',
    type: 'text',
    label: 'gold_standard_model*',
  },
  {
    key: 'gold_standard_trim',
    type: 'text',
    label: 'gold_standard_trim',
  },
  {
    key: 'isEdit',
    type: 'isEdit',
    label: '',
  }
];
@Component({
  selector: 'app-industry-standard-map',
  templateUrl: './industry-standard-map.component.html',
  styleUrls: ['./industry-standard-map.component.scss']
})
export class IndustryStandardMapComponent implements OnInit {
  columnsArray = [
    // {
    //   key: 'All',
    // },
    {
      key: 'source_make',
    },
    {
      key: 'source_model',
    },
    {
      key: 'source_trim',
    },
    {
      key: 'gold_standard_make',
    },
    {
      key: 'gold_standard_model',
    },
    {
      key: 'gold_standard_trim',
    }


  ]
  @ViewChild('scheduledOrdersPaginator')
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort();
  // public dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = COLUMNS_SCHEMA.map((col) => col.key);
  dataSource: any;
  columnsSchema: any = COLUMNS_SCHEMA;
  user: any;
  isLoading = true;
  pageNumber: number = 1;
  IsWait: boolean = false;
  IsRemoveAllEnabled: boolean = false;
  IsAddRowEnabled: boolean = false;
  // OriginalRecords: any[] = [];
  OriginalRecords = new MatTableDataSource<any>();
  isEditable = true;

  constructor(
    private goldenServiceService: IndustryStandardMapService,
    private changeDetectorRefs: ChangeDetectorRef,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loadData();
  }
  source = false;
  sourceModel = false;
  sourceMake = false;
  goldStdMake = false;
  goldStdModel = false;
  goldStdTrim = false;

  insterRow = false;
  isDisabled = true;
  addRowEvent = false;
  columKeyUp(obj, colObj) {
    console.log(obj, colObj);
    this.isDisabled = (this.insterRow) ? true : false;
    if (obj.source_make !== '' && obj.source_model !== '' && obj.gold_standard_make !== '' && obj.gold_standard_model !== '' && obj.gold_standard_model.length <= 128 && obj.source_make.length <= 128 && obj.source_model.length <= 128 && obj.source_trim.length <= 128 && obj.gold_standard_make.length <= 128 && obj.gold_standard_trim.length <= 128) {
      this.isDisabled = false;
    }
    if (colObj.key == 'source_make') {
      this.source = (obj.source_make.length > 128 || obj.source_make == '') ? true : false;
      return;
    }
    if (colObj.key == 'source_model') {
      this.sourceModel = (obj.source_model.length > 128 || obj.source_model == '') ? true : false;
      return
    }
    if (colObj.key == 'source_trim') {
      this.sourceMake = (obj.source_trim.length > 128) ? true : false;
      return;
    }
    if (colObj.key == 'gold_standard_make') {
      this.goldStdMake = (obj.gold_standard_make.length > 128 || obj.gold_standard_make == '') ? true : false;
      return;
    }
    if (colObj.key == 'gold_standard_model') {
      this.goldStdModel = (obj.gold_standard_model.length > 128 || obj.gold_standard_model == '') ? true : false;
      return;
    }
    if (colObj.key == 'gold_standard_trim') {
      this.goldStdTrim = (obj.gold_standard_trim.length > 128) ? true : false;
      return;
    }
  }
  ngAfterViewInit() { }
  loadData() {
    this.IsWait = true;
    this.goldenServiceService.getStudentData().subscribe((result: any) => {
      //this.user = result.slice(0, 100); // result;
      //this.dataSource = new MatTableDataSource(result.slice(0, 100));
      // this.changeDetectorRefs.detectChanges();
      this.dataSource = new MatTableDataSource(result);
      //this.OriginalRecords = new MatTableDataSource(JSON.parse(JSON.stringify(result)));
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.paginator._changePageSize(this.paginator.pageSize);
      this.changeDetectorRefs.detectChanges();
      this.IsWait = false;
      this.isEditable = true;
      this.isDisabled = true;
    });
  }
  addRow(obj) {
    this.insterRow = (obj) ? true : false;
    this.addRowEvent = true;
    const newRow = {
      Id: 0,
      source_make: '',
      source_model: '',
      source_trim: '',
      gold_standard_make: '',
      gold_standard_model: '',
      gold_standard_trim: '',
      isEdit: true,
    };
    this.dataSource.data = [newRow, ...this.dataSource.data];
    // console.log('row added',newRow)
    // this.save(newRow);
  }

  removeRow(id: number) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { routingChange: false }
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.IsWait = true;
          var deleteIDs: number[] = [];
          deleteIDs.push(id);

          this.goldenServiceService
            .deleteRecord(deleteIDs)
            .subscribe((result: any) => {
              this.dataSource.data = this.dataSource.data.filter(
                (u: { Id: number }) => {
                  return u.Id !== id;
                });
              this.IsWait = false;
              this.loadData();
            });
        }
      });
  }
  getSelectedIds: any;
  removeSelectedRows() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { sentIds: this.getSelectedIds, page: 'gold_map', routingChange: false }
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.IsWait = true;
          var selectedRows = this.dataSource.data.filter(
            (u: any) => u.isSelected
          );
          var selectedIds = selectedRows.map((a) => a.Id);
          this.goldenServiceService
            .deleteRecord(selectedIds)
            .subscribe((result: any) => {
              this.dataSource.data = this.dataSource.data.filter(
                (u: any) => !u.isSelected
              );
              this.IsWait = false;
              this.IsRemoveAllEnabled = false;
              this.loadData();
            });
        }
      });
    // this.dataSource.data = this.dataSource.data.filter((u: any) => !u.isSelected);
  }

  doFilter = (event: any) => {
    //console.log('event', event.value);
    this.dataSource.filter = event.value.trim().toLocaleLowerCase();
  };
  getColName: any;
  columnName(event) {
    console.log(event.value);
    this.getColName = event.value;

    this.dataSource.filterPredicate = (d: Element, filter: string) => {
      const textToSearch = d[this.getColName] && d[this.getColName].toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };

  }
  editRow(element: any) {

    if (!this.source && !this.sourceMake && !this.sourceModel && !this.goldStdMake && !this.goldStdModel && !this.goldStdTrim) {
      element.isEdit = !element.isEdit;
      this.addRowEvent = false;
      this.insterRow = false;
      this.isDisabled = true;
      // this.save(element).subscribe((id) => {
      //   element.id = id;
      // });
      this.save(element)
    }
  }

  save(entity: any) {
    this.IsWait = true;
    this.goldenServiceService.insertRecords(entity).subscribe((result: any) => {
      this.IsWait = false;
      this.loadData();
    });
  }

  // save(entity: any): Observable<any> {
  //   var subject = new Subject<string>();
  //   this.IsWait = true;
  //   this.goldenServiceService.insertRecords(entity).subscribe((result: any) => {
  //     this.IsWait = false;
  //     console.log('added record respons', result?.recordset[0]?.Id);
  //     subject.next(result?.recordset[0]?.Id);
  //   });
  //   return subject.asObservable();
  // }

  updateRemoveAllButton(entity: any, event: any) {
    entity.isSelected = event.checked;
    var selectedRows = this.dataSource.data.filter((u: any) => u.isSelected);
    if (selectedRows.length > 0) {
      this.IsRemoveAllEnabled = true;
    } else {
      this.IsRemoveAllEnabled = false;
    }
    console.log('selected rows are: ', selectedRows);
    this.getSelectedIds = selectedRows;
  }
  cancelEdit(element: any) {
    this.IsWait = true;
    this.loadData();
  }

  // cancelEdit(element: any){
  //   this.IsWait = true;
  //   element.isEdit = false
  //   this.isEditable = true;
  //   const index = this.dataSource.data.indexOf(element);
  //   const record = this.dataSource.data[index];
  //   if (index > -1 && record.Id == 0) {
  //     this.dataSource.data.splice(index, 1);
  //     this.dataSource.data = this.dataSource.data.filter(
  //       (u: { Id: number }) => {
  //         return u.Id !== record.Id;
  //       });
  //   }
  //   if (index > -1 && record.Id > 0) {
  //     this.IsWait = true;
  //     // this.dataSource.data[index] = this.OriginalRecords.data.filter(
  //     //   (u: { Id: number }) => {
  //     //     return u.Id == record.Id;
  //     //   });
  //       //this.dataSource = new MatTableDataSource(this.dataSource.data);
  //      // this.dataSource = this.OriginalRecords;

  //      this.loadData();
  //     //  this.changeDetectorRefs.detectChanges();
  //   }
  //   this.IsWait = false;

  //  // this.changeDetectorRefs.detectChanges();
  // }
  editClicked(element: any) {
    //this.OriginalRecords.push( JSON.parse(JSON.stringify(element)));
    element.isEdit = !element.isEdit
    this.isEditable = false;

  }
  //updateRemoveAllButton

  // individualDelete(id: number){
  //   this.goldenServiceService
  //   .deleteRecord(id)
  //   .subscribe((result: any) => {
  //     console.log('test delete', result);
  //   });
  // }
}
