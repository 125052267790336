import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Report } from '../../views/dashboard/model/report';
import { environment } from '../../../../environments/environment';
import { UserService } from 'src/app/services/user.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-report-admin',
  templateUrl: './report-admin.component.html',
  styleUrls: ['./report-admin.component.scss']
})
export class ReportAdminComponent implements OnInit {
  labelPosition = 'after';
  model: Report;
  icons = JSON.parse(localStorage.getItem('icons')) || [];
  showDelete: boolean;
  environment: any;
  categories;
  reportPlatformData;
  constructor(public dialogRef: MatDialogRef<ReportAdminComponent>, public userService: UserService, @Inject(MAT_DIALOG_DATA) public data, public dataService: DataService) {
    if (this.data.URL) {
      this.showDelete = true;
    }
    this.model = Object.assign({}, this.data, { allowsIframe: this.data.AllowsIframe, requiresVPN: this.data.internal });
    this.environment = environment;
    this.userService.getReportCategories().subscribe(categories => this.categories = categories);
    this.userService.getReportPlatform().subscribe(reportData => this.reportPlatformData = reportData
    );

  }

  ngOnInit() {
  }
  //New Code Added
  ReportName = false;
  ReportUrl = false;
  ReportCategory = false;
  ReportIcon = false;
  ReportPlatform = false;
  onSubmit() {
    if (this.model) {
      this.ReportName = (this.model.name) ? false : true;
      this.ReportUrl = (this.model.URL) ? false : true;
      this.ReportIcon = (this.model.image) ? false : true;
      this.ReportCategory = (this.model.category) ? false : true;
      this.ReportPlatform = (this.model.platform) ? false : true;
    }
    if (!this.ReportName && !this.ReportUrl && !this.ReportIcon && !this.ReportCategory && !this.ReportPlatform) {
      if (this.showDelete) {
        console.log('reportDataEdit', this.model)
        this.dataService.editReport(this.model);
      } else {
        console.log('reportDataAdd', this.model)
        this.dataService.addReport(this.model);
      }
      this.dialogRef.close();
    }
  }

}
