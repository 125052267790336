/*istanbul ignore next*/
export const environment = {
  production: false,
  envName: 'staging',
  mopar: false,
  cx: false,
  resource: 'https%3A%2F%2Fdai-dev.extra.fcagroup.com%2Fadfs',
  apiUrl: 'https://azapp-11798-p-002-stage.azurewebsites.net/',
  auth: {
    clientId: 'a8ade7f1-bfd8-468c-b683-813c91df4915',
    authority:
      'https://sts-dev.fiat.com/adfs',
    knownAuthorities: ["sts-dev.fiat.com"],
    validateAuthority: false,
    redirectUri: 'https://dai-stage.extra.fcagroup.com/',
    cacheLocation: 'localStorage',
    postLogoutRedirectUri: 'https://dai-stage.extra.fcagroup.com/',
    navigateToLoginRequestUrl: false
  }
};
