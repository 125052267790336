<div *ngIf="!environment.mopar" [hidden]="leftIframeIsOpen || rightIframeIsOpen" class="workstream-grid">
  <div class="vertical-line"></div>
  <div id="demand" class="workstream">
    <h2 class="workstream-header">Validation Reports</h2>
    <div class="grid-container">
      <div class="grid-icon three-column" *ngFor="let link of AdminLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
</div>
<app-spinner-overlay overlay="true" *ngIf="loader">
</app-spinner-overlay>
<div *ngIf="environment.mopar && !environment.cx" [hidden]="leftIframeIsOpen || rightIframeIsOpen"
  class="workstream-grid">
  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">Global</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of GlobalLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">Connected Srvc</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of ConnectedServicesLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>

  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">Customer Care</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of CustomerCareLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">Executive</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of ExecutiveLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">Fleet</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of FleetLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>

  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">MVP</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of MvpLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>

  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">Quality</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of QualityLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>

  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">Sales & Mkt</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of SalesAndMarketingLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>

  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">Supply Chain</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of SupplyChainLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>

  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">TSO</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of TsoLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
</div>

<div *ngIf="environment.mopar && environment.cx" [hidden]="leftIframeIsOpen || rightIframeIsOpen"
  class="workstream-grid">
  <div class="workstream cx">
    <h2 class="workstream-header workstream-header-mopar">Customer Experience</h2>
    <div class="grid-container">
      <div class="grid-icon three-column" *ngFor="let link of CustomerExperienceLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="workstream cx">
    <h2 class="workstream-header workstream-header-mopar">CX/Customer First Dashboards</h2>
    <div class="grid-container">
      <div class="grid-icon three-column" *ngFor="let link of CXLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>

  <div class="workstream cx">
    <h2 class="workstream-header workstream-header-mopar">Technical Services</h2>
    <div class="grid-container">
      <div class="grid-icon three-column" *ngFor="let link of TechnicalServicesLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="workstream cx">
    <h2 class="workstream-header workstream-header-mopar">Sheets Reports</h2>
    <div class="grid-container">
      <div class="grid-icon three-column" *ngFor="let link of SheetsReportsLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="workstream cx">
    <h2 class="workstream-header workstream-header-mopar">Text Analytics</h2>
    <div class="grid-container">
      <div class="grid-icon three-column" *ngFor="let link of TextAnalyticsLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
</div>



<span style="clear: both;"></span>

<div class="dual-iframe-holder">
  <div [style.width.%]="iframeWidth" class="iframe-holder" style="float:left">
    <button (click)="closeLeftIframe()" [hidden]="!leftIframeIsOpen" mat-flat-button id="IdrButton"
      class="iframe-close-button">
      Close
    </button>

    <iframe title="leftIframe" *ngIf="leftIframeIsOpen" #leftIframe height="800px"
      [src]="leftIframeUrl | safe: 'resourceUrl'" id="IDR"></iframe>
  </div>
  <div class="iframe-spacer"></div>
  <div [style.width.%]="iframeWidth" class="iframe-holder" style="float:right">
    <button (click)="closeRightIframe()" [hidden]="!rightIframeIsOpen" mat-flat-button id="MddButton"
      class="iframe-close-button">
      Close
    </button>
    <iframe title="rightIframe" *ngIf="rightIframeIsOpen" #rightIframe height="800px"
      [src]="rightIframeUrl | safe: 'resourceUrl'" id="MDD"></iframe>
  </div>
</div>