import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { IndustryGoldStandardService } from 'src/app/services/industry-gold-standard.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, Subject } from 'rxjs';

export interface Element {
  c_mfg_recode: string;
  c_make_recode: string;
  c_mod_recode: string;
  c_seg_recode: string;
  c_mkt_basket: string;
  c_trim: string;
  c_roll_up: string;
  l_prem: string;
  c_alt_pwrtrn: string;

}
const COLUMNS_SCHEMA = [
  {
    key: 'isSelected',
    type: 'isSelected',
    label: '',
  },
  {
    key: 'c_mfg_recode',
    type: 'text',
    label: 'c_mfg_recode*',
  },
  {
    key: 'c_make_recode',
    type: 'text',
    label: 'c_make_recode*',
  },
  {
    key: 'c_mod_recode',
    type: 'text',
    label: 'c_mod_recode*',
  },
  {
    key: 'c_seg_recode',
    type: 'text',
    label: 'c_seg_recode*',
  },
  {
    key: 'c_mkt_basket',
    type: 'text',
    label: 'c_mkt_basket',
  },
  {
    key: 'c_trim',
    type: 'text',
    label: 'c_trim*',
  },
  {
    key: 'c_roll_up',
    type: 'text',
    label: 'c_roll_up*',
  },
  {
    key: 'l_prem',
    type: 'text',
    label: 'l_prem',
  },
  {
    key: 'c_alt_pwrtrn',
    type: 'text',
    label: 'c_alt_pwrtrn*'
  },
  {
    key: 'isEdit',
    type: 'isEdit',
    label: '',
  },
];

@Component({
  selector: 'app-industry-gold-standard',
  templateUrl: './industry-gold-standard.component.html',
  styleUrls: ['./industry-gold-standard.component.scss'],
  providers: [MatDialog]
})

export class IndustryGoldStandardComponent implements OnInit {

  @ViewChild('scheduledOrdersPaginator')
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort();
  // public dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = COLUMNS_SCHEMA.map((col) => col.key);
  dataSource: any;
  columnsSchema: any = COLUMNS_SCHEMA;
  user: any;
  isLoading = true;
  pageNumber: number = 1;
  IsWait: boolean = false;
  IsRemoveAllEnabled: boolean = false;
  IsAddRowEnabled: boolean = false;
  // OriginalRecords: any[] = [];
  OriginalRecords = new MatTableDataSource<any>();
  isEditable = true;
  columnsArray = [
    {
      key: 'c_mfg_recode',
    },
    {
      key: 'c_make_recode',
    },
    {
      key: 'c_mod_recode',
    },
    {
      key: 'c_seg_recode',
    },
    {
      key: 'c_mkt_basket',
    },
    {
      key: 'c_trim',
    },
    {
      key: 'c_roll_up',
    },
    {
      key: 'l_prem',
    },
    {
      key: 'c_alt_pwrtrn',
    }

  ]
  constructor(
    private goldenServiceService: IndustryGoldStandardService,
    private changeDetectorRefs: ChangeDetectorRef,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.loadData();

  }
  manufactured = false;
  make = false;
  model = false;
  segment = false;
  marketBasket = false;
  trim = false;
  rollUp = false;
  prem = false;
  powerTrain = false;
  tickIcon = false;
  insterRow = false;
  isDisabled = true;
  addRowEvent = false;
  editBtn = false;
  getColumnValue(obj) {
    // alert(this.editBtn);
    console.log('first', obj);
    // this.editBtn = true;
  }
  columKeyUp(obj, colObj) {
    // console.log(obj.l_prem.length)
    // console.log(obj.l_prem)
    // console.log(obj, colObj);
    this.isDisabled = (this.insterRow) ? true : false;

    if (obj.c_mfg_recode !== '' && obj.c_make_recode !== '' && obj.c_mod_recode !== '' && obj.c_seg_recode !== '' && obj.c_trim !== '' && obj.c_roll_up !== '' && obj.c_alt_pwrtrn !== '' && obj.c_roll_up.length <= 30 && obj.c_mfg_recode.length <= 64 && obj.c_make_recode.length <= 64 && obj.c_mod_recode.length <= 64 && obj.c_seg_recode.length <= 64 && obj.c_trim.length <= 64 && obj.c_alt_pwrtrn.length <= 30 && obj.c_mkt_basket.length <= 64
    ) {
      this.isDisabled = false;
    }
    if (colObj.key == 'c_mfg_recode') {
      this.manufactured = (obj.c_mfg_recode.length > 64 || obj.c_mfg_recode == '') ? true : false;
      return;
    }
    if (colObj.key == 'c_make_recode') {
      this.make = (obj.c_make_recode.length > 64 || obj.c_make_recode == '') ? true : false;
      return
    }
    if (colObj.key == 'c_mod_recode') {
      this.model = (obj.c_mod_recode.length > 64 || obj.c_mod_recode == '') ? true : false;
      return;
    }
    if (colObj.key == 'c_seg_recode') {
      this.segment = (obj.c_seg_recode.length > 64 || obj.c_seg_recode == '') ? true : false;
      return;
    }
    if (colObj.key == 'c_trim') {
      this.trim = (obj.c_trim.length > 64 || obj.c_trim == '') ? true : false;
      return;
    }
    if (colObj.key == 'c_roll_up') {
      this.rollUp = (obj.c_roll_up.length > 30 || obj.c_roll_up == '') ? true : false;
      return;
    }
    if (colObj.key == 'c_alt_pwrtrn') {
      this.powerTrain = (obj.c_alt_pwrtrn.length > 30 || obj.c_alt_pwrtrn == '') ? true : false;
      return;
    }
    if (colObj.key == 'c_mkt_basket') {
      this.marketBasket = (obj.c_mkt_basket.length > 64) ? true : false;
      return;
    }
    if (colObj.key == 'l_prem') {
      if (this.editBtn) {
        this.prem = (obj.l_prem.length > 2) ? true : false;
        return;
      } else {
        this.prem = (obj.l_prem.length > 1) ? true : false;
        return
      }


    }



  }
  keyPressFunction(obj) {
    // if(obj.c_mfg_recode !== '' && obj.c_make_recode !== '' && obj.c_mod_recode !== ''){
    //   this.tickIcon = true;
    //   return
    //  }
  }
  ngAfterViewInit() {
    // this.dataSource = ELEMENT_DATA.slice(1);
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }
  loadData() {
    this.IsWait = true;
    this.goldenServiceService.getStudentData().subscribe((result: any) => {
      //this.user = result.slice(0, 100); // result;
      //this.dataSource = new MatTableDataSource(result.slice(0, 100));
      // this.changeDetectorRefs.detectChanges();
      this.dataSource = new MatTableDataSource(result);
      //this.OriginalRecords = new MatTableDataSource(JSON.parse(JSON.stringify(result)));
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.paginator._changePageSize(this.paginator.pageSize);
      this.changeDetectorRefs.detectChanges();
      this.IsWait = false;
      this.isEditable = true;
      this.isDisabled = true;
      // this.dataSource.filterPredicate = (data: Element, filter: string) => {
      //   return data.c_make_recode == filter;
      // };
    });
  }
  addRow(obj) {
    this.insterRow = (obj) ? true : false;
    this.addRowEvent = true;
    console.log('---', this.displayedColumns);
    const newRow = {
      Id: 0,
      c_mfg_recode: '',
      c_make_recode: '',
      c_mod_recode: '',
      c_seg_recode: '',
      c_mkt_basket: '',
      c_trim: '',
      c_roll_up: '',
      l_prem: '',
      c_alt_pwrtrn: '',
      isEdit: true,
    };
    this.dataSource.data = [newRow, ...this.dataSource.data];
    // console.log('row added',newRow)
    // this.save(newRow);
  }
  removeRow(id: number) {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: "500px",
        maxHeight: "300px",
        data: { sentIds: id, routingChange: false }
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.IsWait = true;
          var deleteIDs: number[] = [];
          deleteIDs.push(id);
          this.goldenServiceService
            .deleteRecord(deleteIDs)
            .subscribe((result: any) => {
              this.dataSource.data = this.dataSource.data.filter(
                (u: { Id: number }) => {
                  return u.Id !== id;
                });
              this.IsWait = false;
              this.loadData();
            });
        }
      });
  }
  removeSelectedRows() {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: "500px",
        maxHeight: "300px",
        data: { sentIds: this.getSelectedIds, page: 'gold_std', routingChange: false }
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.IsWait = true;
          var selectedRows = this.dataSource.data.filter(
            (u: any) => u.isSelected
          );
          var selectedIds = selectedRows.map((a) => a.Id);
          this.goldenServiceService
            .deleteRecord(selectedIds)
            .subscribe((result: any) => {
              this.dataSource.data = this.dataSource.data.filter(
                (u: any) => !u.isSelected
              );
              this.IsWait = false;
              this.IsRemoveAllEnabled = false;
              this.loadData();
            });
        }
      });
    // this.dataSource.data = this.dataSource.data.filter((u: any) => !u.isSelected);
  }
  doFilter = (event: any) => {
    //console.log('event', event.value);
    this.dataSource.filter = event.value.trim().toLocaleLowerCase();

  }
  getColName: any;
  columnName(event) {
    console.log(event.value);
    this.getColName = event.value;
    this.dataSource.filterPredicate = (d: Element, filter: string) => {
      const textToSearch = d[this.getColName] && d[this.getColName].toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }


  editRow(element: any) {
    // alert(this.editBtn);
    console.log('element', element);
    if (element.l_prem == null) {
      element.l_prem = '';
    }
    element.isEdit = !element.isEdit;
    if (!this.manufactured && !this.make && !this.model &&
      !this.segment && !this.marketBasket && !this.trim &&
      !this.rollUp && !this.prem && !this.powerTrain) {

      this.save(element);
      element.isEdit = !element.isEdit;
      this.addRowEvent = false;
      this.insterRow = false;
      this.isDisabled = true;
      // this.loadData();
      this.changeDetectorRefs.detectChanges();
    }
  }
  save(entity: any) {

    var subject = new Subject<string>();
    this.IsWait = true;
    this.goldenServiceService.insertRecords(entity).subscribe((result: any) => {
      this.IsWait = false;
      this.loadData();
    });
  }
  getSelectedIds: any;
  updateRemoveAllButton(entity: any, event: any) {
    entity.isSelected = event.checked;
    var selectedRows = this.dataSource.data.filter((u: any) => u.isSelected);
    if (selectedRows.length > 0) {
      this.IsRemoveAllEnabled = true;
    } else {
      this.IsRemoveAllEnabled = false;
    }
    console.log('selected rows are: ', selectedRows);
    this.getSelectedIds = selectedRows;
  }
  cancelEdit(element: any) {
    this.IsWait = true;
    this.loadData();
  }

  // cancelEdit(element: any){
  //   this.IsWait = true;
  //   element.isEdit = false
  //   this.isEditable = true;
  //   const index = this.dataSource.data.indexOf(element);
  //   const record = this.dataSource.data[index];
  //   if (index > -1 && record.Id == 0) {
  //     this.dataSource.data.splice(index, 1);
  //     this.dataSource.data = this.dataSource.data.filter(
  //       (u: { Id: number }) => {
  //         return u.Id !== record.Id;
  //       });
  //   }
  //   if (index > -1 && record.Id > 0) {
  //     this.IsWait = true;
  //     // this.dataSource.data[index] = this.OriginalRecords.data.filter(
  //     //   (u: { Id: number }) => {
  //     //     return u.Id == record.Id;
  //     //   });
  //       //this.dataSource = new MatTableDataSource(this.dataSource.data);
  //      // this.dataSource = this.OriginalRecords;

  //      this.loadData();
  //     //  this.changeDetectorRefs.detectChanges();
  //   }
  //   this.IsWait = false;

  //  // this.changeDetectorRefs.detectChanges();
  // }
  editClicked(element: any) {
    //this.OriginalRecords.push( JSON.parse(JSON.stringify(element)));
    element.isEdit = !element.isEdit
    this.isEditable = false;

  }
  //updateRemoveAllButton

  // individualDelete(id: number){
  //   this.goldenServiceService
  //   .deleteRecord(id)
  //   .subscribe((result: any) => {
  //     console.log('test delete', result);
  //   });
  // }
}
