import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service'
import { AlertPreference } from 'src/app/models/alertPreference';
import { Alert } from 'src/app/models/alert';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';



@Component({
  // tslint:disable-next-line: component-selector
  selector: 'save-snackbar',
  template: '<span class=\"request-snackbar\">We\'ve saved your changes.</span>'
})
export class SaveSnackbarComponent {

}

@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss']
})
export class UserPreferencesComponent implements OnInit {


  user;
  alerts: Array<Alert> = [];

  @ViewChild('alertInput', { static: false }) alertInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  userData: any = {};
  adData: any;
  addingAlert = false;
  userAlerts: Array<AlertPreference> = [];
  newAlert;
  newAlertPreference;
  bcDma: any = [];
  modelNameplateSegment: any = [];
  dmaList: any = [];
  filteredDmaList: any = [];
  nameplateList: any = [];
  brandList: any = [];
  bcList: any = [];
  segmentList: any = [];

  nameplateSelectedOptions: string[];
  brandSelectedOptions: string[];
  bcSelectedOptions: string[] = [];
  dmaSelectedOptions: string[];
  segmentSelectedOptions: string[];
  cmaAlertRelationships: any = {
    receive: false,
    source: [],
    nameplate: [],
    brand: [],
    segment: [],
    dma: [],
    bc: []
  };
  tab: any;

  constructor(public userService: UserService, private snackBar: MatSnackBar, private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.user = this.userService.getUser();
    this.userService.getUserPrefInitData().subscribe(this.initialize.bind(this));
    this.route.queryParams.subscribe(params => {
      this.tab = params.tab || 0;
    });
  }

  initialize(data) {
    this.userData = data.userAccount;
    console.log(this.userData)
    this.userData.includeEmail = this.userData.n_include_email == 'true' ? true : false;
    this.adData = data.adAccount;
    this.cmaAlertRelationships = data.cmaAlertRelationships;
    this.dmaList = data.bcDma.map(row => row.n_dma);
    this.nameplateList = [...new Set(data.modelNameplateSegment.map(row => row.X_NPL_RPT))];
    this.brandList = [...new Set(data.modelNameplateSegment.map(row => row.N_BRND))];
    this.segmentList = [...new Set(data.segments.map(row => row.N_SEG_TYP))];
    this.bcList = [...new Set(data.bcDma.map(row => row.x_bc))];
    this.bcDma = data.bcDma;
    this.filterDmaList();
    this.modelNameplateSegment = data.modelNameplateSegment;
  }



  matchDmaId(dma, id) {
    if (!dma || !id) {
      return false;
    }
    return id.toString() === dma.toString();
  }

  filterDmaList() {
    const t = this.bcDma
      ?.filter(row => this.cmaAlertRelationships.bc.includes(row.x_bc));

    this.filteredDmaList = t.map(row => row.n_dma);
    this.cmaAlertRelationships.dma = this.cmaAlertRelationships.dma.filter(dma => {
      const r = this.bcDma.find(row => row.n_dma === dma);
      return this.cmaAlertRelationships.bc.includes(r.x_bc);
    });

  }

  saveCmaAlertRelationships() {
    const mappedCmaAlertRelationships = {
      bc: this.cmaAlertRelationships.bc.map(bc => this.bcDma.find(row => row.x_bc === bc).i_bc_id),
      dma: this.cmaAlertRelationships.dma.map(dma => this.bcDma.find(row => row.n_dma === dma).c_dma),
      segment: this.cmaAlertRelationships.segment
        .map(segment => this.modelNameplateSegment.find(row => row.N_SEG_TYP === segment.toUpperCase()).i_segment_id)
    }
    this.userService.saveCmaAlertRelationships(this.userData.i_user_id, this.cmaAlertRelationships.receive ? 'Y' : 'N', mappedCmaAlertRelationships).subscribe(result => {
      this.snackBar.openFromComponent(SaveSnackbarComponent, {
        duration: 5 * 1000,
      });
      this.userService.getUserPrefInitData().subscribe(this.initialize.bind(this));

    });
  }

  save() {

    this.userService.saveUser(this.userData).subscribe(result => {

      this.snackBar.openFromComponent(SaveSnackbarComponent, {

        duration: 5 * 1000,
      });
    });
  }
}
