import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from '../app/components/views/dashboard/dashboard.component';
import { PricePaidDistributionComponent } from './components/views/ppd/ppd.component';
import { MsalGuard } from '@azure/msal-angular';
import { UserPreferencesComponent } from '../app/components/views/user-preferences/user-preferences.component'
import { DrawerComponent } from './components/layout/drawer/drawer.component';
import { NameplateRecapComponent } from './components/views/nameplate-recap/nameplate-recap.component';
import { IdrProspectsComponent } from './components/views/idr-prospects/idr-prospects.component';
import { ShareWalkComponent } from './components/views/share-walk/share-walk.component';
import { AdminViewComponent } from './components/views/admin-view/admin-view.component';
import { TicketComponent } from './components/views/ticket/ticket.component';

import { AdobeArchiveComponent } from './components/views/adobe-archive/adobearchive.component';
import { JeepOverViewComponent } from './components/view/jeep-global/jeep-over-view/jeep-over-view.component';
import { DealerMappingComponent } from './components/Trim/dealer-mapping/dealer-mapping.component';
import { TrimViewComponent } from './components/Trim/trim-view/trim-view.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DrawerComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'user-preferences',
    component: UserPreferencesComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'management-review',
    component: NameplateRecapComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'idr-prospects',
    component: IdrProspectsComponent
  },
  {
    path: 'share-walk',
    component: ShareWalkComponent
  },
  {
    path: 'admin',
    component: AdminViewComponent
  },
  {
    path: 'jeep-global',
    component: JeepOverViewComponent
  },
  {
    path: 'TrimView',
    component: TrimViewComponent
  },
  {
    path: 'ticket',
    component: TicketComponent
  },
  {
    path: 'adobe-archive',
    component: AdobeArchiveComponent
  },
  {
    path: 'ppd',
    component: PricePaidDistributionComponent
  },
  {
    path: '**',
    redirectTo: '/dashboard'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
