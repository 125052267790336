import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpClientModule,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: "root",
})
export class IndustryGoldStandardService {
  // private goldenUrl = 'http://localhost:1433/api/golden';
  private goldenUrl: string;
  apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
    this.goldenUrl = this.apiUrl + "api/golden";
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
  };

  private createCompleteRoute = (route: string, envAddress: string) => {
    return `${envAddress}/${route}`;
  };

  getStudentData(): Observable<any> {

    return this.http.get<any>(this.goldenUrl);
  }

  insertRecords(records: any): Observable<any> {
    return this.http.post<any>(this.goldenUrl, records);
  }

  deleteRecord(record: number[]): Observable<any> {
    console.log("record", record);
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: {
        id: record,
      },
    };
    return this.http.delete<any>(this.goldenUrl, options);
  }
  /*
    public create = (route: string, body: any) => {
      return this.http.post(this.createCompleteRoute(route, environment.urlAddress), body, this.generateHeaders());
    }

    public update = (route: string, body: any) => {
      return this.http.put(this.createCompleteRoute(route, environment.urlAddress), body, this.generateHeaders());
    }

    public delete = (route: string) => {
      return this.http.delete(this.createCompleteRoute(route, environment.urlAddress));
    }
    */
}
