<mat-tab-group [selectedIndex]="tab">
  <!-- <mat-tab label='BIG User Settings'>
    <form>
      <mat-form-field>
        <mat-select placeholder="My Priority Nameplate" name="nameplateList" [(value)]='userData.n_nameplate'>
          <mat-option value="{{nameplate}}" *ngFor="let nameplate of nameplateList">{{nameplate}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="My Priority DMA" name="dmaList" [(value)]='userData.i_dma_id'
          [compareWith]="matchDmaId">
          <mat-option value="{{dma.c_dma}}" *ngFor="let dma of bcDma">{{dma.n_dma}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-label>Include Email in
        Demand Metrics</mat-label>
      <mat-checkbox class="checkbox" name='includeEmail' [(ngModel)]="userData.includeEmail">
      </mat-checkbox>


      <mat-form-field>
        <input matInput disabled placeholder="Text"
          [value]="adData ? adData?.mobilePhone || 'No mobile phone available' : 'Loading...'">
      </mat-form-field>
      <mat-form-field>
        <input matInput disabled placeholder="Email"
          [value]="adData ? adData?.mail || 'No email available' : 'Loading...'">
      </mat-form-field>
      <div>
        <button mat-raised-button color="primary" (click)="save()">Save</button>

        <a mat-raised-button color="secondary" href=".">Return to dashboard</a>
      </div>
    </form>
  </mat-tab> -->


  <!--   <label *ngIf="userAlerts.length">Active Alerts</label>
      <app-alert-preference *ngFor="let alertPreference of userAlerts" [alertPreference]=alertPreference>
      </app-alert-preference>
      <div class="addingAlert">
        <div *ngIf="addingAlert">
          <mat-form-field>
    
            <mat-select placeholder="New Alert" [value]="newAlert" name="newAlert">
              <mat-option *ngFor="let alert of alerts" value="alert.i_id">
                Month over month sales for my priority DMA and my priority nameplate
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-radio-group [(ngModel)]="newAlertPreference" name="alertPreference">
            <mat-radio-button value="none">Don't Receive Alerts</mat-radio-button>
            <mat-radio-button value="text">Receive Text Alerts</mat-radio-button>
            <mat-radio-button value="email">Receive Email Alerts</mat-radio-button>
            <mat-radio-button value="both">Receive Email and Text Alerts</mat-radio-button>
          </mat-radio-group>
        </div>
        <button *ngIf="!addingAlert" mat-raised-button color="primary" (click)="addingAlert = true">Add Alert</button>
        <button *ngIf="addingAlert" mat-raised-button color="primary" (click)="addUserAlert()">Add Alert</button>
    
        <button *ngIf="addingAlert" mat-raised-button color="secondary" (click)="addingAlert = false">Cancel</button>
      </div> -->



  <!-- <mat-tab label='Configure Alerts'>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>Competitive Price Messaging Alerts</mat-panel-title>
        <mat-panel-description>You will {{cmaAlertRelationships.receive ? '' : 'not'}} receive daily CPMA alerts
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-checkbox [(ngModel)]='cmaAlertRelationships.receive'>I want to receive daily CPMA alerts</mat-checkbox>

      <div>
        Receive alerts for these segments (leave blank to receive all segments)
        <chip-multiselect [allOptions]='segmentList' [selectedOptions]="cmaAlertRelationships.segment"
          [name]="'Segment'">

        </chip-multiselect>
        Receive alerts from these BCs (leave blank to receive only national messages)
        <chip-multiselect [allOptions]="bcList" [selectedOptions]="cmaAlertRelationships.bc" [name]="'BC'"
          [onChange]="filterDmaList.bind(this)">
        </chip-multiselect>

        <div *ngIf="filteredDmaList.length">
          Receive alerts from these DMAs (leave blank to receive all DMAs in selected BCs)
          <chip-multiselect [allOptions]="filteredDmaList" [selectedOptions]="cmaAlertRelationships.dma" [name]="'DMA'">
          </chip-multiselect>

        </div>

      </div>
      <button color='primary' mat-flat-button (click)='saveCmaAlertRelationships()'>Save</button>
    </mat-expansion-panel>
  </mat-tab> -->
</mat-tab-group>