import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorService } from './error.service';

@Injectable()
export class CoreErrorHandlerService extends ErrorHandler {

  constructor(private logger: ErrorService) {
    super();
  }

  handleError(error) {

    if (error?.message?.includes('Token calls are blocked in hidden iframes')) {
      return;
    }

    if (error?.message?.includes('ClientAuthError: User login is required')) {
      return;
    }
    console.log('handle error called', error)
    this.logger.logError(error.message || error.toString(), error.stack || null).subscribe();
    super.handleError(error);
  }
}
