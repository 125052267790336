<!-- <app-industry-standard-map></app-industry-standard-map>  -->

<!-- <mat-tab-group (focusChange)="onFocusChange($event)">
  <mat-tab label="Industry Gold Standard">
    <ng-template matTabContent >
      <app-industry_gold_standard></app-industry_gold_standard>
    </ng-template>
  </mat-tab>
  <mat-tab label="Industry Standard Map" >
    <ng-template matTabContent>
      <app-industry-standard-map></app-industry-standard-map>
    </ng-template>
  </mat-tab>
</mat-tab-group> -->
<div class="model-header">
  <button type="button" class="close" [mat-dialog-close]>&times;</button>
</div>
<mat-tab-group #tabgroup style="margin-bottom:5px;" animationDuration="0" mat-align-tabs="start"
  (selectedIndexChange)="change(tabgroup,$event)" (selectedTabChange)="tabChangeFn($event)">
  <!-- you makes the mat-tabs "empty" -->
  <mat-tab label="industry_gold_standard">
    <ng-template matTabContent>
      <app-industry-gold-standard></app-industry-gold-standard>
    </ng-template>
  </mat-tab>
  <mat-tab label="industry_standard_map">
    <ng-template matTabContent>
      <app-industry-standard-map></app-industry-standard-map>
    </ng-template>
  </mat-tab>
  <mat-tab label="competitive_dealer_mapping">
    <ng-template matTabContent>
      <app-dealer-mapping></app-dealer-mapping>
    </ng-template>
  </mat-tab>
</mat-tab-group>
<div class="wrapper" [style.height]="maxHeigth">
  <div #tab [style.visibility]="index!=0 && indexOld!=0?'hidden':null" [@flyInOut]="getAnimation(0,indexOld,index)"
    (@flyInOut.done)="indexOld=index">
    <!-- <app-industry_gold_standard></app-industry_gold_standard> -->
  </div>
  <div #tab [style.visibility]="index!=1 && indexOld!=1?'hidden':null" [@flyInOut]="getAnimation(1,indexOld,index)"
    (@flyInOut.done)="indexOld=index">
    <!-- <app-industry-standard-map></app-industry-standard-map> -->
  </div>
  <div #tab [style.visibility]="index!=2 && indexOld!=2?'hidden':null" [@flyInOut]="getAnimation(2,indexOld,index)"
    (@flyInOut.done)="indexOld=index">
    <!-- <app-dealer-mapping></app-dealer-mapping> -->
    <!-- Dealer Mapping Place holder -->
  </div>
</div>
<!-- <pre>
  {{indexOld}}{{index}}
{{getAnimation(0,indexOld,index)|json}}
{{getAnimation(1,indexOld,index)|json}}
{{getAnimation(2,indexOld,index)|json}}
</pre> -->