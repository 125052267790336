<!-- <div fxLayout fxLayoutAlign="center center" class="filterClass">
  <mat-form-field fxFlex="40%">
    <input matInput type="text" (keyup)="doFilter($event.target)" placeholder="Filter">
  </mat-form-field>

</div> -->
<div fxLayout fxLayoutAlign="center center" class="filterClass" fxLayoutGap="20px">
  <mat-form-field class="showMonthClass">
    <mat-label>Select column</mat-label>
    <mat-select (selectionChange)="columnName($event)">
      <mat-option *ngFor="let obj of columnsArray" [value]="obj.key">
        {{obj.key}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <input matInput type="text" (keyup)="doFilter($event.target)" placeholder="Filter">
  </mat-form-field>
</div>
<article class="table-header">

  <button *ngIf="IsRemoveAllEnabled" class="button-remove-rows" mat-button (click)="removeSelectedRows()">
    Remove Rows
  </button>
  <button class="button-add-row" mat-button (click)="addRow('true')">Add Row</button>
</article>

<!-- <mat-progress-spinner mode="indeterminate" *ngIf="IsWait">
</mat-progress-spinner> -->

<table mat-table [dataSource]="dataSource" matSort>

  <ng-container [matColumnDef]="col.key" *ngFor="let col of columnsSchema">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ col.label }}
    </th>
    <td mat-cell *matCellDef="let element" (keyup)="columKeyUp(element,col)">
      <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
        <ng-container *ngSwitchCase="'isSelected'">
          <mat-checkbox (change)="updateRemoveAllButton(element, $event)"
            (ngModelChange)="updateRemoveAllButton(element, $event)"></mat-checkbox>
        </ng-container>
        <div class="btn-edit" *ngSwitchCase="'isEdit'">
          <button mat-button
            (click)="element.isEdit = !element.isEdit && addRowEvent = true && insterRow = true && isDisabled = true && editBtn = true;getColumnValue(element)">
            Edit
          </button>
          <button *ngIf="!IsRemoveAllEnabled" mat-button class="button-remove" (click)="removeRow(element.Id)">
            Delete
          </button>
        </div>
        <span *ngSwitchCase="'date'">
          {{ element[col.key] | date: 'mediumDate' }}
        </span>
        <span *ngSwitchDefault>
          {{ element[col.key] }}
        </span>
      </div>
      <div [ngSwitch]="col.type" *ngIf="element.isEdit">
        <div *ngSwitchCase="'isSelected'"></div>
        <div class="btn-edit" *ngSwitchCase="'isEdit'">
          <!-- <button mat-button (click)="element.isEdit = !element.isEdit">
            Done
          </button> -->
          <!-- <mat-icon (click)="addRowBtn(element)" *ngIf="tickIcon">check_circle</mat-icon> -->
          <button mat-button [disabled]="isDisabled" (click)="editRow(element);editBtn = false">
            Done
          </button>
          <button mat-button (click)="cancelEdit(element)">
            Cancel
          </button>
        </div>
        <mat-form-field class="form-input" *ngSwitchCase="'date'" appearance="fill">
          <mat-label>Choose a date</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="element[col.key]" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="form-input" *ngSwitchDefault>
          <input [type]="col.type" matInput [(ngModel)]="element[col.key]" />
        </mat-form-field>
      </div>
      <ng-container *ngIf="addRowEvent && manufactured && col.key === 'c_mfg_recode'">
        <span *ngIf="element.c_mfg_recode == ''" class="error-class">*Required</span>
        <span *ngIf="element.c_mfg_recode.length > 64 " class="error-class">Maximum character is 64</span>
      </ng-container>
      <ng-container *ngIf="addRowEvent && make && col.key === 'c_make_recode'">
        <span *ngIf="element.c_make_recode == ''" class="error-class">*Required</span>
        <span *ngIf="element.c_make_recode.length > 64 " class="error-class">Maximum character is 64</span>
      </ng-container>
      <ng-container *ngIf="addRowEvent && model && col.key === 'c_mod_recode'">
        <span *ngIf="element.c_mod_recode == ''" class="error-class">*Required</span>
        <span *ngIf="element.c_mod_recode.length > 64 " class="error-class">Maximum character is 64</span>
      </ng-container>
      <ng-container *ngIf="addRowEvent && marketBasket && col.key === 'c_mkt_basket'">
        <span *ngIf="element.c_mkt_basket.length > 64 " class="error-class">Maximum character is 64</span>
      </ng-container>
      <ng-container *ngIf="addRowEvent && segment && col.key === 'c_seg_recode'">
        <span *ngIf="element.c_seg_recode == ''" class="error-class">*Required</span>
        <span *ngIf="element.c_seg_recode.length > 64 " class="error-class">Maximum character is 64</span>
      </ng-container>
      <ng-container *ngIf="addRowEvent && trim && col.key === 'c_trim'">
        <span *ngIf="element.c_trim == ''" class="error-class">*Required</span>
        <span *ngIf="element.c_trim.length > 64 " class="error-class">Maximum character is 64</span>
      </ng-container>
      <ng-container *ngIf="addRowEvent && rollUp && col.key === 'c_roll_up'">
        <span *ngIf="element.c_roll_up == ''" class="error-class">*Required</span>
        <span *ngIf="element.c_roll_up.length > 30 " class="error-class">Maximum character is 30</span>
      </ng-container>
      <ng-container *ngIf="!editBtn && addRowEvent && prem && col.key === 'l_prem'">
        <span *ngIf="element.l_prem && element.l_prem.length > 1 " class="error-class">Maximum character is 1</span>
      </ng-container>
      <ng-container *ngIf="editBtn && addRowEvent && prem && col.key === 'l_prem'">
        <span *ngIf="element.l_prem && element.l_prem.length > 2 " class="error-class">Maximum character is 1</span>
      </ng-container>
      <ng-container *ngIf="addRowEvent && powerTrain && col.key === 'c_alt_pwrtrn'">
        <span *ngIf="element.c_alt_pwrtrn == ''" class="error-class">*Required</span>
        <span *ngIf="element.c_alt_pwrtrn.length > 30" class="error-class">Maximum character is 30</span>
      </ng-container>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <!-- (click)="ontimeclick(row)" -->
  <tr mat-row *matRowDef="let row; columns: displayedColumns" (keyup)="keyPressFunction(row)"></tr>
</table>
<mat-paginator #scheduledOrdersPaginator showFirstLastButtons="firstLastButtons" [length]="20"
  [pageSizeOptions]="[20,30,50,100]"></mat-paginator>
<!-- <mat-paginator class="demo-table" #paginator
     showFirstLastButtons="firstLastButtons"
     [length]="20"
     [pageSizeOptions]="[5, 10,20, 30, 50]">
</mat-paginator> -->
<div class="overlay" *ngIf="IsWait">
  <div class="center">
    <mat-progress-spinner mode="indeterminate" diameter=100>
    </mat-progress-spinner>
  </div>
</div>