<div class="modal-wrapper grid">
  <span class="grid-item">Report Name: </span>
  <div class="grid-item">
    <input matInput [(ngModel)]="model.name" class="commonInputClass"
      [ngClass]="(!model.name && ReportName) ? 'inputClass': ''" type="text" cdkFocusInitial>
    <span class="spanError" *ngIf="!model.name && ReportName">*Report Name Required</span>
  </div>

  <span class="grid-item">Report URL: </span>
  <div class="grid-item">
    <input matInput [(ngModel)]="model.URL" type="text" class="commonInputClass"
      [ngClass]="(!model.URL && ReportUrl) ? 'inputClass': ''">
    <span class="spanError" *ngIf="!model.URL && ReportUrl">*Report Url Required</span>
  </div>

  <span class="grid-item">Report Icon: <img src="https://st11798p010.blob.core.windows.net/$web/{{model.image}}"></span>
  <div class="grid-item">
    <select matInput [(ngModel)]="model.image" value={{model.image}} type="text" class="commonInputClass"
      [ngClass]="(!model.image && ReportIcon) ? 'inputClass': ''">
      <option *ngFor="let i of icons" [value]="i.file_location">
        {{i.name}}</option>
    </select>
    <span class="spanError" *ngIf="!model.image && ReportIcon">*Report Icon Required</span>
  </div>

  <span class="grid-item">Report Category: </span>
  <div class="grid-item">
    <select matInput [(ngModel)]="model.category" type="text" class="commonInputClass"
      [ngClass]="(!model.category && ReportCategory) ? 'inputClass': ''">
      <option *ngFor="let category of categories" value='{{category.category}}' [ngValue]='category.category'>
        {{category.category}}
      </option>
    </select>
    <span class="spanError" *ngIf="!model.category && ReportCategory">*Report Category Required</span>
  </div>

  <span class="grid-item">Allows Iframe: </span>
  <mat-checkbox [(ngModel)]="model.allowsIframe" [labelPosition]="labelPosition"></mat-checkbox>
  <!-- Added New for internal/VPN checkBox -->
  <span class="grid-item">Requires VPN: </span>
  <!--[ngStyle]="showDelete ? {'top': '332px'} : {'top': '312px'}" -->
  <mat-checkbox [(ngModel)]="model.requiresVPN" [ngClass]="model.requiresVPN == true ? 'vpnOnly' : ''"
    [labelPosition]="labelPosition"></mat-checkbox>

  <span class="grid-item">Report Tags: </span>
  <input matInput [(ngModel)]="model.tags" class="grid-item" type="text" cdkFocusInitial>
  <span class="grid-item">Platform</span>
  <div class="grid-item">
    <!-- <select matInput [(ngModel)]="model.platform" type="text" class="commonInputClass"
      [ngClass]="(!model.platform && ReportPlatform) ? 'inputClass': ''">
      <option>External</option>
      <option>QlikView</option>
      <option>QlikSense</option>
      <option>Cognos</option>
      <option>Tableau</option>
      <option>SharePoint</option>
      <option>Power BI</option>
    </select> -->

    <select matInput [(ngModel)]="model.platform" type="text" class="commonInputClass"
      [ngClass]="(!model.platform && ReportPlatform) ? 'inputClass': ''">
      <option *ngFor="let platform of reportPlatformData" value='{{platform.Platform}}' [ngValue]='platform.Platform'>
        {{platform.Platform}}
      </option>
    </select>
    <span class="spanError" *ngIf="!model.platform && ReportPlatform">*Report Platform Required</span>
  </div>


  <span class="grid-item">Owner Email</span>
  <input class="grid-item" color="primary" matInput [(ngModel)]="model.reportOwnerEmail" type="text">

  <span class="grid-item">Priority</span>
  <input class="grid-item" color="primary" matInput [(ngModel)]="model.priority" type="text">

  <button mat-flat-button class="closeButton" mat-dialog-close>Close</button>
  <!-- [mat-dialog-close]="model" -->
  <!-- (click)="onSubmit()" -->
  <button mat-flat-button color="primary" class="submitButton" (click)="onSubmit()">Submit</button>
  <button mat-flat-button *ngIf="showDelete" color="secondary" class="submitButton"
    [mat-dialog-close]="{model: model, delete: true}">Delete</button>

</div>