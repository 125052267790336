
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';


// add Google Analytics script to <head>
const script = document.createElement('script');
let gtagId;
if (!environment.mopar) {
  gtagId = environment.envName === 'prod' ? 'UA-146915285-1' : 'UA-146915285-2';
}
if (environment.mopar) {
  gtagId = 'G-63GLCJ48WS'
}
if (environment.cx) {
  gtagId = 'G-3BWXWD0YNB'
}
script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + gtagId);
document.head.appendChild(script);

if (environment.production) {
  enableProdMode();
} else { // Dev Only


  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: '2b3a7c83-ebee-4a49-896f-60421327d5cd'
    }
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
