import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { capitalizeAcronyms, intelligentShortening, toTitleCase } from "src/string-helpers";
import { DrawerService } from "src/app/services/drawer.service";
import { DataService } from "src/app/services/data.service";
import { KPIService } from "src/app/services/kpi.service";
import { ExcelService } from "src/app/services/excel.service";

//import { bcMapping } from "src/bc-mapping";

@Component({
  selector: "app-insight-grid",
  templateUrl: "./insight-grid.component.html",
  styleUrls: ["./insight-grid.component.scss"],
})
export class InsightGridComponent implements OnInit {
  brand;
  bc;
  nameplate;
  //chart = document.getElementById('myChart');

  myChart;

  @Input() insights;
  @Input() mom;
  segmentVolumeInsights;
  capitalizeAcronyms = capitalizeAcronyms;
  intelligentShortening = intelligentShortening;
  top5: any;
  toTitleCase = toTitleCase;
  jobLogs = {};
  includeEmail: any;


  data: any = [];
  salesTempo: number;
  constructor(
    public drawerService: DrawerService,
    public dataService: DataService,
    public kpiService: KPIService,
    private excelService: ExcelService
  ) {
    this.kpiService.filter$.subscribe(({ brand, nameplate, bc, includeEmail }) => {
      this.brand = brand;
      this.nameplate = nameplate;
      this.bc = bc;
      this.includeEmail = includeEmail
    });

    this.dataService.getSuccessfulJobLogs().subscribe((jobLogs) => {

      jobLogs.forEach(log => this.jobLogs[log.pipeline_name] = log.last_update.split('T')[0]);
    });

    this.dataService.getSalesTempo().subscribe((salesTempo) => {
      this.salesTempo = salesTempo[0]?.q_sales_sales_tempo || 0;
      // this.salesTempo = salesTempo[0] ? salesTempo[0].q_sales_sales_tempo : 0;
      // console.log('---', this.salesTempo);
      // console.log('---', salesTempo[0])
    });
  }

  getJobLogs(pipelineName) {
    return null;
    //this.jobLogs.find(val => val.pipeline_name = pipelineName).last_update.split('T')[0];
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {

    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

  }

}
