import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {
  name: string;
  userID: string;
  environment;
  constructor(private dataService: DataService) {
    this.environment = environment;
  }
  ngOnInit() {
    this.name = JSON.parse(localStorage.getItem('msalUser')).idTokenClaims.given_name;
    this.userID = JSON.parse(localStorage.getItem('msalUser')).idTokenClaims.email;
  }

  ngOnDestroy(): void {

  }
}
