import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { DrawerService } from "src/app/services/drawer.service";
import { KPIService } from "src/app/services/kpi.service";
import { LoaderService } from "src/app/services/loader.service";
import { Subject } from "rxjs";
import { toTitleCase } from "src/string-helpers";
import { UnderperformingKpis } from "src/app/models/underperformingKpis";
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from "@angular/platform-browser";
import { ExcelService } from "src/app/services/excel.service";

//var jexcel = require('json2excel');

@Component({
  selector: "app-kpi-wrapper",
  templateUrl: "./kpi-wrapper.component.html",
  styleUrls: ["./kpi-wrapper.component.scss"],
})
export class KpiWrapperComponent implements OnInit {
  public selectedNameplate;
  public selectedBrand;

  public announcement = "test";
  public underperformingKPIs: UnderperformingKpis;
  public insights;
  public totalKPIs;
  public nameplateList = [];
  public brandList = [];
  public user = "";
  public pendingCount: number = 0;
  public allNameplate = {};
  public isLoading: Subject<boolean> = this.loaderService.isLoading;
  toTitleCase: any;
  public selectedBC: number = 10;
  public bcList: any;
  public buttonState = "MoM";
  public opened;
  public contents;
  public nameplate;
  public bc = 0;
  includeEmail: string = 'false';
  salesTempo: any;
  constructor(
    public dataService: DataService,
    private loaderService: LoaderService,
    private drawerService: DrawerService,
    public kpiService: KPIService,
    private excelService: ExcelService,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer
  ) {

    iconRegistry.addSvgIcon(
      'help-circle',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/help_circle.svg'));
  }



  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("msalUser")).idTokenClaims.email;
    this.toTitleCase = toTitleCase;

    this.loaderService.pendingCount$.subscribe((n) => {
      this.pendingCount = n
    })
    this.drawerService.opened$.subscribe(({ opened, contents }) => {
      this.contents = contents;
      this.setContents(this.nameplate, this.bc)
    })
    // load user prefs this.userService
    this.kpiService.filter$.subscribe(({ nameplate, bc, includeEmail }) => {
      this.includeEmail = includeEmail
      this.nameplate = nameplate;
      this.bc = bc;
      this.setContents(nameplate, bc)

    })

    this.dataService.getSalesTempo().subscribe((salesTempo) => {

      this.salesTempo = salesTempo[0]?.q_sales_sales_tempo || 0;
    });
  }

  setContents(nameplate, bc) {
    if (this.contents == 'Problem Board') {
      this.kpiService.getNameplateUnderperformingKPIs(nameplate, bc)
        .subscribe((underperforming) => {
          this.underperformingKPIs = underperforming;
          this.toggleSwitch(this.buttonState);
        });
      (window as any).gtag('event', 'open problem board', {
        'event_category': 'problem board',
        'event_label': JSON.parse(localStorage.getItem('msalUser')).name
      })
    }
    else if (this.contents == 'State of Share') {

      (window as any).gtag('event', 'open state of share', {
        'event_category': 'state of share',
        'event_label': JSON.parse(localStorage.getItem('msalUser')).name
      })
      this.kpiService.getInsights(nameplate, this.includeEmail, bc).subscribe((insights) => {
        console.log("insights loaded", this.includeEmail)
        this.insights = insights;
        this.toggleSwitch(this.buttonState)

      })

    }
  }


  setNameplates(brandMap) {
    this.allNameplate = brandMap;
    Object.keys(brandMap).forEach((brand) => {
      this.brandList = this.brandList.concat(brand);
    });
    this.selectedBrand = this.brandList[0];
    this.nameplateList = brandMap[this.selectedBrand];
    this.selectedNameplate = this.nameplateList[0];
    this.kpiService.setFilter(this.selectedBrand, this.selectedNameplate, this.selectedBC, this.includeEmail);

  }

  setBCs(bcs) {
    this.bcList = bcs;
    this.kpiService.setFilter(this.selectedBrand, this.selectedNameplate, this.selectedBC, this.includeEmail);

  }

  export() {
    console.log(this.bc)
    if (this.bc == 10 || this.bc == 0) {


      let lowerFunnelConversion =
        this.insights.sales.values.map(function (v, i) {
          return ((v.currentMonthValue * 100.0 / this.insights.lfas.values[i].currentMonthValue) - (v.lastMonthValue * 100.0 / this.insights.lfas.values[i].lastMonthValue))?.toLocaleString(undefined,
            { maximumFractionDigits: 1 })
        }, this)
      let visitToLFARatio = this.insights.lfas.values.map(function (v, i) {
        return ((v.currentMonthValue * 100.0 / this.insights.visits.values[i].currentMonthValue) - (v.lastMonthValue * 100.0 / this.insights.visits.values[i].lastMonthValue))?.toLocaleString(undefined,
          { maximumFractionDigits: 1 })
      }, this)

      let lowerFunnelConversionYoY =
        this.insights.sales.values.map(function (v, i) {
          return ((v.currentMonthValue * 100.0 / this.insights.lfas.values[i].currentMonthValue) - (v.lastYearValue * 100.0 / this.insights.lfas.values[i].lastYearValue))?.toLocaleString(undefined,
            { maximumFractionDigits: 1 })
        }, this)
      let visitToLFARatioYoY = this.insights.lfas.values.map(function (v, i) {
        return ((v.currentMonthValue * 100.0 / this.insights.visits.values[i].currentMonthValue) - (v.lastYearValue * 100.0 / this.insights.visits.values[i].lastYearValue))?.toLocaleString(undefined,
          { maximumFractionDigits: 1 })
      }, this)


      let momArray = [
        ['Metric', ...this.insights.segmentVolume.values.map(v => v.n_bc)],
        ['MTD Segment Volume (JDP)', ...this.insights.segmentVolume.values.map(v => v.currentMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['Month over Month', ...this.insights.segmentVolume.values.map(v => v.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['Share'],
        //Refactor Share to remove calculation
        [this.insights.segmentVolume.nameplate, ...this.insights.share.values.map(v => v.currentMonthValue)],
        ['Month over Month', ...this.insights.share.values.map(v => v.monthOverMonthValue)],
        ['MoM Volume Impact', ...this.insights.share.values.map(v => v.monthOverMonthVolumeImpact?.toLocaleString(undefined,
          { maximumFractionDigits: 0 }))],

        ['Share Type'],
        ['Retail Share %', ...this.insights.retailShare.values.map(v => v.currentMonthValue)],
        ['Month Over Month', ...this.insights.retailShare.values.map(v => v.monthOverMonthValue)],
        ['Volume Impact of Share', ...this.insights.retailShare.values.map(v => v.monthOverMonthVolumeImpact?.toLocaleString(undefined,
          { maximumFractionDigits: 0 }))],
        ['Lease Share %', ...this.insights.leaseShare.values.map(v => v.currentMonthValue)],
        ['Month Over Month', ...this.insights.leaseShare.values.map(v => v.monthOverMonthValue)],
        ['Volume Impact of Share', ...this.insights.leaseShare.values.map(v => v.monthOverMonthVolumeImpact?.toLocaleString(undefined,
          { maximumFractionDigits: 0 }))],
        ['Model Detail'],
        [this.insights.modelShare.values[0]?.n_nameplate, ...this.insights.modelShare.values.slice(0, 10).map(v => v.monthOverMonthValue)],
        [this.insights.modelShare.values[10]?.n_nameplate, ...this.insights.modelShare.values.slice(10, 20).map(v => v.monthOverMonthValue)],
        [this.insights.modelShare.values[20]?.n_nameplate, ...this.insights.modelShare.values.slice(20, 30).map(v => v.monthOverMonthValue)],
        [this.insights.modelShare.values[30]?.n_nameplate, ...this.insights.modelShare.values.slice(30, 40).map(v => v.monthOverMonthValue)],
        [this.insights.modelShare.values[40]?.n_nameplate, ...this.insights.modelShare.values.slice(40, 50).map(v => v.monthOverMonthValue)],
        ['Sales', ...this.insights.sales.values.map(v => v.currentMonthValue)],
        ['Month over Month', ...this.insights.sales.values.map(v => v.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['Month Sales Projection', ...this.insights.sales.values.map(v => v.currentMonthValue / this.salesTempo)],
        ['MoM Demand'],
        ['Visits', ...this.insights.visits.values.map(v => v.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['LFAs', ...this.insights.lfas.values.map(v => v.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['Incentive Page Visits', ...this.insights.incentives.values.map(v => v.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['Leads', ...this.insights.leads.values.map(v => v.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['Showroom Traffic', ...this.insights.showroomTraffic.values.map(v => v.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['Visit to LFA ratio', ...visitToLFARatio],
        ['Combo Conversion', ...this.insights.comboConversion.values.map(v => (v.monthOverMonthDiff * 100.0)?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['Lower Funnel Conversion', ...lowerFunnelConversion],
        ['Inventory'],
        ['Current K Stock', ...this.insights.kStock.values.map(v => v.currentMonthValue)],
        ['Start Of Month K Stock', ...this.insights.kStock.values.map(v => v.q_monthly_stock_0_mo)],
        ['X Stock', ...this.insights.kStock.values.map(v => v.q_x_stock)],
        ['Start of Month X Stock', ...this.insights.kStock.values.map(v => v.q_monthly_x_stock_0_mo)],
        ['Turn Rate', ...this.insights.sales.values.map((v, i) => 100 * (v.currentMonthValue / this.insights.kStock.values[i].q_monthly_stock_0_mo))],
        ['Proj Turn Rate', ...this.insights.sales.values.map((v, i) => (100 * (v.currentMonthValue / this.insights.kStock.values[i].q_monthly_stock_0_mo)) / this.salesTempo)],
        ['Proj MoM', ...this.insights.sales.values.map((v, i) =>
        (((100 * (v.currentMonthValue / this.insights.kStock.values[i].q_monthly_stock_0_mo)) / this.salesTempo)
          - ((100 * (v.lastMonthValue / this.insights.kStock.values[i].q_monthly_stock_1_mo)) / this.salesTempo))

        )]
      ]
      let yoyArray = [
        ['Metric', ...this.insights.segmentVolume.values.map(v => v.n_bc)],
        ['MTD Segment Volume (JDP)', ...this.insights.segmentVolume.values.map(v => v.currentMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['Month over Month', ...this.insights.segmentVolume.values.map(v => v.monthOverMonthValue)],
        ['Share'],
        //Refactor Share to remove calculation
        [this.insights.segmentVolume.nameplate, ...this.insights.share.values.map(v => v.currentMonthValue)],
        ['Year over Year', ...this.insights.share.values.map(v => v.yearOverYearValue)],
        ['YoY Volume Impact', ...this.insights.share.values.map(v => v.yearOverYearVolumeImpact?.toLocaleString(undefined,
          { maximumFractionDigits: 3 }))],

        ['Share Type'],
        ['Retail Share %', ...this.insights.retailShare.values.map(v => v.currentMonthValue)],
        ['Year Over Year', ...this.insights.retailShare.values.map(v => v.yearOverYearValue)],
        ['Volume Impact of Share', ...this.insights.retailShare.values.map(v => v.yearOverYearVolumeImpact?.toLocaleString(undefined,
          { maximumFractionDigits: 0 }))],
        ['Lease Share %', ...this.insights.leaseShare.values.map(v => v.currentMonthValue)],
        ['Year Over Year', ...this.insights.leaseShare.values.map(v => v.yearOverYearValue)],
        ['Volume Impact of Share', ...this.insights.leaseShare.values.map(v => v.yearOverYearVolumeImpact?.toLocaleString(undefined,
          { maximumFractionDigits: 0 }))],
        ['Model Detail MoM'],
        [this.insights.modelShare.values[0]?.n_nameplate, ...this.insights.modelShare.values.slice(0, 10).map(v => v.monthOverMonthValue)],
        [this.insights.modelShare.values[10]?.n_nameplate, ...this.insights.modelShare.values.slice(10, 20).map(v => v.monthOverMonthValue)],
        [this.insights.modelShare.values[20]?.n_nameplate, ...this.insights.modelShare.values.slice(20, 30).map(v => v.monthOverMonthValue)],
        [this.insights.modelShare.values[30]?.n_nameplate, ...this.insights.modelShare.values.slice(30, 40).map(v => v.monthOverMonthValue)],
        [this.insights.modelShare.values[40]?.n_nameplate, ...this.insights.modelShare.values.slice(40, 50).map(v => v.monthOverMonthValue)],
        ['Model Detail YoY'],
        [this.insights.modelShare.values[0]?.n_nameplate, ...this.insights.modelShare.values.slice(0, 10).map(v => v.yearOverYearValue)],
        [this.insights.modelShare.values[10]?.n_nameplate, ...this.insights.modelShare.values.slice(10, 20).map(v => v.yearOverYearValue)],
        [this.insights.modelShare.values[20]?.n_nameplate, ...this.insights.modelShare.values.slice(20, 30).map(v => v.yearOverYearValue)],
        [this.insights.modelShare.values[30]?.n_nameplate, ...this.insights.modelShare.values.slice(30, 40).map(v => v.yearOverYearValue)],
        [this.insights.modelShare.values[40]?.n_nameplate, ...this.insights.modelShare.values.slice(40, 50).map(v => v.yearOverYearValue)],

        ['Sales', ...this.insights.sales.values.map(v => v.currentMonthValue)],
        ['Month over Month', ...this.insights.sales.values.map(v => v.yearOverYearValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['MoM Demand'],
        ['Visits', ...this.insights.visits.values.map(v => v.yearOverYearValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['LFAs', ...this.insights.lfas.values.map(v => v.yearOverYearValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['Incentive Page Visits', ...this.insights.incentives.values.map(v => v.yearOverYearValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['Leads', ...this.insights.leads.values.map(v => v.yearOverYearValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['Showroom Traffic', ...this.insights.showroomTraffic.values.map(v => v.yearOverYearValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['Visit to LFA ratio', ...visitToLFARatioYoY],
        ['Combo Conversion', ...this.insights.comboConversion.values.map(v => v.yearOverYearDiff * 100.0)],
        ['Lower Funnel Conversion', ...lowerFunnelConversionYoY],
        ['Inventory'],
        ['Current K Stock', ...this.insights.kStock.values.map(v => v.currentMonthValue)],
        ['Start Of Month K Stock', ...this.insights.kStock.values.map(v => v.q_monthly_stock_0_mo)],
        ['X Stock', ...this.insights.kStock.values.map(v => v.q_x_stock)],
        ['Start of Month X Stock', ...this.insights.kStock.values.map(v => v.q_monthly_x_stock_0_mo)]

      ]

      if (this.insights.customSplit1.values?.length && this.insights.customSplit1.nameplate == 'RAM LD') {
        momArray.splice(7, 0,
          ['DT', ...this.insights.customSplit1.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplit1.values.map(v => v.monthOverMonthValue)],
          ['DS', ...this.insights.customSplit2.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplit2.values.map(v => v.monthOverMonthValue)]
        )

        yoyArray.splice(7, 0,
          ['DT', ...this.insights.customSplit1.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplit1.values.map(v => v.yearOverYearValue)],
          ['DS', ...this.insights.customSplit2.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplit2.values.map(v => v.yearOverYearValue)]
        )

        momArray.splice(24, 0,
          ['DT', ...this.insights.customSplitSales1.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplitSales1.values.map(v => v.monthOverMonthValue)],
          ['DS', ...this.insights.customSplitSales2.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplitSales2.values.map(v => v.monthOverMonthValue)]
        )

        yoyArray.splice(24, 0,
          ['DT', ...this.insights.customSplitSales1.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplitSales1.values.map(v => v.yearOverYearValue)],
          ['DS', ...this.insights.customSplitSales2.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplitSales2.values.map(v => v.yearOverYearValue)]
        )

        momArray.splice(40, 4,
          ['DT K Stock', ...this.insights.customSplitStock1.values.map(v => v.currentMonthValue)],
          ['DT Start of Month K Stock', ...this.insights.customSplitStock1.values.map(v => v.q_monthly_stock_0_mo)],
          ['DT X Stock', ...this.insights.customSplitStock1.values.map(v => v.q_x_stock)],
          ['DT Start of Month X Stock', ...this.insights.customSplitStock1.values.map(v => v.q_monthly_x_stock_0_mo)],
          ['DT Turn Rate', ...this.insights.customSplitSales1.values.map((v, i) => 100 * (v.currentMonthValue / this.insights.customSplitStock1.values[i].q_monthly_stock_0_mo))],
          ['DT Proj Turn Rate', ...this.insights.customSplitSales1.values.map((v, i) => (100 * (v.currentMonthValue / this.insights.customSplitStock1.values[i].q_monthly_stock_0_mo)) / this.salesTempo)],
          ['DT Proj MoM', ...this.insights.customSplitSales1.values.map((v, i) =>
          (((100 * (v.currentMonthValue / this.insights.customSplitStock1.values[i].q_monthly_stock_0_mo)) / this.salesTempo)
            - ((100 * (v.lastMonthValue / this.insights.customSplitStock1.values[i].q_monthly_stock_1_mo)) / this.salesTempo))

          )],
          ['DS K Stock', ...this.insights.customSplitStock2.values.map(v => v.currentMonthValue)],
          ['DS Start of Month K Stock', ...this.insights.customSplitStock2.values.map(v => v.q_monthly_stock_0_mo)],
          ['DS X Stock', ...this.insights.customSplitStock2.values.map(v => v.q_x_stock)],
          ['DS Start of Month X Stock', ...this.insights.customSplitStock2.values.map(v => v.q_monthly_x_stock_0_mo)],
          ['DS Turn Rate', ...this.insights.customSplitSales2.values.map((v, i) => 100 * (v.currentMonthValue / this.insights.customSplitStock2.values[i].q_monthly_stock_0_mo))],
          ['DS Proj Turn Rate', ...this.insights.customSplitSales2.values.map((v, i) => (100 * (v.currentMonthValue / this.insights.customSplitStock2.values[i].q_monthly_stock_0_mo)) / this.salesTempo)],
          ['DS Proj MoM', ...this.insights.customSplitSales2.values.map((v, i) =>
          (((100 * (v.currentMonthValue / this.insights.customSplitStock2.values[i].q_monthly_stock_0_mo)) / this.salesTempo)
            - ((100 * (v.lastMonthValue / this.insights.customSplitStock2.values[i].q_monthly_stock_1_mo)) / this.salesTempo))

          )]
        )

        yoyArray.splice(40, 4,
          ['DT K Stock', ...this.insights.customSplitStock1.values.map(v => v.currentMonthValue)],
          ['DT Start of Month K Stock', ...this.insights.customSplitStock1.values.map(v => v.q_monthly_stock_0_mo)],
          ['DT X Stock', ...this.insights.customSplitStock1.values.map(v => v.q_x_stock)],
          ['DT Start of Month X Stock', ...this.insights.customSplitStock1.values.map(v => v.q_monthly_x_stock_0_mo)],
          ['DS K Stock', ...this.insights.customSplitStock2.values.map(v => v.currentMonthValue)],
          ['DS Start of Month K Stock', ...this.insights.customSplitStock2.values.map(v => v.q_monthly_stock_0_mo)],
          ['DS X Stock', ...this.insights.customSplitStock2.values.map(v => v.q_x_stock)],
          ['DS Start of Month X Stock', ...this.insights.customSplitStock2.values.map(v => v.q_monthly_x_stock_0_mo)]
        )



      }

      if (this.insights.customSplit1.values?.length && this.insights.customSplit1.nameplate == 'PACIFICA  TOTAL') {
        momArray.splice(7, 0,
          ['PACIFICA GAS', ...this.insights.customSplit1.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplit1.values.map(v => v.monthOverMonthValue)],
          ['PACIFICA HYBRID', ...this.insights.customSplit2.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplit2.values.map(v => v.monthOverMonthValue)]
        )

        yoyArray.splice(7, 0,
          ['PACIFICA GAS', ...this.insights.customSplit1.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplit1.values.map(v => v.yearOverYearValue)],
          ['PACIFICA HYBRID', ...this.insights.customSplit2.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplit2.values.map(v => v.yearOverYearValue)]
        )

        momArray.splice(24, 0,
          ['PACIFICA GAS', ...this.insights.customSplitSales1.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplitSales1.values.map(v => v.monthOverMonthValue)],
          ['PACIFICA HYBRID', ...this.insights.customSplitSales2.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplitSales2.values.map(v => v.monthOverMonthValue)]
        )

        yoyArray.splice(24, 0,
          ['PACIFICA GAS', ...this.insights.customSplitSales1.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplitSales1.values.map(v => v.yearOverYearValue)],
          ['PACIFICA HYBRID', ...this.insights.customSplitSales2.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplitSales2.values.map(v => v.yearOverYearValue)]
        )

        momArray.splice(40, 4,
          ['PACIFICA GAS K Stock', ...this.insights.customSplitStock1.values.map(v => v.currentMonthValue)],
          ['PACIFICA GAS Start of Month K Stock', ...this.insights.customSplitStock1.values.map(v => v.q_monthly_stock_0_mo)],
          ['PACIFICA GAS X Stock', ...this.insights.customSplitStock1.values.map(v => v.q_x_stock)],
          ['PACIFICA GAS Start of Month X Stock', ...this.insights.customSplitStock1.values.map(v => v.q_monthly_x_stock_0_mo)],
          ['PACIFICA GAS Turn Rate', ...this.insights.customSplitSales1.values.map((v, i) => 100 * (v.currentMonthValue / this.insights.customSplitStock1.values[i].q_monthly_stock_0_mo))],
          ['PACIFICA GAS Proj Turn Rate', ...this.insights.customSplitSales1.values.map((v, i) => (100 * (v.currentMonthValue / this.insights.customSplitStock1.values[i].q_monthly_stock_0_mo)) / this.salesTempo)],
          ['PACIFICA GAS Proj MoM', ...this.insights.customSplitSales1.values.map((v, i) =>
          (((100 * (v.currentMonthValue / this.insights.customSplitStock1.values[i].q_monthly_stock_0_mo)) / this.salesTempo)
            - ((100 * (v.lastMonthValue / this.insights.customSplitStock1.values[i].q_monthly_stock_1_mo)) / this.salesTempo))

          )],
          ['PACIFICA HYBRID K Stock', ...this.insights.customSplitStock2.values.map(v => v.currentMonthValue)],
          ['PACIFICA HYBRID Start of Month K Stock', ...this.insights.customSplitStock2.values.map(v => v.q_monthly_stock_0_mo)],
          ['PACIFICA HYBRID X Stock', ...this.insights.customSplitStock2.values.map(v => v.q_x_stock)],
          ['PACIFICA HYBRID Start of Month X Stock', ...this.insights.customSplitStock2.values.map(v => v.q_monthly_x_stock_0_mo)],
          ['PACIFICA HYBRID Turn Rate', ...this.insights.customSplitSales2.values.map((v, i) => 100 * (v.currentMonthValue / this.insights.customSplitStock2.values[i].q_monthly_stock_0_mo))],
          ['PACIFICA HYBRID Proj Turn Rate', ...this.insights.customSplitSales2.values.map((v, i) => (100 * (v.currentMonthValue / this.insights.customSplitStock2.values[i].q_monthly_stock_0_mo)) / this.salesTempo)],
          ['PACIFICA HYBRID Proj MoM', ...this.insights.customSplitSales2.values.map((v, i) =>
          (((100 * (v.currentMonthValue / this.insights.customSplitStock2.values[i].q_monthly_stock_0_mo)) / this.salesTempo)
            - ((100 * (v.lastMonthValue / this.insights.customSplitStock2.values[i].q_monthly_stock_1_mo)) / this.salesTempo))

          )]
        )

        yoyArray.splice(40, 4,
          ['PACIFICA GAS K Stock', ...this.insights.customSplitStock1.values.map(v => v.currentMonthValue)],
          ['PACIFICA GAS Start of Month K Stock', ...this.insights.customSplitStock1.values.map(v => v.q_monthly_stock_0_mo)],
          ['PACIFICA GAS X Stock', ...this.insights.customSplitStock1.values.map(v => v.q_x_stock)],
          ['PACIFICA GAS Start of Month X Stock', ...this.insights.customSplitStock1.values.map(v => v.q_monthly_x_stock_0_mo)],
          ['PACIFICA HYBRID K Stock', ...this.insights.customSplitStock2.values.map(v => v.currentMonthValue)],
          ['PACIFICA HYBRID Start of Month K Stock', ...this.insights.customSplitStock2.values.map(v => v.q_monthly_stock_0_mo)],
          ['PACIFICA HYBRID X Stock', ...this.insights.customSplitStock2.values.map(v => v.q_x_stock)],
          ['PACIFICA HYBRID Start of Month X Stock', ...this.insights.customSplitStock2.values.map(v => v.q_monthly_x_stock_0_mo)]
        )
      }

      if (this.insights.customSplit1.values?.length && this.insights.customSplit1.nameplate == 'GRAND CHEROKEE TOTAL') {
        momArray.splice(7, 0,
          ['GRAND CHEROKEE WK', ...this.insights.customSplit1.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplit1.values.map(v => v.monthOverMonthValue)],
          ['GRAND CHEROKEE WL', ...this.insights.customSplit2.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplit2.values.map(v => v.monthOverMonthValue)]
        )

        yoyArray.splice(7, 0,
          ['GRAND CHEROKEE WK', ...this.insights.customSplit1.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplit1.values.map(v => v.yearOverYearValue)],
          ['GRAND CHEROKEE WL', ...this.insights.customSplit2.values.map(v => v.currentMonthValue)],
          ['Month Over Month', ...this.insights.customSplit2.values.map(v => v.yearOverYearValue)]
        )

      }
      console.log(momArray)
      let hoverFieldsArray =
        [//['Metric', ...this.insights.segmentVolume.values.map(v => v.n_bc)],
          ['Competitive Share']

        ]
      for (let x = 0; x < 10; x++) {
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].n_bc + ' Competitors', ...this.kpiService.competitiveShareCache[this.nameplate][x].map(json => json.nameplate)])
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].n_bc + ' Share', ...this.kpiService.competitiveShareCache[this.nameplate][x].map(json => json.currentMonthValue?.toLocaleString(undefined,
            { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].n_bc + ' MoM', ...this.kpiService.competitiveShareCache[this.nameplate][x].map(json => json.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].n_bc + ' YoY', ...this.kpiService.competitiveShareCache[this.nameplate][x].map(json => json.yearOverYearValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])

      }
      hoverFieldsArray.push(['Competitive Lease Share'])

      for (let x = 0; x < 10; x++) {
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].n_bc + ' Competitors', ...this.kpiService.competitiveLeaseShareCache[this.nameplate][x].map(json => json.nameplate)])
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].n_bc + ' Lease Share', ...this.kpiService.competitiveLeaseShareCache[this.nameplate][x].map(json => json.currentMonthValue?.toLocaleString(undefined,
            { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].n_bc + ' MoM', ...this.kpiService.competitiveLeaseShareCache[this.nameplate][x].map(json => json.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].n_bc + ' YoY', ...this.kpiService.competitiveLeaseShareCache[this.nameplate][x].map(json => json.yearOverYearValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])

      }

      hoverFieldsArray.push(['Competitive Retail Share'])

      for (let x = 0; x < 10; x++) {
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].n_bc + ' Competitors', ...this.kpiService.competitiveRetailShareCache[this.nameplate][x].map(json => json.nameplate)])
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].n_bc + ' Retail Share', ...this.kpiService.competitiveRetailShareCache[this.nameplate][x].map(json => json.currentMonthValue?.toLocaleString(undefined,
            { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].n_bc + ' MoM', ...this.kpiService.competitiveRetailShareCache[this.nameplate][x].map(json => json.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].n_bc + ' YoY', ...this.kpiService.competitiveRetailShareCache[this.nameplate][x].map(json => json.yearOverYearValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])
      }
      hoverFieldsArray.push(['Pin Market Share'])

      for (let x = 1; x < 10; x++) {
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].n_bc + ' Pin Markets', ...this.kpiService.pinMarketCache[this.nameplate][x].map(json => json.pin_market)])
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].n_bc + ' Retail Share', ...this.kpiService.pinMarketCache[this.nameplate][x].map(json => json.currentMonthValue?.toLocaleString(undefined,
            { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].n_bc + ' MoM', ...this.kpiService.pinMarketCache[this.nameplate][x].map(json => json.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].n_bc + ' YoY', ...this.kpiService.pinMarketCache[this.nameplate][x].map(json => json.yearOverYearValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])

      }

      hoverFieldsArray.push(['Pin Market Lease Share'])

      for (let x = 1; x < 10; x++) {
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].n_bc + ' Pin Markets', ...this.kpiService.pinMarketLeaseCache[this.nameplate][x].map(json => json.pin_market)])
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].n_bc + ' Lease Share', ...this.kpiService.pinMarketLeaseCache[this.nameplate][x].map(json => json.currentMonthValue?.toLocaleString(undefined,
            { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].n_bc + ' MoM', ...this.kpiService.pinMarketLeaseCache[this.nameplate][x].map(json => json.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].n_bc + ' YoY', ...this.kpiService.pinMarketLeaseCache[this.nameplate][x].map(json => json.yearOverYearValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])

      }

      hoverFieldsArray.push(['Pin Market Retail Share'])

      for (let x = 1; x < 10; x++) {
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].n_bc + ' Pin Markets', ...this.kpiService.pinMarketRetailCache[this.nameplate][x].map(json => json.pin_market)])
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].n_bc + ' Retail Share', ...this.kpiService.pinMarketRetailCache[this.nameplate][x].map(json => json.currentMonthValue?.toLocaleString(undefined,
            { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].n_bc + ' MoM', ...this.kpiService.pinMarketRetailCache[this.nameplate][x].map(json => json.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].n_bc + ' YoY', ...this.kpiService.pinMarketRetailCache[this.nameplate][x].map(json => json.yearOverYearValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])

      }
      if (this.kpiService.leadsBreakdownCache[this.nameplate] && this.kpiService.leadsBreakdownCache[this.nameplate].length) {

        hoverFieldsArray.push(['Leads Breakdown'])
        for (let x = 1; x < 10; x++) {
          hoverFieldsArray.push
            ([this.insights.segmentVolume.values[x].n_bc + ' Channel', ...this.kpiService.leadsBreakdownCache[this.nameplate][x].map(json => json.n_chnl)])
          hoverFieldsArray.push
            ([this.insights.segmentVolume.values[x].n_bc + ' This Month', ...this.kpiService.leadsBreakdownCache[this.nameplate][x].map(json => json.current_month_quantity?.toLocaleString(undefined,
              { maximumFractionDigits: 1 }))])
          hoverFieldsArray.push([this.insights.segmentVolume.values[x].n_bc + ' MoM', ...this.kpiService.leadsBreakdownCache[this.nameplate][x].map(json => json.monthOverMonthValue?.toLocaleString(undefined,
            { maximumFractionDigits: 1 }))])
          hoverFieldsArray.push([this.insights.segmentVolume.values[x].n_bc + ' YoY', ...this.kpiService.leadsBreakdownCache[this.nameplate][x].map(json => json.yearOverYearValue?.toLocaleString(undefined,
            { maximumFractionDigits: 1 }))])

        }
      }

      const d = new Date();

      console.log(hoverFieldsArray)
      this.excelService.exportAsExcelFile(

        //expecting an array of arrays
        momArray
        ,
        yoyArray,
        hoverFieldsArray,
        "StateOfShareNational" + this.nameplate + d.toDateString()


      );
    }
    else {


      let momArray = [
        ['Metric', ...this.insights.segmentVolume.values.map(v => v.n_bc)],
        ['MTD Segment Volume (JDP)', ...this.insights.segmentVolume.values.map(v => v.currentMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['Month over Month', ...this.insights.segmentVolume.values.map(v => v.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['Share'],
        //Refactor Share to remove calculation
        [this.insights.segmentVolume.nameplate, ...this.insights.share.values.map(v => v.currentMonthValue)],
        ['Month over Month', ...this.insights.share.values.map(v => v.monthOverMonthValue)],
        ['MoM Volume Impact', ...this.insights.share.values.map(v => v.monthOverMonthVolumeImpact?.toLocaleString(undefined,
          { maximumFractionDigits: 0 }))],

        ['Share Type'],
        ['Retail Share %', ...this.insights.retailShare.values.map(v => v.currentMonthValue)],
        ['Month Over Month', ...this.insights.retailShare.values.map(v => v.monthOverMonthValue)],
        ['Volume Impact of Share', ...this.insights.retailShare.values.map(v => v.monthOverMonthVolumeImpact?.toLocaleString(undefined,
          { maximumFractionDigits: 0 }))],
        ['Lease Share %', ...this.insights.leaseShare.values.map(v => v.currentMonthValue)],
        ['Month Over Month', ...this.insights.leaseShare.values.map(v => v.monthOverMonthValue)],
        ['Volume Impact of Share', ...this.insights.leaseShare.values.map(v => v.monthOverMonthVolumeImpact?.toLocaleString(undefined,
          { maximumFractionDigits: 0 }))],
        ['Model Detail'],
        [this.insights.modelShare.values[0]?.n_nameplate, ...this.insights.modelShare.values.slice(0, 10).map(v => v.monthOverMonthValue)],
        [this.insights.modelShare.values[10]?.n_nameplate, ...this.insights.modelShare.values.slice(10, 20).map(v => v.monthOverMonthValue)],
        [this.insights.modelShare.values[20]?.n_nameplate, ...this.insights.modelShare.values.slice(20, 30).map(v => v.monthOverMonthValue)],
        [this.insights.modelShare.values[30]?.n_nameplate, ...this.insights.modelShare.values.slice(30, 40).map(v => v.monthOverMonthValue)],
        [this.insights.modelShare.values[40]?.n_nameplate, ...this.insights.modelShare.values.slice(40, 50).map(v => v.monthOverMonthValue)]
      ]
      let yoyArray = [
        ['Metric', ...this.insights.segmentVolume.values.map(v => v.n_bc)],
        ['MTD Segment Volume (JDP)', ...this.insights.segmentVolume.values.map(v => v.currentMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))],
        ['Month over Month', ...this.insights.segmentVolume.values.map(v => v.monthOverMonthValue)],
        ['Share'],
        //Refactor Share to remove calculation
        [this.insights.segmentVolume.nameplate, ...this.insights.share.values.map(v => v.currentMonthValue)],
        ['Year over Year', ...this.insights.share.values.map(v => v.yearOverYearValue)],
        ['YoY Volume Impact', ...this.insights.share.values.map(v => v.yearOverYearVolumeImpact?.toLocaleString(undefined,
          { maximumFractionDigits: 3 }))],

        ['Share Type'],
        ['Retail Share %', ...this.insights.retailShare.values.map(v => v.currentMonthValue)],
        ['Year Over Year', ...this.insights.retailShare.values.map(v => v.yearOverYearValue)],
        ['Volume Impact of Share', ...this.insights.retailShare.values.map(v => v.yearOverYearVolumeImpact?.toLocaleString(undefined,
          { maximumFractionDigits: 0 }))],
        ['Lease Share %', ...this.insights.leaseShare.values.map(v => v.currentMonthValue)],
        ['Year Over Year', ...this.insights.leaseShare.values.map(v => v.yearOverYearValue)],
        ['Volume Impact of Share', ...this.insights.leaseShare.values.map(v => v.yearOverYearVolumeImpact?.toLocaleString(undefined,
          { maximumFractionDigits: 0 }))],
        ['Model Detail MoM'],
        [this.insights.modelShare.values[0]?.n_nameplate, ...this.insights.modelShare.values.slice(0, 10).map(v => v.monthOverMonthValue)],
        [this.insights.modelShare.values[10]?.n_nameplate, ...this.insights.modelShare.values.slice(10, 20).map(v => v.monthOverMonthValue)],
        [this.insights.modelShare.values[20]?.n_nameplate, ...this.insights.modelShare.values.slice(20, 30).map(v => v.monthOverMonthValue)],
        [this.insights.modelShare.values[30]?.n_nameplate, ...this.insights.modelShare.values.slice(30, 40).map(v => v.monthOverMonthValue)],
        [this.insights.modelShare.values[40]?.n_nameplate, ...this.insights.modelShare.values.slice(40, 50).map(v => v.monthOverMonthValue)],
        ['Model Detail YoY'],
        [this.insights.modelShare.values[0]?.n_nameplate, ...this.insights.modelShare.values.slice(0, 10).map(v => v.yearOverYearValue)],
        [this.insights.modelShare.values[10]?.n_nameplate, ...this.insights.modelShare.values.slice(10, 20).map(v => v.yearOverYearValue)],
        [this.insights.modelShare.values[20]?.n_nameplate, ...this.insights.modelShare.values.slice(20, 30).map(v => v.yearOverYearValue)],
        [this.insights.modelShare.values[30]?.n_nameplate, ...this.insights.modelShare.values.slice(30, 40).map(v => v.yearOverYearValue)],
        [this.insights.modelShare.values[40]?.n_nameplate, ...this.insights.modelShare.values.slice(40, 50).map(v => v.yearOverYearValue)]
      ]
      const d = new Date();
      let hoverFieldsArray =
        [//['Metric', ...this.insights.segmentVolume.values.map(v => v.n_bc)],
          ['Competitive Share']

        ]
      for (let x = 0; x < this.insights.segmentVolume.values.length; x++) {
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].c_pin_mkt + ' Competitors', ...this.kpiService.competitiveShareCacheByPinMkt[this.nameplate][this.insights.segmentVolume.values[x].c_pin_mkt].map(json => json.nameplate)])
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].c_pin_mkt + ' Share', ...this.kpiService.competitiveShareCacheByPinMkt[this.nameplate][this.insights.segmentVolume.values[x].c_pin_mkt].map(json => json.currentMonthValue?.toLocaleString(undefined,
            { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].c_pin_mkt + ' MoM', ...this.kpiService.competitiveShareCacheByPinMkt[this.nameplate][this.insights.segmentVolume.values[x].c_pin_mkt].map(json => json.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].c_pin_mkt + ' YoY', ...this.kpiService.competitiveShareCacheByPinMkt[this.nameplate][this.insights.segmentVolume.values[x].c_pin_mkt].map(json => json.yearOverYearValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])

      }
      hoverFieldsArray.push(['Competitive Lease Share'])

      for (let x = 0; x < this.insights.segmentVolume.values.length; x++) {
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].c_pin_mkt + ' Competitors', ...this.kpiService.competitiveLeaseShareCacheByPinMkt[this.nameplate][this.insights.segmentVolume.values[x].c_pin_mkt].map(json => json.nameplate)])
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].c_pin_mkt + ' Lease Share', ...this.kpiService.competitiveLeaseShareCacheByPinMkt[this.nameplate][this.insights.segmentVolume.values[x].c_pin_mkt].map(json => json.currentMonthValue?.toLocaleString(undefined,
            { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].c_pin_mkt + ' MoM', ...this.kpiService.competitiveLeaseShareCacheByPinMkt[this.nameplate][this.insights.segmentVolume.values[x].c_pin_mkt].map(json => json.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].c_pin_mkt + ' YoY', ...this.kpiService.competitiveLeaseShareCacheByPinMkt[this.nameplate][this.insights.segmentVolume.values[x].c_pin_mkt].map(json => json.yearOverYearValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])

      }

      hoverFieldsArray.push(['Competitive Retail Share'])

      for (let x = 0; x < this.insights.segmentVolume.values.length; x++) {
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].c_pin_mkt + ' Competitors', ...this.kpiService.competitiveRetailShareCacheByPinMkt[this.nameplate][this.insights.segmentVolume.values[x].c_pin_mkt].map(json => json.nameplate)])
        hoverFieldsArray.push
          ([this.insights.segmentVolume.values[x].c_pin_mkt + ' Retail Share', ...this.kpiService.competitiveRetailShareCacheByPinMkt[this.nameplate][this.insights.segmentVolume.values[x].c_pin_mkt].map(json => json.currentMonthValue?.toLocaleString(undefined,
            { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].c_pin_mkt + ' MoM', ...this.kpiService.competitiveRetailShareCacheByPinMkt[this.nameplate][this.insights.segmentVolume.values[x].c_pin_mkt].map(json => json.monthOverMonthValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])
        hoverFieldsArray.push([this.insights.segmentVolume.values[x].c_pin_mkt + ' YoY', ...this.kpiService.competitiveRetailShareCacheByPinMkt[this.nameplate][this.insights.segmentVolume.values[x].c_pin_mkt].map(json => json.yearOverYearValue?.toLocaleString(undefined,
          { maximumFractionDigits: 1 }))])
      }

      this.excelService.exportBCsAsExcelFile(

        //expecting an array of arrays
        momArray,
        yoyArray,
        hoverFieldsArray,
        'StateOfShare' + this.nameplate + 'BC' + this.bc + d.toDateString()
      );
    }
  }

  toggleDrawer(contents) {
    this.drawerService.toggleDrawer(contents);
  }

  matchBcId(bc, id) {
    if (!bc || !id) {
      return false;
    }
    return bc === id;
  }

  setNameplateUnderperformingKPIs(kpis) {
    this.underperformingKPIs = kpis;
  }

  setNameplateTotalKPIs(kpis) {
    this.totalKPIs = kpis;
  }

  onFilterChanged() {
    this.updateKpi();
  }

  onBrandFilterChanged() {
    const brand = this.selectedBrand;
    this.nameplateList = this.allNameplate[brand];
    this.selectedNameplate = this.nameplateList[0];
    this.updateKpi();
  }

  updateKpi() {
    //this.kpiService.getNameplateTotalKPIs(this.selectedNameplate).subscribe(kpis => this.setNameplateTotalKPIs(kpis));
    if (this.selectedBC && this.selectedNameplate) {
      this.kpiService.setFilter(this.selectedBrand, this.selectedNameplate, this.selectedBC, this.includeEmail);

    }
  }

  toggleSwitch(event) {
    if (event == "MoM") {
      for (var kpi in this.underperformingKPIs) {
        if (this.underperformingKPIs[kpi].reverseSort) {
          this.underperformingKPIs[kpi].values?.sort(this.reverseMonthOverMonthSorter);
        } else {
          this.underperformingKPIs[kpi].values?.sort(this.monthOverMonthSorter);
        }
      }
      this.buttonState = "MoM";
    }
    if (event == "YoY") {

      for (var kpi in this.underperformingKPIs) {
        if (this.underperformingKPIs[kpi].reverseSort) {
          this.underperformingKPIs[kpi].values?.sort(this.reverseYearOverYearSorter);
        } else {
          this.underperformingKPIs[kpi].values?.sort(this.yearOverYearSorter);
        }
      }
      this.buttonState = "YoY";
    }
  }

  monthOverMonthSorter(firstValue, secondValue) {
    return firstValue.monthOverMonthDiff - secondValue.monthOverMonthDiff;
  }

  yearOverYearSorter(firstValue, secondValue) {
    return firstValue.yearOverYearDiff - secondValue.yearOverYearDiff;
  }

  reverseMonthOverMonthSorter(firstValue, secondValue) {
    return secondValue.monthOverMonthDiff - firstValue.monthOverMonthDiff;
  }

  reverseYearOverYearSorter(firstValue, secondValue) {
    return secondValue.yearOverYearDiff - firstValue.yearOverYearDiff;
  }
}
