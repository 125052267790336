import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-provide-feedback-modal',
  templateUrl: './provide-feedback-modal.component.html',
  styleUrls: ['./provide-feedback-modal.component.scss']
})

export class ProvideFeedbackModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ProvideFeedbackModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  @Input('mat-dialog-close')

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
