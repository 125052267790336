import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DealerMappingService } from 'src/app/services/dealer-mapping.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, Subject } from 'rxjs';
import { filter } from 'underscore';
import * as XLSX from 'ts-xlsx';
export interface Element {
  I_DLR_DIM: string;
  IDR_dealername: string;
  dealer_id_is: string;
  seller_name_ss: string;
  street_ss: string;
  zip_is: string;
  city_ss: string;
  state_ss: string;
  car_city_ss: string;
  group_flag: string;

}

const COLUMNS_SCHEMA = [
  {
    key: 'isSelected',
    type: 'isSelected',
    label: '',
  },
  {
    key: 'I_DLR_DIM',
    type: 'number',
    label: 'I_DLR_DIM*',
  },
  {
    key: 'IDR_dealername',
    type: 'text',
    label: 'IDR_dealername*',
  },
  {
    key: 'dealer_id_is',
    type: 'number',
    label: 'dealer_id_is*',
  },
  {
    key: 'seller_name_ss',
    type: 'text',
    label: 'seller_name_ss*',
  },
  {
    key: 'street_ss',
    type: 'text',
    label: 'street_ss*',
  },
  {
    key: 'zip_is',
    type: 'number',
    label: 'zip_is*',
  },
  {
    key: 'city_ss',
    type: 'text',
    label: 'city_ss*',
  },
  {
    key: 'state_ss',
    type: 'text',
    label: 'state_ss*',
  },
  {
    key: 'car_city_ss',
    type: 'text',
    label: 'car_city_ss',
  },
  {
    key: 'group_flag',
    type: 'text',
    label: 'group_flag',
  },
  {
    key: 'isEdit',
    type: 'isEdit',
    label: '',
  }
];
@Component({
  selector: 'app-dealer-mapping',
  templateUrl: './dealer-mapping.component.html',
  styleUrls: ['./dealer-mapping.component.scss']
})
export class DealerMappingComponent implements OnInit {
  columnsArray = [
    // {
    //   key: 'All',
    // },
    // {
    //   key: 'I_DLR_DIM',
    // },
    {
      key: 'IDR_dealername',
    },
    // {
    //   key: 'dealer_id_is',
    // },
    {
      key: 'seller_name_ss',
    },
    {
      key: 'street_ss',
    },
    // {
    //   key: 'zip_is',
    // },
    {
      key: 'city_ss',
    },
    {
      key: 'state_ss',
    },
    {
      key: 'car_city_ss',
    },
    {
      key: 'group_flag',
    }


  ]
  @ViewChild('scheduledOrdersPaginator')
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort();
  // public dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = COLUMNS_SCHEMA.map((col) => col.key);
  dataSource: any;
  columnsSchema: any = COLUMNS_SCHEMA;
  user: any;
  isLoading = true;
  pageNumber: number = 1;
  IsWait: boolean = false;
  IsRemoveAllEnabled: boolean = false;
  IsAddRowEnabled: boolean = false;
  // OriginalRecords: any[] = [];
  OriginalRecords = new MatTableDataSource<any>();
  isEditable = true;

  constructor(
    private dealerMappingServices: DealerMappingService,
    private changeDetectorRefs: ChangeDetectorRef,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loadData();
  }
  IDR_DealerName = false;
  i_dlr_dim = false;
  dealer_id_is = false;
  seller_name = false;
  street = false;
  city = false;
  zip = false;
  state = false;
  car_city = false;
  group_flag = false;

  insterRow = false;
  isDisabled = true;
  addRowEvent = false;
  getDataLength: any;
  getOriginalData: any
  columKeyUp(obj, colObj) {

    // this.isDisabled = false;
    this.isDisabled = (this.insterRow) ? true : false;
    if (obj.IDR_dealername !== '' && obj.seller_name_ss !== '' && obj.street_ss !== '' && obj.zip_is !== '' && obj.city_ss !== '' && obj.state_ss !== '' && obj.I_DLR_DIM !== '' && obj.dealer_id_is !== '') {
      this.isDisabled = false;
    }
    if (colObj.key == 'I_DLR_DIM') {
      this.i_dlr_dim = (obj.I_DLR_DIM == '') ? true : false;
      return;
    }
    if (colObj.key == 'IDR_dealername') {
      this.IDR_DealerName = (obj.IDR_dealername == '') ? true : false;
      return;
    }
    if (colObj.key == 'dealer_id_is') {
      this.dealer_id_is = (obj.dealer_id_is == '') ? true : false;
      return;
    }
    if (colObj.key == 'seller_name_ss') {
      this.seller_name = (obj.seller_name_ss == '') ? true : false;
      return
    }
    if (colObj.key == 'zip_is') {
      this.zip = (obj.zip_is == '') ? true : false;
      return;
    }
    if (colObj.key == 'city_ss') {
      this.city = (obj.city_ss == '') ? true : false;
      return;
    }
    if (colObj.key == 'street_ss') {
      this.street = (obj.street_ss == '') ? true : false;
      return;
    }
    if (colObj.key == 'state_ss') {
      this.state = (obj.state_ss == '') ? true : false;
      return;
    }

  }
  ngAfterViewInit() { }
  loadData() {
    this.IsWait = true;
    this.dealerMappingServices.getDealerData().subscribe((result: any) => {
      console.log('result', result)
      console.log('result', result.length);
      this.getOriginalData = result
      if (result.length > 0) {
        this.getDataLength = result.length;
      }
      //this.user = result.slice(0, 100); // result;
      //this.dataSource = new MatTableDataSource(result.slice(0, 100));
      // this.changeDetectorRefs.detectChanges();
      this.dataSource = new MatTableDataSource(result);
      //this.OriginalRecords = new MatTableDataSource(JSON.parse(JSON.stringify(result)));
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.paginator._changePageSize(this.paginator.pageSize);
      this.changeDetectorRefs.detectChanges();
      this.IsWait = false;
      this.isEditable = true;
      this.isDisabled = true;

    });
  }
  addRow(obj) {
    this.insterRow = (obj) ? true : false;
    this.addRowEvent = true;
    const newRow = {
      Id: 0,
      dealer_id_is: '',
      I_DLR_DIM: '',
      IDR_dealername: '',
      seller_name_ss: '',
      street_ss: '',
      zip_is: '',
      city_ss: '',
      state_ss: '',
      car_city_ss: '',
      group_flag: '',
      isEdit: true,
    };
    this.dataSource.data = [newRow, ...this.dataSource.data];
    console.log('row added', newRow);
    // this.save(newRow);
  }

  removeRow(id: number) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { routingChange: false }
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.IsWait = true;
          var deleteIDs: number[] = [];
          deleteIDs.push(id);

          this.dealerMappingServices
            .deleteRecord(deleteIDs)
            .subscribe((result: any) => {
              this.dataSource.data = this.dataSource.data.filter(
                (u: { Id: number }) => {
                  return u.Id !== id;
                });
              this.IsWait = false;
              this.loadData();
            });
        }
      });
  }
  getSelectedIds: any;
  removeSelectedRows() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { sentIds: this.getSelectedIds, page: 'dealer_map', routingChange: false }
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.IsWait = true;
          var selectedRows = this.dataSource.data.filter(
            (u: any) => u.isSelected
          );
          var selectedIds = selectedRows.map((a) => a.Id);
          this.dealerMappingServices
            .deleteRecord(selectedIds)
            .subscribe((result: any) => {
              this.dataSource.data = this.dataSource.data.filter(
                (u: any) => !u.isSelected
              );
              this.IsWait = false;
              this.IsRemoveAllEnabled = false;
              this.loadData();
            });
        }
      });
    // this.dataSource.data = this.dataSource.data.filter((u: any) => !u.isSelected);
  }

  doFilter = (event: any) => {
    console.log('event', event.value);
    console.log(this.dataSource);

    this.dataSource.filter = event.value.trim().toLocaleLowerCase();
  };

  getColName: any;
  columnName(event) {
    console.log(event.value);
    // I_DLR_DIM: string;
    // IDR_dealername: string;
    // dealer_id_is: string;
    // seller_name_ss: string;
    // street_ss: string;
    // zip_is: string;
    // city_ss: string;
    // state_ss: string;
    // car_city_ss: string;
    // group_flag: string;
    this.getColName = event.value;
    this.dataSource.filterPredicate = (d: Element, filter: string) => {
      const textToSearch = d[this.getColName] && d[this.getColName].trim().toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };


  }
  getParticularId: any;
  editData = false;
  getEditData(element) {
    this.editData = true;
    console.log('OriginaArray', this.getOriginalData)
    for (var i in this.getOriginalData) {
      if (this.getOriginalData[i].Id == element.Id) {
        this.getParticularId = this.getOriginalData[i].Id;
      }
    }
    console.log('pushedIdArray', this.getParticularId);
  }
  editRow(element: any) {
    console.log('editRow', element);
    console.log('OriginaArrayDoneButton', this.getOriginalData);
    this.getOriginalData = this.getOriginalData.filter(item => item.Id != this.getParticularId);
    console.log('afterSplice', this.getOriginalData);

    // if (this.editData) {
    //   for (var i in this.getOriginalData
    //   ) {
    //     if (this.getOriginalData[i].I_DLR_DIM == element.I_DLR_DIM) {
    //       alert('please enter unique Id for I_DLR_DIM field');
    //       element.isEdit = true;
    //       this.addRowEvent = false;
    //       this.insterRow = false;
    //       this.isDisabled = true;
    //       return false;
    //     }
    //     if (this.getOriginalData[i].dealer_id_is == element.dealer_id_is) {
    //       alert('please enter unique Id for dealer_id_is field')
    //       element.isEdit = true;
    //       this.addRowEvent = false;
    //       this.insterRow = false;
    //       this.isDisabled = true;
    //       return false;
    //     }
    //     if (this.getOriginalData[i].city_ss == element.city_ss) {
    //       alert('please enter unique Id for city_ss field')
    //       element.isEdit = true;
    //       this.addRowEvent = false;
    //       this.insterRow = false;
    //       this.isDisabled = true;
    //       return false;
    //     }
    //   }
    // }
    // this.save(element);
    if (!this.IDR_DealerName && !this.seller_name && !this.city && !this.state &&
      !this.zip && !this.street && !this.i_dlr_dim && !this.dealer_id_is) {
      element.isEdit = !element.isEdit;
      this.addRowEvent = false;
      this.insterRow = false;
      this.isDisabled = true;
      this.save(element)
    }
  }

  save(entity: any) {
    console.log(this.getOriginalData);
    // alert(this.editData);
    if (!this.editData) {
      for (var i in this.getOriginalData
      ) {
        if (this.getOriginalData[i].I_DLR_DIM == entity.I_DLR_DIM) {
          alert('please enter unique Id for I_DLR_DIM field');
          entity.isEdit = true;
          this.addRowEvent = false;
          this.insterRow = false;
          this.isDisabled = true;
          return false;
        }
        if (this.getOriginalData[i].dealer_id_is == entity.dealer_id_is) {
          alert('please enter unique Id for dealer_id_is field')
          entity.isEdit = true;
          this.addRowEvent = false;
          this.insterRow = false;
          this.isDisabled = true;
          return false;
        }
        if (this.getOriginalData[i].city_ss == entity.city_ss) {
          alert('please enter unique Id for city_ss field')
          entity.isEdit = true;
          this.addRowEvent = false;
          this.insterRow = false;
          this.isDisabled = true;
          return false;
        }
      }
    }
    if (entity.car_city_ss == null) {
      entity.car_city_ss = '';
    }
    if (entity.group_flag == null) {
      entity.group_flag = '';
    }
    this.IsWait = true;
    console.log('saveRow', entity);
    this.dealerMappingServices.insertRecords(entity).subscribe((result: any) => {
      this.IsWait = false;
      this.editData = false;
      this.loadData();
    });
  }

  updateRemoveAllButton(entity: any, event: any) {
    entity.isSelected = event.checked;
    var selectedRows = this.dataSource.data.filter((u: any) => u.isSelected);
    if (selectedRows.length > 0) {
      this.IsRemoveAllEnabled = true;
    } else {
      this.IsRemoveAllEnabled = false;
    }
    console.log('selected rows are: ', selectedRows);
    this.getSelectedIds = selectedRows;
  }
  cancelEdit(element: any) {
    this.IsWait = true;
    this.loadData();
  }


  editClicked(element: any) {
    //this.OriginalRecords.push( JSON.parse(JSON.stringify(element)));
    element.isEdit = !element.isEdit
    this.isEditable = false;

  }
  arrayBuffer: any;
  file: File;
  incomingfile(event) {
    this.file = event.target.files[0];

  }
  // fileUpload() {
  //   // const formData = new FormData();
  //   // formData.append('file', this.file);
  //   const formData = { file: this.file }
  //   console.log('formData', formData.file)
  //   this.dealerMappingServices.fileUploadService(this.file).subscribe((result: any) => {
  //     // this.Upload();
  //     // alert('File uploaded successfully');
  //     // if (result.status === 200) {
  //     //   alert('File uploaded successfully');
  //     // }
  //   });

  //   // const inputEl: HTMLInputElement = this.inputEl.nativeElement;
  //   // const fileCount: number = inputEl.files.length;
  //   // const formData = new FormData();
  //   // const headers = new Headers();
  //   // headers.set('Accept', 'application/json');
  //   // headers.delete('Content-Type'); // mandate for accepting binary content
  //   // if (fileCount > 0) {
  //   //     for (let i = 0; i < fileCount; i++) {
  //   //         formData.append('file', this.file);
  //   //     }
  //   //     try {
  //   //       this.dealerMappingServices.fileUpload(formData).subscribe((result: any) => {
  //   //                 if (result.status === 200) {
  //   //                     alert('File uploaded successfully');
  //   //                 }
  //   //             }, error => {
  //   //               alert('File contents mismatch');
  //   //             });
  //   //     } catch (e) {
  //   //         console.log('Error occured while posting uploaded file. See below message for details : \n');
  //   //         console.log(e);
  //   //     }
  //   // }
  // }
  // Upload() {
  //   let fileReader = new FileReader();
  //   fileReader.onload = (e) => {
  //     this.arrayBuffer = fileReader.result;
  //     var data = new Uint8Array(this.arrayBuffer);
  //     var arr = new Array();
  //     for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
  //     var bstr = arr.join("");
  //     var workbook = XLSX.read(bstr, { type: "binary" });
  //     var first_sheet_name = workbook.SheetNames[0];
  //     var worksheet = workbook.Sheets[first_sheet_name];
  //     console.log(XLSX.utils.sheet_to_json(worksheet, { raw: true }));
  //   }
  //   fileReader.readAsArrayBuffer(this.file);
  // }

}
