import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';

const getErrorMessage = (maxRetry: number) => `Tried to load resource over XHR for ${maxRetry} times without success`;

const DEFAULT_MAX_RETRIES = 3;
const DEFAULT_BACKOFF = 1000;

export function retryWithBackoff(delayMS: number, maxRetry = DEFAULT_MAX_RETRIES, backoffMS = DEFAULT_BACKOFF) {
  let retries = maxRetry;
  return (src: Observable<any>) =>
    src.pipe(retryWhen((errors: Observable<any>) => errors.pipe(mergeMap(error => {
      if (retries-- > 0) {
        const backoffTime = delayMS + (maxRetry - retries * backoffMS);
        return of(error).pipe(delay(backoffTime))
      }
      return throwError(getErrorMessage(maxRetry));
    }
    ))));
}