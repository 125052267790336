import { Component, OnInit, Input } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { first, tap } from 'rxjs/operators';
import { arrForEach } from '@microsoft/applicationinsights-core-js';
import { DataService } from 'src/app/services/data.service';
// import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-jeep-region-view',
  templateUrl: './jeep-region-view.component.html',
  styleUrls: ['./jeep-region-view.component.scss']
})
export class JeepRegionViewComponent implements OnInit {

  constructor(private dataService: DataService) {
  }
  @Input() jeepGrid;
  @Input() month;
  ngOnInit() {

  }

  getBudgetString() {
    if (parseInt(this.month.toString().slice(-2)) > 8) {
      return 'F2'
    }
    if (parseInt(this.month.toString().slice(-2)) > 4) {
      return 'F1'
    } else {
      return 'Budget'
    }

  }

  monthStringify() {
    let monthArray = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    if (this.month)
      return monthArray[parseInt(this.month.toString().slice(-2))] || this.month
    else
      return ""
  }

  sortJeepGrid(jeepGrid) {
    let nameplateOrder = ['TOTAL', 'AVENGER', 'AVENGER BEV', 'RENEGADE', 'RENEGADE 4XE', 'COMPASS', 'COMPASS 4XE', 'D-SUV (IAP)', 'GLADIATOR', 'WRANGLER', 'WRANGLER 4XE',
      'CHEROKEE', 'GRAND COMMANDER', 'GRAND CHEROKEE (WK)', 'GRAND CHEROKEE (WL)', 'GRAND CHEROKEE 4XE', 'WAGONEER', 'GRAND WAGONEER', 'COMMANDER', 'MERIDIAN']

    let regionOrder = ['TOTAL', 'NORTH AMERICA', 'INDIA & ASIA PACIFIC', 'CHINA', 'LATAM', 'ENLARGE EUROPE', 'MIDDLE EAST & AFRICA']
    let countryOrder = ['TOTAL', 'U.S. TOTAL', 'U.S. RETAIL', 'U.S. FLEET', 'CANADA', 'MEXICO', 'FRANCE', 'GERMANY', 'ITALY', 'SPAIN', 'UNITED KINGDOM',
      , 'AUSTRALIA', 'INDIA', 'JAPAN', 'SOUTH KOREA', 'CHINA', 'ARGENTINA', 'BRAZIL', 'LATAM (OTHER)', 'MIDDLE EAST', 'SAUDI ARABIA', 'UNITED ARAB EMIRATES', 'TURKEY', 'ISRAEL', 'IRAQ']
    jeepGrid.sort((a, b) => {
      if (a.REGION != b.REGION) {
        return (regionOrder.indexOf(a.REGION.toUpperCase()) < regionOrder.indexOf(b.REGION.toUpperCase())) ? -1 : 1;
      }
      if (a.COUNTRY != b.COUNTRY) {
        return (countryOrder.indexOf(a.COUNTRY.toUpperCase()) < countryOrder.indexOf(b.COUNTRY.toUpperCase())) ? -1 : 1;
      }
      return (nameplateOrder.indexOf(a.NAMEPLATE.toUpperCase()) < nameplateOrder.indexOf(b.NAMEPLATE.toUpperCase())) ? -1 : 1;
    })
    return jeepGrid;
  }
  exportexcel() {
    let globalData, europeData, latamData, meaData, chinaData, iapData, naftaData;
    let globalPromise, europePromise, latamPromise, meaPromise, chinaPromise, iapPromise, naftaPromise;
    globalPromise = this.dataService.getJeepGlobal('global', this.month).pipe(
      tap(rows => {
        globalData = this.sortJeepGrid((rows as any).body)
      }),
      first()).toPromise()
    naftaPromise = this.dataService.getJeepGlobal('nafta', this.month).pipe(
      tap(rows => {
        naftaData = this.sortJeepGrid((rows as any).body)
      }),
      first()).toPromise()

    europePromise = this.dataService.getJeepGlobal('europe', this.month).pipe(
      tap(rows => {
        europeData = this.sortJeepGrid((rows as any).body)
      }),
      first()).toPromise()

    iapPromise = this.dataService.getJeepGlobal('iap', this.month).pipe(
      tap(rows => {
        iapData = this.sortJeepGrid((rows as any).body)
      }),
      first()).toPromise()
    meaPromise = this.dataService.getJeepGlobal('mea', this.month).pipe(
      tap(rows => {
        meaData = this.sortJeepGrid((rows as any).body)
      }),
      first()).toPromise()
    chinaPromise = this.dataService.getJeepGlobal('china', this.month).pipe(
      tap(rows => {
        chinaData = this.sortJeepGrid((rows as any).body)
      }),
      first()).toPromise()
    latamPromise = this.dataService.getJeepGlobal('latam', this.month).pipe(
      tap(rows => {
        latamData = this.sortJeepGrid((rows as any).body)
      }),
      first()).toPromise()

    Promise.all([globalPromise, naftaPromise, europePromise, latamPromise, meaPromise, chinaPromise, iapPromise]).then(() => {
      // debugger;
      let dataArray = [globalData, naftaData, europeData, iapData, meaData, chinaData, latamData]
      let worksheetNames = ['Jeep Global', 'North America', 'Europe', 'IAP', 'MEA', 'China', 'LATAM']

      //Create workbook and worksheet
      let workbook = new Workbook();
      dataArray.forEach((jeepData, index) => {
        let worksheet = workbook.addWorksheet(worksheetNames[index]);
        var data = [];
        for (var i = 0; i < jeepData.length; i++) {
          var obj = jeepData[i];
          var NamePlate = obj.NAMEPLATE == 'TOTAL' ? (obj.COUNTRY == 'TOTAL' ? (obj.REGION == 'TOTAL' ? 'Jeep Global' : obj.REGION) : obj.COUNTRY) :
            obj.NAMEPLATE;
          data.push([
            NamePlate, obj.BUDGET, obj.LASTYEARSSALES, obj.PLAN, obj.SALES, obj.SALESPERCENTOFBUDGET,
            obj.SALESPERCENTOFPLAN, obj.SALESPERCENTOFLASTYEAR,
            obj.CYTDBUDGET, obj.LYTDSALES, obj.CYTDSALES, obj.CYTDSALESPERCENTOFBUDGET, obj.CYTDSALESPERCENTOFLASTYEAR,
            obj.CYBUDGET, obj.LYSALES, obj.CYPLAN,
          ]);
        }
        const header = ["", this.getBudgetString(), "Last Year Sales", "Plan", "Actual Sales", "Sale % of " + this.getBudgetString(), "Sales % of Plan", "Sales % vs LY",
          this.getBudgetString(), "Last Year Sales", "Actual Sales", "Sale % of " + this.getBudgetString(), "Sales % vs LY",
          this.getBudgetString(), "Last Year Sales", "Plan"]
        // worksheet.mergeCells(`B${column1.number}:H${column1.number}`);
        // let titleRow = worksheet.addRow([title]);
        // titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true }
        // worksheet.addRow([]);
        worksheet.mergeCells('B1:P1')
        worksheet.mergeCells('B2:H2');
        worksheet.mergeCells('I2:M2');
        worksheet.mergeCells('N2:P2');
        worksheet.getCell('B1').value = 'Global Jeep Brand Retail Performance ' + this.monthStringify() + ' ' + this.month.toString().slice(0, 4)
        worksheet.getCell('B2').value = this.monthStringify() + ' ' + this.month.toString().slice(0, 4)
        worksheet.getCell('I2').value = 'CYTD'
        worksheet.getCell('N2').value = 'CY'

        worksheet.getCell('B1').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        worksheet.getCell('B2').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        worksheet.getCell('I2').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        worksheet.getCell('N2').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        worksheet.getCell('B1').font = { bold: true };
        worksheet.getCell('B2').font = { bold: true };
        worksheet.getCell('I2').font = { bold: true };
        worksheet.getCell('N2').font = { bold: true };
        //Blank Row 
        // worksheet.addRow([]);
        //Add Header Row
        let headerRow = worksheet.addRow(header);

        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'C6E0B4' },
            bgColor: { argb: 'C6E0B4' }
          }
          cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        })
        let nameplateColumn = worksheet.getColumn(1);
        nameplateColumn.eachCell((cell, number) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        })

        // worksheet.addRows(data);
        // Add Data and Conditional Formatting
        data.forEach(d => {
          let row = worksheet.addRow(d);
          let highlightRow = false;
          let highlightedRows = ['Jeep Global', 'China', 'Enlarge Europe', 'India & Asia Pacific', 'LATAM', 'Middle East & Africa', 'North America'
            , 'FRANCE', 'GERMANY', 'ITALY', 'UNITED KINGDOM', 'SPAIN', 'BRAZIL', 'ARGENTINA', 'LATAM (OTHER)', 'MIDDLE EAST', 'CHINA', 'INDIA', 'JAPAN', 'SOUTH KOREA', 'U.S. RETAIL', 'U.S. FLEET', 'MEXICO', 'CANADA', 'U.S. TOTAL', 'AUSTRALIA', 'MIDDLE EAST', 'SAUDI ARABIA', 'UNITED ARAB EMIRATES', 'TURKEY', 'ISRAEL', 'IRAQ']
          row.eachCell((cell, number) => {
            if (highlightedRows.indexOf(cell.value.toString()) >= 0) {
              highlightRow = true;
            }

            if (highlightRow) {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',

                fgColor: { argb: 'A9D08E' },
                bgColor: { argb: 'A9D08E' },
              }
              cell.font = { bold: true };
            }
            if ([6, 7, 8, 12, 13].indexOf(parseInt(cell.col)) > -1) {
              cell.numFmt = '#%'
              cell.font = cell.font || {}
              cell.font.color = cell.value < .995 ? { argb: "00FF0000" } : { argb: "FF000000" }
            } else {
              cell.numFmt = '###,###,###'
              // cell.font = cell.font || {}
              //cell.font.color = cell.value < 100 ? { argb: "00FF0000" } : { argb: "FF000000" }
            }
            cell.alignment = { vertical: 'middle', horizontal: number > 1 ? 'center' : 'left', wrapText: true };
          });
          let columns = worksheet.getColumn(6);
          // let qty = row.getCell(10);
          let color = '5062b7b8';
          if (+columns.values < 99.5 ? true : false) {
            color = '5062b7b8'
          }
          // qty.fill = {
          //   type: 'pattern',
          //   pattern: 'solid',
          //   fgColor: { argb: color }
          // }
        }
        );
        for (let outlinedRows = 0; outlinedRows < 10; outlinedRows++) {
          worksheet.getRow(i).outlineLevel = 1
        }

        worksheet.getColumn(1).width = 30;
      })



      //Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'JeepGlobalSalesReport' + this.month + '.xlsx');
      })
    })
    // console.log('Data', this.jeepGrid)
    /*Based on Table Id Code */
    // let element = document.getElementById('container');
    // const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // XLSX.writeFile(wb, this.fileName);
    // let data = this.jeepGrid.map(obj => Object.values(obj));

    /*based on Xlsx Style Code */


  }
}



