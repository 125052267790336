<table>

  <tr>
    <th>Leads Breakdown</th>
  </tr>
  <tr *ngIf="!data || !data.length">
    <td>Loading...</td>
  </tr>
  <tr *ngIf="data && data.length">
    <td>Channel</td>
    <td *ngIf="mom == 'MoM'">MoM</td>
    <td *ngIf="mom == 'YoY'">YoY</td>

  </tr>
  <tr *ngFor='let item of data'>
    <td>{{item.n_chnl}}</td>
    <td *ngIf="!mom" [ngClass]="{'negative':item.currentMonthValue < 0}">
      {{item.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}</td>
    <td *ngIf="mom == 'MoM'" [ngClass]="{'negative':item.monthOverMonthValue < 0}">
      {{item.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}ppt</td>
    <td *ngIf="mom == 'YoY'" [ngClass]="{'negative':item.yearOVerYearValue < 0}">
      {{item.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}ppt</td>
  </tr>
</table>