<div class='kpi' *ngIf="insights && insights.share.bc == 10">
  <table>
    <thead>
      <tr class='bc-headers'>
        <td>Metric</td>
        <td>National</td>
        <td>CABC</td>
        <td>DBC</td>
        <td>GLBC</td>
        <td>MABC</td>
        <td>MWBC</td>
        <td>NEBC</td>
        <td>SEBC</td>
        <td>SWBC</td>
        <td>WBC</td>
        <td>Updated</td>
      </tr>
    </thead>
    <tbody>
      <tr class='category-header'>
        <th>MTD Segment Volume (JDP)</th>
        <td *ngFor="
    let data of insights.segmentVolume.values | slice: 0:10;
    let i = index
  ">{{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 0})}}

        </td>
        <td [attr.rowspan]="insights.share.values.length ? 16 : 2">
          {{this.jobLogs['jdpa_sales_weekly']}}
        </td>
      </tr>
      <tr *ngIf="mom == 'MoM'">
        <td>{{insights.share.segmentName || 'Month Over Month'}}</td>
        <td *ngFor="
      let data of insights.segmentVolume.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.monthOverMonthValue < 0}">
          {{data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%

        </td>
      </tr>
      <tr *ngIf="mom == 'YoY'">
        <td>{{insights.share.segmentName || 'Year Over Year'}}</td>
        <td *ngFor="
      let data of insights.segmentVolume.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.yearOverYearValue < 0}">
          {{data.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%

        </td>
      </tr>
      <tr *ngIf="salesTempo && false">
        <td>{{'Segment Volume Projection'}}</td>
        <td *ngFor="
      let data of insights.segmentVolume.values | slice: 0:10;
      let i = index
    ">
          {{(data.currentMonthValue / salesTempo)?.toLocaleString(undefined, {maximumFractionDigits: 0})}}

        </td>
      </tr>



      <ng-container *ngIf="insights.share.values.length">
        <tr class='category-header'>
          <th>Share</th>
        </tr>
        <tr>
          <td>{{insights.share.segmentName || insights.share.nameplate?.toUpperCase()}}</td>
          <!-- -->
          <td *ngFor="
    let data of insights.share.values | slice: 0:10;
    let i = index
  " [ngClass]="{'right': true}">
            {{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%

            <div class="dropdown-content">
              <app-insight-competitive-popup [bc]=i [nameplate]=insights.share.nameplate>
              </app-insight-competitive-popup>
            </div>
          </td>
        </tr>
        <tr *ngIf="mom == 'MoM'">
          <td>{{insights.share.monthOverMonth || 'Month Over Month'}}</td>
          <td *ngFor=" let data of insights.share.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
      data.monthOverMonthValue < 0}" class="right">
            {{data.monthOverMonthValue?.toLocaleString(undefined,
            {maximumFractionDigits: 1})
            }}ppt
            <div class="dropdown-content">
              <app-insight-competitive-popup [bc]=i [nameplate]=insights.share.nameplate [mom]=mom>
              </app-insight-competitive-popup>
            </div>
          </td>
        </tr>

        <tr *ngIf="mom == 'YoY'">
          <td>{{insights.share.monthOverMonth || 'Year Over Year'}}</td>
          <td *ngFor=" let data of insights.share.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
      (data.yearOverYearValue) < 0}" class="right">{{
            (data.yearOverYearValue)?.toLocaleString(undefined,
            {maximumFractionDigits: 1})
            }}ppt
            <div class=" dropdown-content">
              <app-insight-competitive-popup [bc]=i [nameplate]=insights.share.nameplate [mom]=mom>
              </app-insight-competitive-popup>
            </div>
          </td>
        </tr>

        <tr>
          <td>{{insights.share.segmentName || 'Volume Impact of Share'}}</td>
          <td *ngFor="
      let data of insights.share.values | slice: 0:10;
      let i = index
    ">
            {{
            mom == 'MoM' ?
            (data.monthOverMonthVolumeImpact)?.toLocaleString(undefined,
            {maximumFractionDigits: 0})
            :
            (data.yearOverYearVolumeImpact)?.toLocaleString(undefined,
            {maximumFractionDigits: 0})

            }}
          </td>

        </tr>
        <ng-container
          *ngIf="toTitleCase(insights.share.nameplate) == 'Ram Ld' || toTitleCase(insights.share.nameplate) == 'Pacifica Total'">
          <tr>
            <td>{{insights.share.nameplate == 'RAM LD' ? 'DT' : 'PACIFICA GAS'}}</td>
            <!-- -->
            <td *ngFor="
        let data of insights.customSplit1.values | slice: 0:10;
        let i = index
      ">
              {{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%
            </td> <!-- -->
            <!--<ng-container *ngFor="
          let data of insights.share.values | slice: 0:10;
          let i = index">
            <td insight
              [data]="((data.currentMonthValue / insights.segmentVolume.values[i].currentMonthValue) * 100)?.toLocaleString(undefined, {maximumFractionDigits: 1}) + '%'"
              [bc]="i" [nameplate]="insights.share.nameplate" [popupType]="'Competitive'">
            </td>
          </ng-container> -->

          </tr>
          <tr *ngIf="mom == 'MoM'">
            <td>{{insights.share.monthOverMonth || 'Month Over Month'}}</td>
            <td *ngFor=" let data of insights.customSplit1.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
          (data.monthOverMonthValue) < 0}">{{(data.monthOverMonthValue)?.toLocaleString(undefined,
              {maximumFractionDigits: 1})
              }}ppt

            </td>
          </tr>

          <tr *ngIf="mom == 'YoY'">
            <td>{{insights.share.monthOverMonth || 'Year Over Year'}}</td>
            <td *ngFor=" let data of insights.customSplit1.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
          (data.yearOverYearValue) < 0}">{{data.yearOverYearValue?.toLocaleString(undefined,
              {maximumFractionDigits: 1})
              }}ppt

            </td>
          </tr>
          <tr>
            <td>{{insights.share.nameplate == 'RAM LD' ? 'DS' : 'PACIFICA HYBRID'}}</td>
            <!-- -->
            <td *ngFor="
      let data of insights.customSplit2.values | slice: 0:10;
      let i = index
    ">
              {{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%
            </td> <!-- -->
          </tr>
          <tr *ngIf="mom == 'MoM'">
            <td>{{insights.share.monthOverMonth || 'Month Over Month'}}</td>
            <td *ngFor=" let data of insights.customSplit2.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
        (data.monthOVerMonthValue) < 0}">{{data.monthOverMonthValue?.toLocaleString(undefined,
              {maximumFractionDigits: 1})
              }}ppt

            </td>
          </tr>

          <tr *ngIf="mom == 'YoY'">
            <td>{{insights.share.monthOverMonth || 'Year Over Year'}}</td>
            <td *ngFor=" let data of insights.customSplit2.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
        data.yearOverYearValue < 0}">{{data.yearOverYearValue?.toLocaleString(undefined,
              {maximumFractionDigits: 1})
              }}ppt

            </td>
          </tr>

        </ng-container>

        <ng-container *ngIf="toTitleCase(insights.share.nameplate) == 'Grand Cherokee Total'">
          <tr>
            <td>{{'GRAND CHEROKEE WK'}}</td>
            <td *ngFor="
      let data of insights.customSplit1.values | slice: 0:10;
      let i = index
    ">
              {{((data.currentMonthValue / (insights.segmentVolume.values[i].currentMonthValue * 1.0)) *
              100)?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%
            </td>

          </tr>
          <tr *ngIf="mom == 'MoM'">
            <td>{{insights.share.monthOverMonth || 'Month Over Month'}}</td>
            <td *ngFor=" let data of insights.customSplit1.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
        (data.currentMonthValue / insights.segmentVolume.values[i].currentMonthValue) - (data.lastMonthValue /
        insights.segmentVolume.values[i].lastMonthValue) < 0}">{{(((data.currentMonthValue /
              insights.segmentVolume.values[i].currentMonthValue)
              -(data.lastMonthValue / insights.segmentVolume.values[i].lastMonthValue)) *
              100)?.toLocaleString(undefined,
              {maximumFractionDigits: 1})
              }}ppt

            </td>
          </tr>

          <tr *ngIf="mom == 'YoY'">
            <td>{{insights.share.monthOverMonth || 'Year Over Year'}}</td>
            <td *ngFor=" let data of insights.customSplit1.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
        (((data.currentMonthValue / insights.segmentVolume.values[i].currentMonthValue) -(data.lastMonthValue /
        insights.segmentVolume.values[i].lastYearValue)) * 100) < 0}">{{(((data.currentMonthValue /
              insights.segmentVolume.values[i].currentMonthValue)
              -(data.lastMonthValue / insights.segmentVolume.values[i].lastYearValue)) * 100)?.toLocaleString(undefined,
              {maximumFractionDigits: 1})
              }}ppt

            </td>
          </tr>
          <tr>
            <td>{{'GRAND CHEROKEE WL'}}</td>
            <td *ngFor="
    let data of insights.customSplit2.values | slice: 0:10;
    let i = index
  ">
              {{((data.currentMonthValue / (insights.segmentVolume.values[i].currentMonthValue * 1.0)) *
              100)?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%
            </td> <!-- -->
          </tr>
          <tr *ngIf="mom == 'MoM'">
            <td>{{insights.share.monthOverMonth || 'Month Over Month'}}</td>
            <td *ngFor=" let data of insights.customSplit2.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
      (data.currentMonthValue / insights.segmentVolume.values[i].currentMonthValue) - (data.lastMonthValue /
      insights.segmentVolume.values[i].lastMonthValue) < 0}">{{(((data.currentMonthValue /
              insights.segmentVolume.values[i].currentMonthValue)
              -(data.lastMonthValue / insights.segmentVolume.values[i].lastMonthValue)) *
              100)?.toLocaleString(undefined,
              {maximumFractionDigits: 1})
              }}ppt

            </td>
          </tr>

          <tr *ngIf="mom == 'YoY'">
            <td>{{insights.share.monthOverMonth || 'Year Over Year'}}</td>
            <td *ngFor=" let data of insights.customSplit2.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
      (((data.currentMonthValue / insights.segmentVolume.values[i].currentMonthValue) -(data.lastMonthValue /
      insights.segmentVolume.values[i].lastYearValue)) * 100) < 0}">{{(((data.currentMonthValue /
              insights.segmentVolume.values[i].currentMonthValue)
              -(data.lastMonthValue / insights.segmentVolume.values[i].lastYearValue)) * 100)?.toLocaleString(undefined,
              {maximumFractionDigits: 1})
              }}ppt

            </td>
          </tr>


        </ng-container>





        <tr class='category-header'>
          <th>Share Type</th>
        </tr>
        <tr>
          <td>{{insights.share.segmentName || 'Total Mkt Share'}}</td>
          <!-- -->
          <td *ngFor="
    let data of insights.share.values | slice: 0:10;
    let i = index
  " [ngClass]="{'right': i > 0}">
            {{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%

            <div class="dropdown-content" *ngIf="i > 0">
              <app-insight-pin-market-popup [bc]=i [nameplate]=insights.share.nameplate [mom]=mom>
              </app-insight-pin-market-popup>
            </div>
          </td>
        </tr>
        <tr>
          <td>{{insights.share.monthOverMonth || 'Retail Share %'}}</td>
          <td *ngFor="
      let data of insights.retailShare.values | slice: 0:10;
      let i = index
    " [ngClass]="{'right': i > 0}">{{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})
            }}%

            <div class="dropdown-content" *ngIf="i > 0">
              <app-insight-pin-market-popup [bc]=i [nameplate]=insights.share.nameplate [mom]=mom
                [salesType]='"Retail"'>
              </app-insight-pin-market-popup>
            </div>
          </td>
        </tr>
        <tr *ngIf="mom == 'MoM'">
          <td>{{insights.share.monthOverMonth || 'Retail Share MoM'}}</td>
          <td *ngFor="
      let data of insights.retailShare.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.monthOverMonthValue < 0}" class='right'>
            {{data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})
            }}ppt
            <div class="dropdown-content">
              <app-insight-competitive-popup [bc]=i [nameplate]=insights.share.nameplate [mom]=mom
                [salesType]='"Retail"'>
              </app-insight-competitive-popup>
            </div>
          </td>
        </tr>

        <tr *ngIf="mom == 'YoY'">
          <td>{{insights.share.monthOverMonth || 'Retail Share YoY'}}</td>
          <td *ngFor="
      let data of insights.retailShare.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.yearOverYearValue < 0}" class='right'>
            {{data.yearOverYearValue?.toLocaleString(undefined,
            {maximumFractionDigits: 1})
            }}ppt

            <div class="dropdown-content">
              <app-insight-competitive-popup [bc]=i [nameplate]=insights.share.nameplate [mom]=mom
                [salesType]='"Retail"'>
              </app-insight-competitive-popup>
            </div>
          </td>
        </tr>

        <tr>
          <td>{{insights.share.monthOverMonth || 'Volume Impact Of Share'}}</td>
          <td *ngFor="
      let data of insights.retailShare.values | slice: 0:10;
      let i = index
    ">

            {{
            mom == "MoM" ?
            data.monthOverMonthVolumeImpact?.toLocaleString(undefined,
            {maximumFractionDigits: 0})
            :
            data.yearOverYearVolumeImpact?.toLocaleString(undefined,
            {maximumFractionDigits: 0})
            }}

          </td>
        </tr>

        <tr>
          <td>{{insights.share.monthOverMonth || 'Lease Share %'}}</td>
          <td *ngFor="
      let data of insights.leaseShare.values | slice: 0:10;
      let i = index
    " [ngClass]="{'right': i > 0}">{{
            data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})
            }}%

            <div class="dropdown-content" *ngIf="i > 0">
              <app-insight-pin-market-popup [bc]=i [nameplate]=insights.share.nameplate [mom]=mom [salesType]='"Lease"'>
              </app-insight-pin-market-popup>
            </div>
          </td>
        </tr>
        <tr *ngIf="mom=='MoM'">
          <td>{{insights.share.monthOverMonth || 'Lease Share MoM'}}</td>
          <td *ngFor="
      let data of insights.leaseShare.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.monthOverMonthValue < 0}" class='right'>
            {{data.monthOverMonthValue?.toLocaleString(undefined,
            {maximumFractionDigits: 1})
            }}ppt
            <div class="dropdown-content">
              <app-insight-competitive-popup [bc]=i [nameplate]=insights.share.nameplate [mom]=mom
                [salesType]='"Lease"'>
              </app-insight-competitive-popup>
            </div>
          </td>
        </tr>
        <tr *ngIf="mom=='YoY'">
          <td>{{insights.share.monthOverMonth || 'Lease Share YoY'}}</td>
          <td *ngFor="
      let data of insights.leaseShare.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.yearOverYearValue < 0}" class='right'>
            {{data.yearOverYearValue?.toLocaleString(undefined,
            {maximumFractionDigits: 1})
            }}ppt
            <div class="dropdown-content">
              <app-insight-competitive-popup [bc]=i [nameplate]=insights.share.nameplate [mom]=mom
                [salesType]='"Lease"'>
              </app-insight-competitive-popup>
            </div>
          </td>
        </tr>


        <tr>
          <td>{{insights.share.monthOverMonth || 'Volume Impact Of Share'}}</td>
          <td *ngFor="
      let data of insights.leaseShare.values | slice: 0:10;
      let i = index
    ">
            {{
            mom == "MoM" ?
            data.monthOverMonthVolumeImpact?.toLocaleString(undefined,
            {maximumFractionDigits: 0})
            :
            data.yearOverYearVolumeImpact?.toLocaleString(undefined,
            {maximumFractionDigits: 0})

            }} </td>
        </tr>

        <tr class='category-header'>
          <th>Model Detail</th>
        </tr>
        <ng-container *ngIf="mom == 'MoM'">
          <tr>
            <td>{{insights.modelShare.values[0]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 0:10;
        let i = index
      " [ngClass]="{'negative': data.monthOverMonthValue < 0}">
              {{data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>

          <tr *ngIf="insights.modelShare.values[10]">
            <td>{{insights.modelShare.values[10]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 10:20;
        let i = index
      " [ngClass]="{'negative': data.monthOverMonthValue < 0}">{{
              data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>

          <tr *ngIf="insights.modelShare.values[20]">
            <td>{{insights.modelShare.values[20]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 20:30;
        let i = index
      " [ngClass]="{'negative': data.monthOverMonthValue < 0}">{{data.monthOverMonthValue?.toLocaleString(undefined,
              {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>

          <tr *ngIf="insights.modelShare.values[30]">
            <td>{{insights.modelShare.values[31]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 30:40;
        let i = index
      " [ngClass]="{'negative': data.monthOverMonthValue < 0}">{{
              data.monthOverMonthValue?.toLocaleString(undefined,
              {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>

          <tr *ngIf="insights.modelShare.values[40]">
            <td>{{insights.modelShare.values[40]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 40:50;
        let i = index
      " [ngClass]="{'negative': data.monthOverMonthValue < 0}">{{
              data.monthOverMonthValue?.toLocaleString(undefined,
              {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>
        </ng-container>

        <ng-container *ngIf="mom == 'YoY'">
          <tr>
            <td>{{insights.modelShare.values[0]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 0:10;
        let i = index
      " [ngClass]="{'negative': data.yearOverYearValue < 0}">
              {{data.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>

          <tr *ngIf="insights.modelShare.values[10]">
            <td>{{insights.modelShare.values[10]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 10:20;
        let i = index
      " [ngClass]="{'negative': data.yearOverYearValue < 0}">{{
              data.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits:
              1 })
              }}ppt</td>
          </tr>

          <tr *ngIf="insights.modelShare.values[20]">
            <td>{{insights.modelShare.values[20]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 20:30;
        let i = index
      " [ngClass]="{'negative': data.yearOverYearValue < 0}">{{data.yearOverYearValue?.toLocaleString(undefined,
              {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>

          <tr *ngIf="insights.modelShare.values[30]">
            <td>{{insights.modelShare.values[31]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 30:40;
        let i = index
      " [ngClass]="{'negative': data.yearOverYearValue < 0}">{{
              data.yearOverYearValue?.toLocaleString(undefined,
              {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>

          <tr *ngIf="insights.modelShare.values[40]">
            <td>{{insights.modelShare.values[40]?.n_nameplate || 'Model Share'}}</td>
            <td *ngFor="
        let data of insights.modelShare.values | slice: 40:50;
        let i = index
      " [ngClass]="{'negative': data.yearOverYearValue < 0}">{{
              data.yearOverYearValue?.toLocaleString(undefined,
              {maximumFractionDigits:
              1})
              }}ppt</td>
          </tr>
        </ng-container>



      </ng-container>
      <ng-container *ngIf="insights.sales.values.length">

        <tr class='category-header'>
          <th>Sales</th>
        </tr>

        <tr>
          <td>{{(insights.share.nameplate?.toUpperCase()) || 'Nameplate'}}</td>
          <td *ngFor="
      let data of insights.sales.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.currentMonthValue < 0}">
            {{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 0})}}

          </td>
          <td>
            {{this.jobLogs['sales kpi Data Factory Pipeline']}}
          </td>
        </tr>

        <tr *ngIf="mom == 'MoM'">
          <td>{{insights.share.segmentName || 'Month Over Month'}}</td>
          <td *ngFor="
      let data of insights.sales.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.monthOverMonthValue < 0}">
            {{data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%

          </td>
        </tr>
        <tr *ngIf="mom == 'YoY'">
          <td>{{insights.share.segmentName || 'Year Over Year'}}</td>
          <td *ngFor="
      let data of insights.sales.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.yearOverYearValue < 0}">
            {{data.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%

          </td>
        </tr>
        <tr *ngIf="salesTempo">
          <td>{{'Month Sales Projection'}}</td>
          <td *ngFor="	
        let data of insights.sales.values | slice: 0:10;	
        let i = index	
      ">
            {{(data.currentMonthValue / salesTempo)?.toLocaleString(undefined, {maximumFractionDigits: 0})}}

          </td>
        </tr>
      </ng-container>
      <ng-container
        *ngIf="toTitleCase(insights.share.nameplate) == 'Ram Ld' || toTitleCase(insights.share.nameplate) == 'Pacifica  Total'">
        <tr>
          <td>{{insights.share.nameplate == 'RAM LD' ? 'DT' : 'PACIFICA GAS'}}</td>
          <!-- -->
          <td *ngFor="
        let data of insights.customSplitSales1.values | slice: 0:10;
        let i = index
      ">
            {{((data.currentMonthValue))?.toLocaleString(undefined, {maximumFractionDigits: 1})}}
          </td> <!-- -->

        </tr>
        <tr *ngIf="mom == 'MoM'">
          <td>{{insights.share.monthOverMonth || 'Month Over Month'}}</td>
          <td *ngFor=" let data of insights.customSplitSales1.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
          data.currentMonthValue / (data.lastMonthValue) < 1}">{{(((data.currentMonthValue)
            / (data.lastMonthValue)) * 100 - 100)?.toLocaleString(undefined, {maximumFractionDigits: 1})
            }}%

          </td>
        </tr>

        <tr *ngIf="mom == 'YoY'">
          <td>{{insights.share.monthOverMonth || 'Year Over Year'}}</td>
          <td *ngFor=" let data of insights.customSplitSales1.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
          (data.currentMonthValue /(data.lastMonthValue ) * 100 - 100) < 0}">{{((data.currentMonthValue
            /(data.lastMonthValue)) * 100 - 100)?.toLocaleString(undefined, {maximumFractionDigits: 1})
            }}%

          </td>
        </tr>
        <tr>
          <td>{{insights.share.nameplate == 'RAM LD' ? 'DS' : 'PACIFICA HYBRID'}}</td>
          <!-- -->
          <td *ngFor="
      let data of insights.customSplitSales2.values | slice: 0:10;
      let i = index
    ">
            {{(data.currentMonthValue)?.toLocaleString(undefined, {maximumFractionDigits: 1})}}
          </td> <!-- -->
          <!--<ng-container *ngFor="
        let data of insights.share.values | slice: 0:10;
        let i = index">
          <td insight
            [data]="((data.currentMonthValue / insights.segmentVolume.values[i].currentMonthValue) * 100)?.toLocaleString(undefined, {maximumFractionDigits: 1}) + '%'"
            [bc]="i" [nameplate]="insights.share.nameplate" [popupType]="'Competitive'">
          </td>
        </ng-container> -->

        </tr>
        <tr *ngIf="mom == 'MoM'">
          <td>{{insights.share.monthOverMonth || 'Month Over Month'}}</td>
          <td *ngFor=" let data of insights.customSplitSales2.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
        data.currentMonthValue-data.lastMonthValue < 0}">{{(((data.currentMonthValue)
            /(data.lastMonthValue)) * 100 - 100)?.toLocaleString(undefined, {maximumFractionDigits: 1})
            }}%

          </td>
        </tr>

        <tr *ngIf="mom == 'YoY'">
          <td>{{insights.share.yearOverYear || 'Year Over Year'}}</td>
          <td *ngFor=" let data of insights.customSplitSales2.values | slice: 0:10; let i=index " [ngClass]=" {'negative':
        (((data.currentMonthValue) - (data.lastMonthValue))) < 0}">{{(data.currentMonthValue
            / (data.lastMonthValue) * 100 - 100)?.toLocaleString(undefined, {maximumFractionDigits: 1})
            }}%

          </td>
        </tr>

      </ng-container>

      <tr class='category-header'>
        <th>{{mom}} Demand</th>
      </tr>
      <tr *ngIf="mom == 'MoM'">
        <td>{{insights.share.monthOverMonth || 'Visits'}}</td>
        <td *ngFor="
      let data of insights.visits.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.monthOverMonthValue < 0}" title='{{data.monthOverMonthDiff}}'>
          {{data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%</td>
        <td>
          {{this.jobLogs['visits Data Factory Pipeline']}}
        </td>
      </tr>
      <tr *ngIf="mom == 'YoY'">
        <td>{{insights.share.monthOverMonth || 'Visits'}}</td>
        <td *ngFor="
      let data of insights.visits.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.yearOverYearValue < 0}" title='{{data.yearOverYearDiff}}'>
          {{data.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%</td>
        <td>
          {{this.jobLogs['visits Data Factory Pipeline']}}
        </td>
      </tr>



      <tr *ngIf="mom == 'MoM'">
        <td>{{insights.share.monthOverMonth || 'LFAs'}}</td>
        <td *ngFor="
      let data of insights.lfas.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.monthOverMonthValue < 0}" title='{{data.monthOVerMonthDiff}}'>
          {{data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%</td>
        <td>
          {{this.jobLogs['lfa Data Factory Pipeline']}}
        </td>
      </tr>
      <tr *ngIf="mom == 'YoY'">
        <td>{{insights.share.monthOverMonth || 'LFAs'}}</td>
        <td *ngFor="
      let data of insights.lfas.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': data.yearOverYearValue < 0}" title='{{data.yearOverYearDiff}}'>
          {{data.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%</td>
        <td>
          {{this.jobLogs['lfa Data Factory Pipeline']}}
        </td>
      </tr>

      <tr *ngIf=' mom=="MoM"'>
        <td>{{insights.share.monthOverMonth || 'Incentive Page Visits'}}</td>
        <td *ngFor=" let data of insights.incentives.values | slice: 0:10; let
        i=index " title=' {{data.monthOverMonthDiff}}' [ngClass]=" {'negative': data.monthOverMonthValue < 0}">
          {{data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%</td>
        <td>
          {{this.jobLogs['incentives Data Factory Pipeline']}}
        </td>
      </tr>

      <tr *ngIf="mom== 'YoY'">
        <td>{{insights.share.monthOverMonth || 'Incentive Page Visits'}}</td>
        <td *ngFor="
      let data of insights.incentives.values | slice: 0:10;
      let i = index
    " title='{{data.yearOverYearDiff}}' [ngClass]="{'negative': data.yearOverYearValue < 0}">
          {{data.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%</td>
        <td>
          {{this.jobLogs['incentives Data Factory Pipeline']}}
        </td>
      </tr>

      <ng-container *ngIf="insights.leads.values.length">
        <tr *ngIf="mom =='MoM'">
          <td>{{insights.share.segmentName || 'Leads'}}</td>
          <td *ngFor="
    let data of insights.leads.values | slice: 0:10;
    let i = index
  " [ngClass]="{'negative': data.monthOverMonthValue < 0} " class='right' title='{{data.monthOverMonthDiff}}'>
            {{data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%
            <div class="dropdown-content">
              <app-insight-leads-breakdown-popup [bc]=i [nameplate]=insights.share.nameplate [mom]=mom>
              </app-insight-leads-breakdown-popup>
            </div>
          </td>
          <td>
            {{this.jobLogs['leads kpi Data Factory Pipeline']}}
          </td>
        </tr>
        <tr *ngIf="mom =='YoY'">
          <td>{{insights.share.segmentName || 'Leads'}}</td>
          <td *ngFor="
    let data of insights.leads.values | slice: 0:10;
    let i = index
  " [ngClass]="{'negative': data.yearOverYearValue < 0}" title='{{data.yearOverYearDiff}}'>
            {{data.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%
            <div class="dropdown-content">
              <app-insight-competitive-popup [bc]=i [nameplate]=insights.share.nameplate [mom]=mom>
              </app-insight-competitive-popup>
            </div>
          </td>
          <td>
            {{this.jobLogs['leads kpi Data Factory Pipeline']}}
          </td>

        </tr>

      </ng-container>
      <ng-container *ngIf="insights.showroomTraffic.values.length">

        <tr *ngIf="mom == 'MoM'">
          <td>{{insights.share.monthOverMonth || 'Showroom Traffic'}}</td>
          <td *ngFor="
        let data of insights.showroomTraffic.values | slice: 0:10;
        let i = index
      " title='{{data.monthOverMonthDiff}}' [ngClass]="{'negative': data.monthOverMonthValue < 0}">
            {{data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%</td>
          <td>
            {{this.jobLogs['traffic visits Data Factory Pipeline']}}
          </td>
        </tr>

        <tr *ngIf="mom== 'YoY'">
          <td>{{insights.share.monthOverMonth || 'Showroom Traffic'}}</td>
          <td *ngFor="
        let data of insights.showroomTraffic.values | slice: 0:10;
        let i = index
      " title='{{data.yearOverYearDiff}}' [ngClass]="{'negative': data.yearOverYearValue < 0}">
            {{data.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%</td>
          <td>
            {{this.jobLogs['traffic visits Data Factory Pipeline']}}
          </td>
        </tr>
      </ng-container>

      <ng-container *ngIf="insights.apps && insights.apps.values.length">

        <tr *ngIf="mom == 'MoM'">
          <td>{{insights.share.monthOverMonth || 'Applications'}}</td>
          <td *ngFor="
        let data of insights.apps.values | slice: 0:10;
        let i = index
      " title='{{data.monthOverMonthDiff}}' [ngClass]="{'negative': data.monthOverMonthValue < 0}">
            {{data.monthOverMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%</td>
          <td>
            {{this.jobLogs['applications Data Factory Pipeline']}}
          </td>
        </tr>

        <tr *ngIf="mom== 'YoY'">
          <td>{{insights.share.monthOverMonth || 'Applications'}}</td>
          <td *ngFor="
        let data of insights.apps.values | slice: 0:10;
        let i = index
      " title='{{data.yearOverYearDiff}}' [ngClass]="{'negative': data.yearOverYearValue < 0}">
            {{data.yearOverYearValue?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%</td>
          <td>
            {{this.jobLogs['applications Data Factory Pipeline']}}
          </td>
        </tr>
      </ng-container>

      <tr *ngIf="mom =='MoM'">
        <td title='LFAs / Visits'>{{insights.share.monthOverMonth || 'Visit to LFA Ratio'}}</td>
        <td *ngFor="
      let data of insights.visits.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': ((insights.lfas.values[i].currentMonthValue / data.currentMonthValue) - (insights.lfas.values[i].lastMonthValue / data.lastMonthValue ))< 0}"
          title='{{((( insights.lfas.values[i].currentMonthValue / data.currentMonthValue ) / ( insights.lfas.values[i].lastMonthValue / data.lastMonthValue) * 100) - 100)?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%'>
          {{(( insights.lfas.values[i].currentMonthValue * 100.0 /data.currentMonthValue) -
          (insights.lfas.values[i].lastMonthValue * 100.0 / data.lastMonthValue))?.toLocaleString(undefined,
          {maximumFractionDigits: 3})}}
        </td>
      </tr>
      <tr *ngIf="mom == 'YoY'">
        <td>{{insights.share.monthOverMonth || 'Visit to LFA Ratio (ppt)'}}</td>
        <td *ngFor="
      let data of insights.visits.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': (( insights.lfas.values[i].currentMonthValue * 100.0 / data.currentMonthValue) - ( insights.lfas.values[i].lastYearValue / data.lastYearValue ))< 0}"
          title='{{((( insights.lfas.values[i].currentMonthValue * 100.0 / data.currentMonthValue) / ( insights.lfas.values[i].lastYearValue / data.lastYearValue)) * 100 - 100)?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%'>
          {{(( insights.lfas.values[i].currentMonthValue * 100.0 / data.currentMonthValue) - (
          insights.lfas.values[i].lastYearValue * 100.0 / data.lastYearValue))?.toLocaleString(undefined,
          {maximumFractionDigits:
          3})}}
        </td>
      </tr>



      <ng-container *ngIf="insights.comboConversion.values.length">
        <tr *ngIf="mom == 'MoM'">
          <td>{{insights.share.monthOverMonth || 'Combo Conversion (ppt)'}}</td>
          <td *ngFor="
      let data of insights.comboConversion.values | slice: 0:10;
      let i = index
    " title='{{data.monthOverMonthValue}}%' [ngClass]="{'negative': data.monthOverMonthDiff < 0}">
            {{(data.monthOverMonthDiff * 100.0)?.toLocaleString(undefined, {maximumFractionDigits: 3})}}</td>
        </tr>
        <tr *ngIf="mom == 'YoY'">
          <td>{{insights.share.monthOverMonth || 'Combo Conversion (ppt)'}}</td>
          <td *ngFor="
      let data of insights.comboConversion.values | slice: 0:10;
      let i = index
    " title='{{data.yearOverYearValue}}%' [ngClass]="{'negative': data.yearOverYearDiff < 0}">
            {{(data.yearOverYearDiff * 100.0)?.toLocaleString(undefined, {maximumFractionDigits: 3})}}</td>
        </tr>
      </ng-container>



      <ng-container *ngIf="insights.sales.values.length > 2">
        <tr *ngIf="mom =='MoM'">
          <td title='Sales / LFAs'>{{insights.sales.monthOverMonth || 'Lower Funnel Conversion (ppt)'}}</td>
          <td *ngFor="
      let data of insights.lfas.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': (( insights.sales.values[i].currentMonthValue / data.currentMonthValue) - ( insights.sales.values[i].lastMonthValue / data.lastMonthValue))?.toLocaleString(undefined, {maximumFractionDigits: 1}) < 0}"
            title='{{(( insights.sales.values[i].currentMonthValue / data.currentMonthValue) / ( insights.sales.values[i].lastMonthValue / data.lastMonthValue) * 100 - 100)?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%'>
            {{(( insights.sales.values[i].currentMonthValue * 100.0 / data.currentMonthValue) - (
            insights.sales.values[i].lastMonthValue * 100.0 / data.lastMonthValue))?.toLocaleString(undefined,
            {maximumFractionDigits: 1})}}
          </td>
        </tr>
        <tr *ngIf="mom == 'YoY'">
          <td>{{insights.sales.yearOverYear || 'Lower Funnel Conversion'}}</td>
          <td *ngFor="
      let data of insights.lfas.values | slice: 0:10;
      let i = index
    " [ngClass]="{'negative': (( insights.sales.values[i].currentMonthValue / data.currentMonthValue) - ( insights.sales.values[i].lastYearValue / data.lastYearValue))?.toLocaleString(undefined, {maximumFractionDigits: 1}) < 0}"
            title='{{(( insights.sales.values[i].currentMonthValue / data.currentMonthValue) / ( insights.sales.values[i].lastYearValue / data.lastYearValue) * 100 - 100)?.toLocaleString(undefined, {maximumFractionDigits: 1})}}%'>
            {{(( insights.sales.values[i].currentMonthValue / data.currentMonthValue) - (
            insights.sales.values[i].lastYearValue / data.lastYearValue))?.toLocaleString(undefined,
            {maximumFractionDigits:
            3})}}
          </td>
        </tr>
      </ng-container>
      <tr class='category-header'>
        <th>Inventory</th>
      </tr>
      <ng-container
        *ngIf="toTitleCase(insights.share.nameplate) !== 'Ram Ld' && toTitleCase(insights.share.nameplate) !== 'Pacifica  Total' && toTitleCase(insights.share.nameplate) !== 'Grand Cherokee Total'">

        <tr>
          <td>{{'Current K Stock'}}</td>
          <td *ngFor="
      let data of insights.kStock.values | slice: 0:10;
      let i = index
    " title='{{data.monthOverMonthValue}}' [ngClass]="{'negative': data.currentMonthValue < 0}">
            {{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 0})}}
          </td>
          <td rowspan='4'>
            {{this.jobLogs['inventory history kpi Data Factory Pipeline']}}
          </td>
        </tr>

        <tr>
          <td>{{'Start of Month K Stock'}}</td>
          <td *ngFor="
      let data of insights.kStock.values | slice: 0:10;
      let i = index
    " title='{{data.q_monthly_stock_0_mo - data.q_monthly_stock_1_mo}}'
            [ngClass]="{'negative': data.q_monthly_stock_1_mo < 0}">
            {{data.q_monthly_stock_0_mo?.toLocaleString(undefined, {maximumFractionDigits: 0})}}
          </td>
        </tr>


        <tr>
          <td>{{'Current X Stock'}}</td>
          <td *ngFor="
      let data of insights.kStock.values | slice: 0:10;
      let i = index
    " title='{{data.monthOverMonthValue}}' [ngClass]="{'negative': data.currentMonthValue < 0}">
            {{data.q_x_stock?.toLocaleString(undefined, {maximumFractionDigits: 0})}}
          </td>
        </tr>

        <tr>
          <td>{{'Start of Month X Stock'}}</td>
          <td *ngFor="
      let data of insights.kStock.values | slice: 0:10;
      let i = index
    " title='{{data.q_monthly_x_stock_0_mo - data.q_monthly_x_stock_1_mo}}'
            [ngClass]="{'negative': data.q_monthly_stock_1_mo < 0}">
            {{data.q_monthly_x_stock_0_mo?.toLocaleString(undefined, {maximumFractionDigits: 0})}}
          </td>
        </tr>
        <ng-container *ngIf="true">

          <tr>
            <td>{{'Turn Rate'}}</td>
            <td *ngFor=" let data of insights.kStock.values | slice: 0:10; let i = index ">

              {{(100 * (insights.sales.values.length > 2 && insights.sales.values[i].currentMonthValue /
              data.q_monthly_stock_0_mo))?.toLocaleString(undefined,
              {maximumFractionDigits: 1}) + '%'}}
            </td>
          </tr>

          <tr *ngIf="salesTempo">
            <td>{{'Proj Turn Rate'}}</td>
            <td *ngFor="
        let data of insights.kStock.values | slice: 0:10;
        let i = index
      ">
              {{((100 * (insights.sales.values[i].currentMonthValue / data.q_monthly_stock_0_mo)) /
              salesTempo)?.toLocaleString(undefined, {maximumFractionDigits: 1}) + '%'}}
            </td>
          </tr>

          <tr *ngIf="salesTempo">
            <td>{{'Proj Turn Rate MoM'}}</td>
            <td *ngFor="
        let data of insights.kStock.values | slice: 0:10;
        let i = index
      ">
              {{(((100 * (insights.sales.values[i].currentMonthValue / data.q_monthly_stock_0_mo)) /
              salesTempo) - ((100 * (insights.sales.values[i].lastMonthValue / data.q_monthly_stock_1_mo)) /
              salesTempo))?.toLocaleString(undefined, {maximumFractionDigits: 1})}}
            </td>
          </tr>
        </ng-container>
      </ng-container>

      <ng-container
        *ngIf="toTitleCase(insights.share.nameplate) == 'Ram Ld' || toTitleCase(insights.share.nameplate) == 'Pacifica Total'">
        <tr>
          <td>{{insights.share.nameplate == 'RAM LD' ? 'DT K Stock' : 'PACIFICA GAS K Stock'}}</td>
          <td *ngFor="
        let data of insights.customSplitStock1.values | slice: 0:10;
        let i = index
      " [ngClass]="{'negative': data.currentMonthValue < 0}">
            {{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 0})}}
          </td>
          <td rowspan='8'>
            {{this.jobLogs['inventory history kpi Data Factory Pipeline']}}
          </td>
        </tr>

        <tr>
          <td>{{insights.share.nameplate == 'RAM LD' ? 'DT Start of Month K Stock' : 'PACIFICA GAS Start of Month K
            Stock'}}</td>
          <td *ngFor="
        let data of insights.customSplitStock1.values | slice: 0:10;
        let i = index
      " [ngClass]="{'negative': data.q_monthly_stock_1_mo < 0}"
            title='{{data.q_monthly_stock_0_mo - data.q_monthly_stock_1_mo}}'>
            {{data.q_monthly_stock_0_mo?.toLocaleString(undefined, {maximumFractionDigits: 0})}}
          </td>
        </tr>


        <tr>
          <td>{{insights.share.nameplate == 'RAM LD' ? 'DT X Stock' : 'PACIFICA GAS X Stock'}}</td>
          <td *ngFor="
        let data of insights.customSplitStock1.values | slice: 0:10;
        let i = index
      " [ngClass]="{'negative': data.currentMonthValue < 0}">
            {{data.q_x_stock?.toLocaleString(undefined, {maximumFractionDigits: 0})}}
          </td>
        </tr>

        <tr>
          <td>{{insights.share.nameplate == 'RAM LD' ? 'DT Start Of Month X Stock' : 'PACIFICA GAS Start of Month X
            Stock'}}</td>
          <td *ngFor="
        let data of insights.customSplitStock1.values | slice: 0:10;
        let i = index
      " title='{{data.q_monthly_stock_0_mo - data.q_monthly_stock_1_mo}}'
            [ngClass]="{'negative': data.q_monthly_stock_0_mo < 0}">
            {{data.q_monthly_x_stock_0_mo?.toLocaleString(undefined, {maximumFractionDigits: 0})}}
          </td>
        </tr>
        <ng-container *ngIf="true">

          <tr>
            <td>{{'Turn Rate'}}</td>
            <td *ngFor="
        let data of insights.customSplitStock1.values | slice: 0:10;
        let i = index
      ">
              {{(100 * (insights.customSplitSales1.values[i].currentMonthValue /
              data.q_monthly_stock_0_mo))?.toLocaleString(undefined,
              {maximumFractionDigits: 1}) + '%'}}
            </td>
          </tr>

          <tr *ngIf="salesTempo">
            <td>{{'Proj Turn Rate'}}</td>
            <td *ngFor="
        let data of insights.customSplitStock1.values | slice: 0:10;
        let i = index
      ">
              {{((100 * (insights.customSplitSales1.values[i].currentMonthValue / data.q_monthly_stock_0_mo)) /
              salesTempo)?.toLocaleString(undefined, {maximumFractionDigits: 1}) + '%'}}
            </td>
          </tr>

          <tr *ngIf="salesTempo">
            <td>{{'Proj Turn Rate MoM'}}</td>
            <td *ngFor="
        let data of insights.customSplitStock1.values | slice: 0:10;
        let i = index
      ">
              {{(((100 * (insights.customSplitSales1.values[i].currentMonthValue / data.q_monthly_stock_0_mo)) /
              salesTempo) - ((100 * (insights.customSplitSales1.values[i].lastMonthValue / data.q_monthly_stock_1_mo)) /
              salesTempo))?.toLocaleString(undefined, {maximumFractionDigits: 1})}}
            </td>
          </tr>
        </ng-container>
        <tr>
          <td>{{insights.share.nameplate == 'RAM LD' ? 'DS K Stock' : 'PACIFICA HYBRID K Stock'}}</td>
          <td *ngFor="
        let data of insights.customSplitStock2.values | slice: 0:10;
        let i = index
      " [ngClass]="{'negative': data.currentMonthValue < 0}">
            {{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 0})}}
          </td>
        </tr>

        <tr>
          <td>{{insights.share.nameplate == 'RAM LD' ? 'DS Start of Month K Stock' : 'PACIFICA HYBRID Start Of Month K
            Stock'}}</td>
          <td *ngFor="
        let data of insights.customSplitStock2.values | slice: 0:10;
        let i = index
      " [ngClass]="{'negative': data.q_monthly_stock_1_mo < 0}"
            title='{{data.q_monthly_stock_0_mo - data.q_monthly_stock_1_mo}}'>
            {{data.q_monthly_stock_0_mo?.toLocaleString(undefined, {maximumFractionDigits: 0})}}
          </td>
        </tr>


        <tr>
          <td>{{insights.share.nameplate == 'RAM LD' ? 'DS X Stock' : 'PACIFICA HYBRID X Stock'}}</td>
          <td *ngFor="
        let data of insights.customSplitStock2.values | slice: 0:10;
        let i = index
      " title='{{data.monthOverMonthValue}}' [ngClass]="{'negative': data.currentMonthValue < 0}">
            {{data.q_x_stock?.toLocaleString(undefined, {maximumFractionDigits: 0})}}
          </td>
        </tr>

        <tr>
          <td>{{insights.share.nameplate == 'RAM LD' ? 'DS Start of Month X Stock' : 'PACIFICA HYBRID Start of Month X
            Stock'}}</td>
          <td *ngFor="
        let data of insights.customSplitStock2.values | slice: 0:10;
        let i = index
      " title='{{data.q_monthly_stock_0_mo - data.q_monthly_stock_1_mo}}'
            [ngClass]="{'negative': data.q_monthly_stock_1_mo < 0}">
            {{data.q_monthly_x_stock_0_mo?.toLocaleString(undefined, {maximumFractionDigits: 0})}}
          </td>
        </tr>

        <ng-container *ngIf="true">

          <tr>
            <td>{{'Turn Rate'}}</td>
            <td *ngFor="
        let data of insights.customSplitStock2.values | slice: 0:10;
        let i = index
      ">
              {{(100 * (insights.customSplitSales2.values[i].currentMonthValue /
              data.q_monthly_stock_0_mo))?.toLocaleString(undefined,
              {maximumFractionDigits: 1}) + '%'}}
            </td>
          </tr>

          <tr *ngIf="salesTempo">
            <td>{{'Proj Turn Rate'}}</td>
            <td *ngFor="
        let data of insights.customSplitStock2.values | slice: 0:10;
        let i = index
      ">
              {{((100 * (insights.customSplitSales2.values[i].currentMonthValue / data.q_monthly_stock_0_mo)) /
              salesTempo)?.toLocaleString(undefined, {maximumFractionDigits: 1}) + '%'}}
            </td>
          </tr>

          <tr *ngIf="salesTempo">
            <td>{{'Proj Turn Rate MoM'}}</td>
            <td *ngFor="
        let data of insights.customSplitStock2.values | slice: 0:10;
        let i = index
      ">
              {{(((100 * (insights.customSplitSales2.values[i].currentMonthValue / data.q_monthly_stock_0_mo)) /
              salesTempo) - ((100 * (insights.customSplitSales2.values[i].lastMonthValue / data.q_monthly_stock_1_mo)) /
              salesTempo))?.toLocaleString(undefined, {maximumFractionDigits: 1})}}
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="toTitleCase(insights.share.nameplate) == 'Grand Cherokee Total'">
        <tr>
          <td>{{'GRAND CHEROKEE TOTAL Stock'}}</td>
          <td *ngFor="
        let data of insights.customSplitStock1.values | slice: 0:10;
        let i = index
      " [ngClass]="{'negative': data.currentMonthValue < 0}">
            {{data.currentMonthValue?.toLocaleString(undefined, {maximumFractionDigits: 0})}}
          </td>
          <td rowspan='8'>
            {{this.jobLogs['inventory history kpi Data Factory Pipeline']}}
          </td>
        </tr>

        <tr>
          <td>{{'GRAND CHEROKEE TOTAL Start of Month K
            Stock'}}</td>
          <td *ngFor="
        let data of insights.customSplitStock1.values | slice: 0:10;
        let i = index
      " [ngClass]="{'negative': data.q_monthly_stock_1_mo < 0}"
            title='{{data.q_monthly_stock_0_mo - data.q_monthly_stock_1_mo}}'>
            {{data.q_monthly_stock_0_mo?.toLocaleString(undefined, {maximumFractionDigits: 0})}}
          </td>
        </tr>


        <tr>
          <td>{{'GRAND C TOTAL X Stock'}}</td>
          <td *ngFor="
        let data of insights.customSplitStock1.values | slice: 0:10;
        let i = index
      " [ngClass]="{'negative': data.currentMonthValue < 0}">
            {{data.q_x_stock?.toLocaleString(undefined, {maximumFractionDigits: 0})}}
          </td>
        </tr>

        <tr>
          <td>{{'GRAND CHEROKEE TOTAL Start of Month X
            Stock'}}</td>
          <td *ngFor="
        let data of insights.customSplitStock1.values | slice: 0:10;
        let i = index
      " title='{{data.q_monthly_stock_0_mo - data.q_monthly_stock_1_mo}}'
            [ngClass]="{'negative': data.q_monthly_stock_0_mo < 0}">
            {{data.q_monthly_x_stock_0_mo?.toLocaleString(undefined, {maximumFractionDigits: 0})}}
          </td>
        </tr>
        <ng-container *ngIf="true">

          <tr>
            <td>{{'Turn Rate'}}</td>
            <td *ngFor="
        let data of insights.customSplitStock1.values | slice: 0:10;
        let i = index
      ">
              {{(100 * (insights.customSplitSales1.values[i].currentMonthValue /
              data.q_monthly_stock_0_mo))?.toLocaleString(undefined,
              {maximumFractionDigits: 1}) + '%'}}
            </td>
          </tr>

          <tr *ngIf="salesTempo">
            <td>{{'Proj Turn Rate'}}</td>
            <td *ngFor="
        let data of insights.customSplitStock1.values | slice: 0:10;
        let i = index
      ">
              {{((100 * (insights.customSplitSales1.values[i].currentMonthValue / data.q_monthly_stock_0_mo)) /
              salesTempo)?.toLocaleString(undefined, {maximumFractionDigits: 1}) + '%'}}
            </td>
          </tr>

          <tr *ngIf="salesTempo">
            <td>{{'Proj Turn Rate MoM'}}</td>
            <td *ngFor="
        let data of insights.customSplitStock1.values | slice: 0:10;
        let i = index
      ">
              {{(((100 * (insights.customSplitSales1.values[i].currentMonthValue / data.q_monthly_stock_0_mo)) /
              salesTempo) - ((100 * (insights.customSplitSales1.values[i].lastMonthValue / data.q_monthly_stock_1_mo)) /
              salesTempo))?.toLocaleString(undefined, {maximumFractionDigits: 1})}}
            </td>
          </tr>
        </ng-container>
      </ng-container>

    </tbody>

  </table>
</div>
<app-pin-mkt-view *ngIf="insights && insights.share.bc != 10" [insights]="insights" [mom]='mom'></app-pin-mkt-view>