import { Component, OnChanges, OnInit } from '@angular/core';
import { DataService } from "src/app/services/data.service";
import * as loadash from 'lodash';
import { LoaderService } from 'src/app/services/loader.service';
import { Subject, Subscription } from 'rxjs';
// import * as XLSX from 'ts-xlsx';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-shipment-over-view',
  templateUrl: './shipment-over-view.component.html',
  styleUrls: ['./shipment-over-view.component.scss']
})
export class ShipmentOverViewComponent implements OnInit {

  jeepMainData: any[];

  loader = true;
  jeepGlobalBoolean = true;
  public isLoading: Subject<boolean> = this.loaderService.isLoading;
  months = [];
  month;
  currentRegion = 'global'
  state: {};
  constructor(private dataService: DataService, private loaderService: LoaderService) {
    let yearmonth = 202101
    let currentDate = new Date();
    let currentMonth = currentDate.getUTCMonth().toString().padStart(2, '0')
    let currentYearMonth = parseInt(currentDate.getUTCFullYear().toString() + currentMonth)
    this.month = currentYearMonth;
    while (yearmonth <= currentYearMonth) {
      this.months.unshift(yearmonth)
      if (yearmonth.toString().endsWith('12')) {
        yearmonth = yearmonth + 89
      } else {
        yearmonth = yearmonth + 1;
      }
    }
    console.log(this.months)
  }
  public jeepGrid;
  public getRegionsData = [{ label: 'Global', endpoint: 'global' }
    , { label: 'North America', endpoint: 'nafta' }
    , { label: 'Enlarged Europe', endpoint: 'europe' }
    , { label: 'India & Asia Pacific', endpoint: 'iap' }
    , { label: 'Middle East & Africa', endpoint: 'mea' }
    , { label: 'China', endpoint: 'china' }
    , { label: 'LATAM', endpoint: 'latam' }
  ];

  ngOnInit(): void {
    // this.jeepGrid = this.jeepData;
    // console.log(this.jeepGrid.regions)
    this.jeepGrid = [];

    this.getRegion({})

  }
  //jeepGlobalBoolean = true;
  //public isLoading: Subject<boolean> = this.loaderService.isLoading;

  ngOnChanges() {
    this.getRegion({})
  }

  getRegion(tabClickEvent) {
    if (!tabClickEvent.tab) {
      tabClickEvent.tab = { textLabel: this.currentRegion }
    } else {
      this.currentRegion = tabClickEvent.tab.textLabel
    }
    this.loader = true;
    this.jeepGrid = [];
    this.state = {};
    this.loader = true;
    this.loaderService.show()
    // this.dataService.getJeepGlobal(tabClickEvent.tab.textLabel, this.month).subscribe((rows) => {
    //   this.jeepGrid = this.sortJeepGrid(rows.body)
    //   this.loaderService.hide()
    //   this.loader = false;
    // })
  }
  sortJeepGrid(jeepGrid) {
    let nameplateOrder = ['TOTAL', 'AVENGER', 'AVENGER BEV', 'RENEGADE', 'RENEGADE 4XE', 'COMPASS', 'COMPASS 4XE', 'D-SUV (IAP)', 'GLADIATOR', 'WRANGLER', 'WRANGLER 4XE',
      'CHEROKEE', 'GRAND COMMANDER', 'GRAND CHEROKEE (WK)', 'GRAND CHEROKEE (WL)', 'GRAND CHEROKEE 4XE', 'WAGONEER', 'GRAND WAGONEER', 'COMMANDER', 'MERIDIAN']

    let regionOrder = ['TOTAL', 'NORTH AMERICA', 'INDIA & ASIA PACIFIC', 'CHINA', 'LATAM', 'ENLARGE EUROPE', 'MIDDLE EAST & AFRICA']
    let countryOrder = ['TOTAL', 'U.S. TOTAL', 'U.S. RETAIL', 'U.S. FLEET', 'CANADA', 'MEXICO', 'FRANCE', 'GERMANY', 'ITALY', 'SPAIN', 'UNITED KINGDOM',
      'MIDDLE EAST', 'AUSTRALIA', 'INDIA', 'JAPAN', 'SOUTH KOREA', 'CHINA', 'ARGENTINA', 'BRAZIL', 'LATAM (OTHER)', 'SAUDI ARABIA', 'UNITED ARAB EMIRATES', 'TURKEY', 'ISRAEL', 'IRAQ']
    jeepGrid.sort((a, b) => {
      if (a.REGION != b.REGION) {
        return (regionOrder.indexOf(a.REGION.toUpperCase()) < regionOrder.indexOf(b.REGION.toUpperCase())) ? -1 : 1;
      }
      if (a.COUNTRY != b.COUNTRY) {
        return (countryOrder.indexOf(a.COUNTRY.toUpperCase()) < countryOrder.indexOf(b.COUNTRY.toUpperCase())) ? -1 : 1;
      }
      return (nameplateOrder.indexOf(a.NAMEPLATE.toUpperCase()) < nameplateOrder.indexOf(b.NAMEPLATE.toUpperCase())) ? -1 : 1;
    })
    return jeepGrid;
  }

}
