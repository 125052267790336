<table>
  <thead>
    <tr>
      <th colspan="1" style="border: none;"></th>
      <th colspan="7">January 2022</th>
      <th colspan="5">CYTD</th>
      <th colspan="3">CY</th>
    </tr>
    <tr>
      <th style="border: none;"></th>
      <th>Budget</th>
      <th>Last Year Sales</th>
      <th>Plan</th>
      <th>Actual Sales</th>
      <th>Sale % of Budget</th>
      <th>Sales % of Plan</th>
      <th>Sales % vs LY</th>

      <th>Budget</th>
      <th>Last Year Sales</th>
      <th>Actual Sales</th>
      <th>Sale % of Budget</th>
      <th>Sales % vs LY</th>

      <th>Budget</th>
      <th>CY Sales</th>
      <th>Plan</th>
    </tr>
  </thead>
  <tbody>



    <tr *ngFor="let obj of jeepGrid" class="no-bottom-border">
      <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}" class="tableRow">{{
        obj.NAMEPLATE == 'TOTAL' ? (obj.COUNTRY == 'TOTAL' ? obj.REGION : obj.COUNTRY) :
        obj.NAMEPLATE
        }} </td>

      <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}">{{obj.NAMEPLATE}} </td>
      <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}">{{obj.LASTYEARSSALES}} </td>
      <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}">{{obj.PLAN}} </td>
      <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}"> {{obj.SALES}}</td>
      <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}">
        {{((obj.SALESPERCENTOFBUDGET)*100).toFixed(0)}} %</td>
      <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}">{{
        ((obj.SALESPERCENTOFPLAN)*100).toFixed(0)}}% </td>
      <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}">
        {{((obj.SALESPERCENTOFPRIORYEAR)*100).toFixed(0)}}%
      </td>

      <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}"> {{obj.CYTDBUDGET}}</td>
      <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}"> {{obj.LYTDSALES}}</td>
      <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}">{{obj.CYTDSALES}} </td>
      <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}">
        {{((obj.CYTDSALESPERCENTOFBUDGET)*100).toFixed(0)}}% </td>
      <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'} ">
        {{((obj.CYTDSALESPERCENTOFLASTYEAR)*100).toFixed(0)}}%
      </td>

      <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}"> {{obj.CYBUDGET}} </td>
      <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}">{{obj.CYSALES}} </td>
      <td [ngClass]="{'green_Bg':obj.NAMEPLATE == 'TOTAL'}">{{obj.CYPLAN}} </td>
    </tr>
  </tbody>
</table>