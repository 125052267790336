import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-temporary-alert',
  templateUrl: './temporary-alert.component.html',
  styleUrls: ['./temporary-alert.component.scss']
})

export class TemporaryAlertComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TemporaryAlertComponent>, public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data, private sanitized: DomSanitizer) { }

  @Input('mat-dialog-close')
  popup_text;
  ngOnInit() {
    this.popup_text = this.sanitized.bypassSecurityTrustHtml(this.data.alert);
    if (!this.popup_text) {
      this.dialogRef.close()
    }
  }


  close() {
    this.dialogRef.close();
  }

}
