import { Injectable, Injector } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retryWithBackoff } from 'src/rxjs-helpers';
import { catchError } from 'rxjs/operators';
import { Alert } from '../models/alert';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class UserService {




  apiUrl: string;
  private http = this.injector.get(HttpClient);

  constructor(private injector: Injector) {
    this.apiUrl = environment.apiUrl;
  }

  getReportCategories(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `api/reportCategories`)
  }
  getReportPlatform(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `api/reportPlatform`)
  }

  saveUser(user: any) {
    return this.http.post(this.apiUrl + 'api/users', { user });
  }

  saveCmaAlertRelationships(user: any, receive, cmaAlertRelationships: any) {
    return this.http.post(this.apiUrl + 'api/users/cmaAlertRelationships', { user, receive, cmaAlertRelationships });
  }

  getUser(): any {
    return JSON.parse(localStorage.getItem('msalUser'));
  }

  getEmailPreference(): Observable<any> {
    return this.http.get(this.apiUrl + `api/users/emailPreference/${this.getUser().idTokenClaims.email}`);
  }

  logError(error = ''): Observable<any> {
    const err = { name: error };
    const errorLink = this.apiUrl + 'api/logs';
    return this.http.post(errorLink, err);

  }


  getNameplates(): any {
    return this.http.get(this.apiUrl + 'api/nameplates');
  }

  getUserPrefInitData(): any {
    return this.http.get(this.apiUrl + `api/users/getUserPrefInitData/${this.getUser().idTokenClaims.unique_name.slice(4)}`);
  }
  // const headers = new Headers({ 'Content-Type': 'application/json' });

  getUserAlerts(): Observable<Array<Alert>> {
    const email = this.getUser().idTokenClaims.email;
    return this.http.get(this.apiUrl + `api/users/alerts/${email}`).pipe(map((response) => { return response as Alert[] }));
  }

  getUserData(): Observable<any> {
    return this.http.get(this.apiUrl + `api/users`);
  }
  insertNewUser(): Observable<any> {
    const email = this.getUser().idTokenClaims.email;
    const tid = this.getUser().idTokenClaims.unique_name.slice(4);
    const givenName = this.getUser().idTokenClaims.given_name
    return this.http.get(this.apiUrl + `api/users/newUser/${email}/${tid}/${givenName}`);
  };

}
