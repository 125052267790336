import { Component, OnInit, Input } from "@angular/core";
import { capitalizeAcronyms, intelligentShortening, toTitleCase } from "src/string-helpers";
import { DrawerService } from "src/app/services/drawer.service";
import { DataService } from "src/app/services/data.service";
import { KPIService } from "src/app/services/kpi.service";

//import { bcMapping } from "src/bc-mapping";

@Component({
  selector: "insight, [insight]",
  templateUrl: "./insight.component.html",
  styleUrls: ["./insight.component.scss"],
})
export class InsightComponent implements OnInit {
  @Input() nameplate;
  @Input() bc;
  @Input() popupType = null;
  @Input() data;
  loading = false;
  capitalizeAcronyms = capitalizeAcronyms;
  intelligentShortening = intelligentShortening;

  toTitleCase = toTitleCase;
  constructor(
    public drawerService: DrawerService,
    public dataService: DataService,
    public kpiService: KPIService
  ) {
  }

  ngOnInit() {

  }


}
