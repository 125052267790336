<mat-drawer-container class="drawer-container">


  <mat-drawer #drawer mode="over" [(opened)]="opened" class="drawer">
    <app-kpi-wrapper *ngIf="!environment.mopar"></app-kpi-wrapper>
  </mat-drawer>
  <mat-drawer-content [ngClass]="{'drawer-content': true, 'drawer-opened': opened}">
    <app-toolbar></app-toolbar>
    <app-dashboard></app-dashboard>
    <app-footer></app-footer>
  </mat-drawer-content>
</mat-drawer-container>