<div fxLayout fxLayoutAlign="center center" class="filterClass" fxLayoutGap="20px">
  <mat-form-field class="showMonthClass">
    <mat-label>Select column</mat-label>
    <mat-select (selectionChange)="columnName($event)">
      <mat-option *ngFor="let obj of columnsArray" [value]="obj.key">
        {{obj.key}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <input matInput type="text" (keyup)="doFilter($event.target)" placeholder="Filter">
  </mat-form-field>
</div>
<article class="table-header">

  <button *ngIf="IsRemoveAllEnabled" class="button-remove-rows" mat-button (click)="removeSelectedRows()">
    Remove Rows
  </button>

  <button class="button-add-row" mat-button (click)="addRow('true')">Add Row</button>
</article>
<table mat-table [dataSource]="dataSource" matSort>
  <ng-container [matColumnDef]="col.key" *ngFor="let col of columnsSchema">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ col.label }}
    </th>
    <td mat-cell *matCellDef="let element" (keyup)="columKeyUp(element,col)">
      <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
        <ng-container *ngSwitchCase="'isSelected'">
          <mat-checkbox (change)="updateRemoveAllButton(element, $event)"
            (ngModelChange)="updateRemoveAllButton(element, $event)"></mat-checkbox>
        </ng-container>
        <div class="btn-edit" *ngSwitchCase="'isEdit'">
          <button mat-button
            (click)="element.isEdit = !element.isEdit && addRowEvent = true && insterRow = true && isDisabled = true">
            Edit
          </button>
          <button *ngIf="!IsRemoveAllEnabled" mat-button class="button-remove" (click)="removeRow(element.Id)">
            Delete
          </button>
        </div>
        <span *ngSwitchCase="'date'">
          {{ element[col.key] | date: 'mediumDate' }}
        </span>
        <span *ngSwitchDefault>
          {{ element[col.key] }}
        </span>
      </div>
      <div [ngSwitch]="col.type" *ngIf="element.isEdit">
        <div *ngSwitchCase="'isSelected'"></div>
        <div class="btn-edit" *ngSwitchCase="'isEdit'">
          <button mat-button [disabled]="isDisabled" (click)="editRow(element)">
            Done
          </button>
          <button mat-button (click)="cancelEdit(element)">
            Cancel
          </button>
        </div>
        <mat-form-field class="form-input" *ngSwitchCase="'date'" appearance="fill">
          <mat-label>Choose a date</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="element[col.key]" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="form-input" *ngSwitchDefault>
          <input [type]="col.type" matInput [(ngModel)]="element[col.key]" />
        </mat-form-field>
      </div>
      <ng-container *ngIf="addRowEvent && source && col.key === 'source_make'">
        <span *ngIf="element.source_make == ''" class="error-class">*Required</span>
        <span *ngIf="element.source_make.length > 128 " class="error-class">Maximum character is 128</span>
      </ng-container>
      <ng-container *ngIf="addRowEvent && sourceModel && col.key === 'source_model'">
        <span *ngIf="element.source_model == ''" class="error-class">*Required</span>
        <span *ngIf="element.source_model.length > 128 " class="error-class">Maximum character is 128</span>
      </ng-container>
      <ng-container *ngIf="addRowEvent && sourceMake && col.key === 'source_trim'">
        <!-- <span *ngIf="element.source_trim == ''" class="error-class">*Required</span> -->
        <span *ngIf="element.source_trim.length > 128 " class="error-class">Maximum character is 128</span>
      </ng-container>
      <ng-container *ngIf="addRowEvent && goldStdMake && col.key === 'gold_standard_make'">
        <span *ngIf="element.gold_standard_make == ''" class="error-class">*Required</span>
        <span *ngIf="element.gold_standard_make.length > 128 " class="error-class">Maximum character is 128</span>
      </ng-container>
      <ng-container *ngIf="addRowEvent && goldStdModel && col.key === 'gold_standard_model'">
        <span *ngIf="element.gold_standard_model == ''" class="error-class">*Required</span>
        <span *ngIf="element.gold_standard_model.length > 128 " class="error-class">Maximum character is 128</span>
      </ng-container>
      <ng-container *ngIf="addRowEvent && goldStdTrim && col.key === 'gold_standard_trim'">
        <!-- <span *ngIf="element.gold_standard_trim == ''" class="error-class">*Required</span> -->
        <span *ngIf="element.gold_standard_trim.length > 128 " class="error-class">Maximum character is 128</span>
      </ng-container>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator #scheduledOrdersPaginator showFirstLastButtons="firstLastButtons" [length]="20"
  [pageSizeOptions]="[20,30,50,100]"></mat-paginator>
<div class="overlay" *ngIf="IsWait">
  <div class="center">
    <mat-progress-spinner mode="indeterminate" diameter=100>
    </mat-progress-spinner>
  </div>
</div>