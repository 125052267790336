import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrawerService {

  opened = false;
  opened$ = new ReplaySubject<any>();
  constructor() {
  }

  toggleDrawer(contents) {
    this.opened = !this.opened;
    this.opened$.next({ opened: this.opened, contents })
  }
}
