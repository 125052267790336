import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpClientModule,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class IndustryStandardMapService {
  // private goldenUrl = 'http://localhost:1433/api/map';
  private goldenUrl: string;
  apiUrl: string;
  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
    this.goldenUrl = this.apiUrl + "api/map";
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
  };
  getStudentData(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
    }
    return this.http.get<any>(this.goldenUrl);
  }

  insertRecords(records: any): Observable<any> {
    return this.http.post<any>(this.goldenUrl, records);
  }

  deleteRecord(record: number[]): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: {
        id: record,
      },
    };
    return this.http.delete<any>(this.goldenUrl, options);
  }
}
