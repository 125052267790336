import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Pipe } from '@angular/core';
import { SafeResourceUrl, Title, DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/services/data.service';
import { Report } from './model/report';
import { ReportAdminComponent } from '../../layout/report-admin/report-admin.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DrawerService } from 'src/app/services/drawer.service';
import { Subject, Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'request-snackbar',
  template: '<span class=\"request-snackbar\">We\'ve requested access for you.</span>'
})
export class RequestSnackbarComponent {

}



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DataService, Pipe]
})
export class DashboardComponent implements OnInit, OnDestroy {
  public searchText = '';
  // public reports = Reports;
  public reports: any;
  public DemandLinks: any;
  public NetworkLinks: any;
  public PerformanceLinks: any;
  public CompetitiveInsightLinks: any;
  public VolumesLinks: any;
  public SupplyChainLinks;
  public CustomerCareLinks;
  public ConnectedServicesLinks;
  public ExecutiveLinks;
  public FleetLinks;
  public MVPLinks;
  public TSOLinks;
  public SalesAndMarketingLinks;
  public QualityLinks;
  public environment;
  public TextAnalyticsLinks;
  public PalantirLinks;
  public TechnicalServicesLinks;
  public DealerConnectLinks;
  public QlikSenseLinks;
  public isLoading: Subject<boolean> = this.loaderService.isLoading;
  leftIframeIsOpen = false;
  rightIframeIsOpen = false;

  @ViewChild('leftIframe', { static: true }) leftIframe;
  @ViewChild('rightIframe', { static: true }) rightIframe;

  rightIframeUrl: SafeResourceUrl;
  leftIframeUrl: SafeResourceUrl;
  iframeUrl = {};
  iframeWidth = 100;
  isAdmin = false;
  GlobalLinks: any;
  isMobile: boolean;
  CDPLinks: any;
  loader = true;
  constructor(
    public reportService: DataService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private drawerService: DrawerService,
    private loaderService: LoaderService,
    private userService: UserService,
    private titleService: Title,
    private domSanitizer: DomSanitizer
  ) {
    this.environment = environment;
  }

  ngOnInit() {
    this.titleService.setTitle(this.environment.mopar ? (this.environment.cx ? 'CX Gateway' : 'Mopar B.I. Gateway') : 'B.I. Gateway');
    this.subscription = this.reportService.getAllReports().subscribe(reports => { this.processReports(reports); });
    console.log(localStorage.getItem('isAdmin'))
    this.isAdmin = localStorage.getItem('isAdmin') === 'true';

    this.subscription = this.reportService.iframeUrl$.subscribe(iframeUrlChange => {
      this.setURL(iframeUrlChange)
    })
  }
  //code for NgOnDestroy 
  subscription: Subscription;
  ngOnDestroy() {
    this.subscription.unsubscribe();
    console.log('calling unsubscribe')
  }
  processReports(data) {
    this.reports = data;
    this.loadReports(data);
  }



  loadReports(reports: any) {
    this.DemandLinks = reports.filter(
      r => r.category === 'Demand'
    );
    this.NetworkLinks = reports.filter(
      r => r.category === 'Network'
    );

    this.CDPLinks = reports.filter(
      r => r.category === 'CDP'
    );
    this.PerformanceLinks = reports.filter(
      r => r.category === 'Performance'
    );
    this.CompetitiveInsightLinks = reports.filter(
      r => r.category === 'Competitive Insights'
    );
    this.VolumesLinks = reports.filter(
      r => r.category === 'Volumes'
    );
    this.CustomerCareLinks = reports.filter(
      r => r.category === 'Customer Care'
    )
    this.MVPLinks = reports.filter(
      r => r.category === 'MVP'
    )
    this.FleetLinks = reports.filter(
      r => r.category === 'Fleet'
    )
    this.ConnectedServicesLinks = reports.filter(
      r => r.category === 'Connected Srvc'
    )
    this.GlobalLinks = reports.filter(
      r => r.category === 'Global'
    )
    this.ExecutiveLinks = reports.filter(
      r => r.category === 'Executive'
    )
    this.SalesAndMarketingLinks = reports.filter(
      r => r.category === 'Sales & Mkt'
    )
    this.QualityLinks = reports.filter(
      r => r.category === 'Quality'
    )
    this.SupplyChainLinks = reports.filter(
      r => r.category === 'Supply Chain'
    )
    this.TSOLinks = reports.filter(
      r => r.category === 'TSO'
    )
    this.TechnicalServicesLinks = reports.filter(
      r => r.category === 'Technical Services'
    )
    this.QlikSenseLinks = reports.filter(
      r => r.category === 'Qlik Sense'
    )
    this.DealerConnectLinks = reports.filter(
      r => r.category === 'DealerConnect'
    )
    this.PalantirLinks = reports.filter(
      r => r.category === 'Palantir Tools'
    )
    this.TextAnalyticsLinks = reports.filter(
      r => r.category === 'Text Analytics'
    )
    this.loader = false;
  }

  searchReport(event: any) {
    const keyword = event.target.value.toLowerCase();
    const reportsCopy = [...this.reports];
    const searched = reportsCopy.filter(r =>
      r.category.toLowerCase().includes(event.target.value.toLowerCase()) ||
      r.name.toLowerCase().includes(keyword.trim())
    );
    this.loadReports(searched);
  }

  setURL(obj) {
    console.log('intialObje', obj);
    this.iframeUrl = obj;
    // alert(this.iframeUrl['url']);
    const { url, event, name } = obj;
    event?.preventDefault();
    this.titleService.setTitle(name);
    if (this.rightIframeIsOpen) {
      this.leftIframeUrl = (this.iframeUrl['url'] === undefined) ? this.iframeUrl : url;
      // this.leftIframeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
      this.leftIframeIsOpen = true;
    } else {
      this.rightIframeUrl = (this.iframeUrl['url'] === undefined) ? this.iframeUrl : url;
      // this.rightIframeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
      this.rightIframeIsOpen = true;
    }
    this.setIframeWidth();


  }

  toggleDrawer(contents) {
    (document.activeElement as HTMLElement).blur();
    this.drawerService.toggleDrawer(contents);
    (window as any).gtag('event', 'toggle drawer', {
      'event_category': 'toggle drawer',
      'event_label': contents
    });
  }

  editReport(report: Report, event: Event) {
    event.preventDefault();
    const dialogRef = this.dialog.open(ReportAdminComponent, {
      width: '480px',
      data: report
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result?.delete) {
        this.reportService.deleteReport(result.model);
      } else if (result) {
        this.reportService.editReport(result)
      }
    });
  }



  openBookmarkSnackBar() {
    const verticalPosition: MatSnackBarVerticalPosition = 'top';

    this.snackBar.open('We\'ve changed the url of BIG to dai.extra.fcagroup.com. Please update your bookmarks.', 'Close', {
      duration: 180 * 1000,
      verticalPosition
    });
  }

  openSnackBar() {
    this.snackBar.openFromComponent(RequestSnackbarComponent, {
      duration: 5 * 1000,
    });
  }

  requestAccess(owner, report, event) {
    event.preventDefault();
    this.openSnackBar();

    this.reportService.requestAccess(owner, report).subscribe();
  }

  // set iframe width
  setIframeWidth() {
    if (this.leftIframeIsOpen && this.rightIframeIsOpen) {
      this.iframeWidth = 50;
    } else {
      this.iframeWidth = 100;
    }
  }
  // remove left iframe
  closeLeftIframe() {
    this.leftIframeIsOpen = false;
    this.setIframeWidth();
  }
  // remove right iframe
  closeRightIframe() {
    this.rightIframeIsOpen = false;
    this.setIframeWidth();
    this.titleService.setTitle(this.environment.mopar ? (this.environment.cx ? 'CX Gateway' : 'Mopar B.I. Gateway') : 'B.I. Gateway');

  }


}


