import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-shipment-grid',
  templateUrl: './shipment-grid.component.html',
  styleUrls: ['./shipment-grid.component.scss']
})
export class ShipmentGridComponent implements OnInit {

  constructor() { }
  @Input() jeepGrid;
  ngOnInit(): void {
    // console.log('jeepGridData', this.jeepGrid);
  }

}
