import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { DrawerService } from 'src/app/services/drawer.service';
import { KPIService } from 'src/app/services/kpi.service';
import { LoaderService } from 'src/app/services/loader.service';
import { Subject } from 'rxjs';
import { toTitleCase } from 'src/string-helpers';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})

export class FilterComponent implements OnInit {


  public selectedNameplate;
  public selectedBrand;
  public BCValue;
  public announcement = 'test';
  public underperformingKPIs;
  public totalKPIs;
  public nameplateList = [];
  public brandList = [];
  public user = '';
  public allNameplate = {};
  public isLoading: Subject<boolean> = this.loaderService.isLoading;
  toTitleCase: any;
  public selectedBC: number = 10;
  public bcList: any;
  @Input() hideBC = false;
  includeEmail: string = 'false';
  getBcValue = [];
  changedBCValue = false;
  constructor(public dataService: DataService, private loaderService: LoaderService,
    private drawerService: DrawerService, public kpiService: KPIService, public userService: UserService) {
  }

  ngOnInit() {
    this.kpiService.getNameplates().subscribe(brandMap => this.setNameplates(brandMap));
    this.toTitleCase = toTitleCase;
    this.kpiService.getBCs().subscribe(bcs => this.setBCs(bcs));
    this.userService.getEmailPreference().subscribe(includeEmail => this.includeEmail = includeEmail?.includeEmail || 'false')
    // load user prefs this.userService

  }

  toggleDrawer() {

  }

  setNameplates(brandMap) {
    this.allNameplate = brandMap;
    Object.keys(brandMap).forEach(brand => {
      this.brandList = this.brandList.concat(brand);
    });
    this.selectedBrand = this.brandList[0];
    this.nameplateList = brandMap[this.selectedBrand];
    this.selectedNameplate = this.nameplateList[0];
    this.kpiService.setFilter(this.selectedBrand, this.selectedNameplate, this.selectedBC, this.includeEmail);


  }

  setBCs(bcs) {
    this.bcList = bcs;
    console.log('bcList', this.bcList);
    this.BCValue = this.bcList[9].n_bc_name;
    this.kpiService.setFilter(this.selectedBrand, this.selectedNameplate, this.selectedBC, this.includeEmail);

  }

  matchBcId(bc, id) {
    if (!bc || !id) {
      return false;
    }
    return bc === id;
  }
  onFilterChangedBC() {
    this.getBcValue = this.bcList.filter(obj => {
      return obj.n_bc_name === this.BCValue;
    });
    this.changedBCValue = true;
    this.updateKpi();
  }
  onFilterChanged() {
    this.updateKpi();
  }

  onBrandFilterChanged() {
    const brand = this.selectedBrand;
    this.nameplateList = this.allNameplate[brand];
    this.selectedNameplate = this.nameplateList[0];
    this.updateKpi();
  }

  updateKpi() {
    let BCValue = (this.changedBCValue) ? this.getBcValue[0].i_bc_id : 10;
    //this.kpiService.getNameplateTotalKPIs(this.selectedNameplate).subscribe(kpis => this.setNameplateTotalKPIs(kpis));
    if (this.selectedBC && this.selectedNameplate && this.includeEmail != null) {
      console.log('include email state', this.includeEmail)
      this.changedBCValue = false;
      this.kpiService.setFilter(this.selectedBrand, this.selectedNameplate, this.selectedBC, this.includeEmail);
    }
  }
}
