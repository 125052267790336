import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { ProvideFeedbackModalComponent } from "../provide-feedback-modal/provide-feedback-modal.component";
import { PopupTextComponent } from "../popup-text/popup-text.component";
import { StatusReportComponent } from "../status-report/status-report.component";

import { ReportAdminComponent } from "../report-admin/report-admin.component";
import { FeedbackService } from "./feedback.service";
import { DataService } from "src/app/services/data.service";
import { Router, NavigationEnd } from "@angular/router";
import { TrimViewComponent } from "../../Trim/trim-view/trim-view.component";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  providers: [MatDialog],
})
export class FooterComponent implements OnInit {
  currentYear: string;
  env: string;
  version: string;
  isAdmin: boolean;
  route = "";
  isMopar: boolean;
  isDAITeam: boolean;

  constructor(
    public dialog: MatDialog,
    public feedbackService: FeedbackService,
    public dataService: DataService,
    router: Router,
    private routeradmin: Router
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route = event.url;
      }
    });
  }

  ngOnInit() {
    this.currentYear = String(new Date().getFullYear());
    this.env = environment.envName === "prod" ? "version" : environment.envName;
    this.isMopar = !!environment.mopar;
    this.version = '2.0.2';
    this.isAdmin = localStorage.getItem("isAdmin") === "true";
    this.isDAITeam = localStorage.getItem("isDAITeam") === "true";
  }
  gotoAdmin() {
    if (this.isAdmin) {
      this.routeradmin.navigateByUrl("/admin");
    } else {
      alert("Admin only can view");
    }
  }
  gotoDashBoard() {
    this.routeradmin.navigateByUrl("/dashboard");
  }
  provideFeedback() {
    this.routeradmin.navigateByUrl("/ticket");

  }

  setPopupText() {
    const dialogRef = this.dialog.open(PopupTextComponent, {
      width: "400px",
      data: "",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result == "undefined") {
        return;
      }
      this.dataService.setPopupAlert(result).subscribe();
    });
  }

  addReport() {
    const dialogRef = this.dialog.open(ReportAdminComponent, {
      width: "480px",
      data: {},
    });
  }

  statusReport() {
    const dialogRef = this.dialog.open(StatusReportComponent, {
      width: "1000px",
      data: {},
    });
  }

  goldenTrim() {
    const dialogRef = this.dialog.open(TrimViewComponent, {
      disableClose: true,
      width: "1500px",
      maxHeight: "900px",
      data: {},
    });
  }
}
