import { Component, OnInit, Input } from "@angular/core";
import { capitalizeAcronyms, intelligentShortening, toTitleCase } from "src/string-helpers";
import { DrawerService } from "src/app/services/drawer.service";
import { DataService } from "src/app/services/data.service";
import { KPIService } from "src/app/services/kpi.service";
import Chart from "node_modules/chart.js";
import { ChartsModule } from "ng2-charts";

//import { bcMapping } from "src/bc-mapping";

@Component({
  selector: "app-kpi-grid",
  templateUrl: "./kpi-grid.component.html",
  styleUrls: ["./kpi-grid.component.scss"],
})
export class KpiGridComponent implements OnInit {
  brand;
  bc;
  nameplate;
  //chart = document.getElementById('myChart');

  myChart;

  @Input() underperformingData;
  @Input() inventory = false;
  @Input() suffix = 'ppt'
  capitalizeAcronyms = capitalizeAcronyms;
  intelligentShortening = intelligentShortening;
  top5: any;
  toTitleCase = toTitleCase;
  constructor(
    public drawerService: DrawerService,
    public dataService: DataService,
    public kpiService: KPIService
  ) {
    this.top5 = this.underperformingData?.values.slice(0, 5);

    this.kpiService.filter$.subscribe(({ brand, nameplate, bc }) => {
      this.brand = brand;
      this.nameplate = nameplate;
      this.bc = bc;
    });
  }

  ngOnInit() {
    this.myChart = new Chart("myChart", {
      type: "bar",
      data: {
        labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
        datasets: [
          {
            label: "# of Votes",
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
    this.chartColor();
  }

  getColor(value) {
    if (value > 0) return "green";
    if (value <= -30) return "red";
    if (value <= -10) return "orange";
    if (value < 0) return "yellow";
    return "black";
  }

  launchReport() {
    this.drawerService.toggleDrawer(null);
    let bcMap = [
      "",
      "CALIFORNIA",
      "DENVER",
      "GREAT LAKES",
      "MID ATLANTIC",
      "MIDWEST",
      "NORTHEAST",
      "SOUTHEAST",
      "SOUTHWEST",
      "WEST",
      "NATIONAL",
    ];
    let businessCenter = bcMap[this.bc];
    let link = `https://discovery-ba.extra.chrysler.com/QvAJAXZfc/opendoc.htm?document=marketing%20demand%5Cmarketing%20demand%20insights%20%26%20analytics.qvw&host
    QVS%40shmwp146}&select=LB111,${this.brand}&select=LB110,${this.nameplate}&select=LB113,${businessCenter}`;

    window.open(link, "_blank"); //making sure it launches
    // this.dataService.showReport(link);
  }

  chartColor() {
    if (this.brand == "RAM") return "rgba(128,128,128,.50)";

    if (this.brand == "JEEP") return "rgba(84,130,53,.50)";

    if (this.brand == "CHRYSLER" || "DODGE" || "ALPHA ROMEO" || "FIAT")
      return "rgba(255,0,0,.50)";
  }
}
