import { Component, OnInit, Input } from "@angular/core";
import { capitalizeAcronyms, intelligentShortening, toTitleCase } from "src/string-helpers";
import { DrawerService } from "src/app/services/drawer.service";
import { DataService } from "src/app/services/data.service";
import { KPIService } from "src/app/services/kpi.service";

//import { bcMapping } from "src/bc-mapping";

@Component({
  selector: "app-insight-pin-market-popup",
  templateUrl: "./insight-pin-market-popup.component.html",
  styleUrls: ["./insight-pin-market-popup.component.scss"],
})
export class InsightPinMarketPopupComponent implements OnInit {
  brand;
  //chart = document.getElementById('myChart');

  myChart;
  data;
  @Input() nameplate;
  @Input() bc;
  @Input() mom;
  @Input() salesType;
  loading = false;
  capitalizeAcronyms = capitalizeAcronyms;
  intelligentShortening = intelligentShortening;
  top5: any;
  toTitleCase = toTitleCase;
  constructor(
    public drawerService: DrawerService,
    public dataService: DataService,
    public kpiService: KPIService
  ) {
    this.kpiService.filter$.subscribe(({ brand, nameplate, bc }) => {
      this.brand = brand;
      this.nameplate = nameplate;
      this.bc = bc;
    });

  }

  ngOnInit() {
    this.load()
  }



  load() {
    if (!this.loading) {
      if (this.salesType == 'Lease') {
        this.kpiService.getPinMarketLeaseShare(this.nameplate, this.bc).subscribe((insights) => {
          this.data = insights;
        })
      } else if (this.salesType == 'Retail') {
        this.kpiService.getPinMarketRetailShare(this.nameplate, this.bc).subscribe((insights) => {
          this.data = insights;
        })
      } else {
        this.kpiService.getPinMarketShare(this.nameplate, this.bc).subscribe((insights) => {
          this.data = insights;
        })
      }
    }
    this.loading = true;

  }

  getColor(value) {
    if (value > 0) return "green";
    if (value <= -30) return "red";
    if (value <= -10) return "orange";
    if (value < 0) return "yellow";
    return "black";
  }

  launchReport() {
    this.drawerService.toggleDrawer(null);
    let bcMap = [
      "",
      "CALIFORNIA",
      "DENVER",
      "GREAT LAKES",
      "MID ATLANTIC",
      "MIDWEST",
      "NORTHEAST",
      "SOUTHEAST",
      "SOUTHWEST",
      "WEST",
      "NATIONAL",
    ];
    let businessCenter = bcMap[this.bc];
    let link = `https://discovery-ba.extra.chrysler.com/QvAJAXZfc/opendoc.htm?document=marketing%20demand%5Cmarketing%20demand%20insights%20%26%20analytics.qvw&host
    QVS%40shmwp146}&select=LB111,${this.brand}&select=LB110,${this.nameplate}&select=LB113,${businessCenter}`;

    window.open(link, "_blank"); //making sure it launches
    // this.dataService.showReport(link);
  }

  chartColor() {
    if (this.brand == "RAM") return "rgba(128,128,128,.50)";

    if (this.brand == "JEEP") return "rgba(84,130,53,.50)";

    if (this.brand == "CHRYSLER" || "DODGE" || "ALPHA ROMEO" || "FIAT")
      return "rgba(255,0,0,.50)";
  }
}
