<div>

  <filter></filter>
  <header class='header'>Management Review</header>
  <div class="iframe-holder">
    <div [style.width.%]="100" class="iframe-holder">

      <iframe title="idr" #idr height="400px" [src]="managementReviewUrl1 | safe: 'resourceUrl'" id="idr"></iframe>
    </div>
  </div>
  <div class="iframe-holder">
    <div [style.width.%]="100" class="iframe-holder">

      <iframe title="idr" #idr height="300px" [src]="managementReviewUrl2 | safe: 'resourceUrl'" id="idr"></iframe>
    </div>
  </div>
  <div class="iframe-holder">
    <div [style.width.%]="100" class="iframe-holder">

      <iframe title="idr" #idr height="250px" [src]="managementReviewUrl3 | safe: 'resourceUrl'" id="idr"></iframe>
    </div>
  </div>
  <div class="iframe-holder">
    <div [style.width.%]="100" class="iframe-holder">

      <iframe title="idr" #idr height="100px" [src]="managementReviewUrl4 | safe: 'resourceUrl'" id="idr"></iframe>
    </div>
  </div>
  <header class='header'>Nameplate Recap</header>

  <div class="iframe-holder">
    <div [style.width.%]="100" class="iframe-holder">

      <iframe title="idr" #idr height="800px" [src]="nameplateRecapUrl | safe: 'resourceUrl'" id="mdd"></iframe>
    </div>
  </div>
  <header class='header'>Share Walk</header>

  <div class="iframe-holder">

    <div [style.width.%]="100" class="iframe-holder">

      <iframe title="idr" #idr height="150px" [src]="shareWalkUrl | safe: 'resourceUrl'" id="test"></iframe>
    </div>
  </div>
  <header class='header'>MTD Share</header>

  <div class="iframe-holder">
    <div [style.width.%]="100" class="iframe-holder">

      <iframe title="idr" #idr height="650px" [src]="mtdShareUrl | safe: 'resourceUrl'" id="test"></iframe>
    </div>
  </div>
  <header class='header'>MoM Demand</header>

  <div class="iframe-holder">
    <div [style.width.%]="100" class="iframe-holder">

      <iframe title="idr" #idr height="900px" [src]="demandMoMUrl1 | safe: 'resourceUrl'" id="test"></iframe>
    </div>
  </div>
  <header class='header'>Profitability</header>

  <div class="iframe-holder">
    <div [style.width.%]="100" class="iframe-holder">

      <iframe title="idr" #idr height="900px" [src]="profitability | safe: 'resourceUrl'" id="test"></iframe>
    </div>
  </div>
  <header class='header'>Estimated Share</header>

  <div class="iframe-holder">
    <div [style.width.%]="100" class="iframe-holder">

      <iframe title="idr" #idr height="900px" [src]="estimatedShare | safe: 'resourceUrl'" id="test"></iframe>
    </div>
  </div>
</div>