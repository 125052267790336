<!--Nav Bar Content-->
<div class="navbar" *ngIf="!environment.mopar">
  <div class="subnav">
    <button class="subnavbtn">
      Demand <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of DemandLinks">
        <a *ngIf="link.AllowsIframe" target="_blank" (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>
  <div class="subnav">
    <button class="subnavbtn">
      Performance <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of PerformanceLinks">
        <a *ngIf="link.AllowsIframe" target="_blank" (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>

  <div class="subnav">
    <button class="subnavbtn">
      Competitive Insights <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of CompetitiveInsightLinks">
        <a *ngIf="link.AllowsIframe" target="_blank" (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>
  <div class="subnav">
    <button class="subnavbtn">
      Volume <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of VolumesLinks">
        <a *ngIf="link.AllowsIframe" target="_blank" (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>
  <div class="subnav">
    <button class="subnavbtn">
      Network <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of NetworkLinks">
        <a *ngIf="link.AllowsIframe" target="_blank" (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>

  <div class="mobile">

    <a mat-raised-button color="primary" href="/#/user-preferences" class="user-prefs">Settings</a>
    <!-- <a mat-raised-button color="primary" href="/#/user-preferences?tab=1" class="">Alerts</a> -->

    <button mat-raised-button (click)="toggleDrawer('State of Share');" color="primary" class="open-button"
      [disableRipple]="true">
      State of Share</button>
  </div>

  <div class="md-form search-bar">
    <input class="form-control search-bar" type="search" placeholder="Search" aria-label="Search"
      (input)="searchReport($event)" mdbInput>
  </div>
</div>
<div class="navbar" *ngIf="environment.mopar && !environment.cx">
  <div class="subnav">
    <button class="subnavbtn">
      Global <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of GlobalLinks">
        <a href={{link?.URL}} *ngIf="link.AllowsIframe" target="_blank"
          (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>
  <div class="subnav">
    <button class="subnavbtn">
      Connected Srvc <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of ConnectedServicesLinks">
        <a href={{link?.URL}} *ngIf="link.AllowsIframe" target="_blank"
          (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>
  <div class="subnav">
    <button class="subnavbtn">
      Customer Care <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of CustomerCareLinks">
        <a href={{link?.URL}} *ngIf="link.AllowsIframe" target="_blank"
          (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>

  <div class="subnav">
    <button class="subnavbtn">
      Executive <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of ExecutiveLinks">
        <a href={{link?.URL}} *ngIf="link.AllowsIframe" target="_blank"
          (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
      <a href='https://dai.extra.fcagroup.com/#/dashboard' target="_blank">Commercial B.I. Gateway</a>

    </div>
  </div>
  <div class="subnav">
    <button class="subnavbtn">
      Fleet <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of FleetLinks">
        <a href={{link?.URL}} *ngIf="link.AllowsIframe" target="_blank"
          (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>
  <div class="subnav">
    <button class="subnavbtn">
      MVP <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of MVPLinks">
        <a href={{link?.URL}} *ngIf="link.AllowsIframe" target="_blank"
          (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>
  <div class="subnav">
    <button class="subnavbtn">
      Quality <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of QualityLinks">
        <a href={{link?.URL}} *ngIf="link.AllowsIframe" target="_blank"
          (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>
  <div class="subnav">
    <button class="subnavbtn">
      Sales & Mkt <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of SalesAndMarketingLinks">
        <a href={{link?.URL}} *ngIf="link.AllowsIframe" target="_blank"
          (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>
  <div class="subnav">
    <button class="subnavbtn">
      Supply Chain <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of SupplyChainLinks">
        <a href={{link?.URL}} *ngIf="link.AllowsIframe" target="_blank"
          (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>
  <div class="subnav">
    <button class="subnavbtn">
      TSO <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of TSOLinks">
        <a href={{link?.URL}} *ngIf="link.AllowsIframe" target="_blank"
          (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>
  <div class="md-form search-bar search-bar-mopar">
    <input class="form-control search-bar" type="search" placeholder="Search" aria-label="Search"
      (input)="searchReport($event)" mdbInput>
  </div>
</div>

<div class="navbar" *ngIf="environment.mopar && environment.cx">
  <div class="subnav">
    <button class="subnavbtn">
      DealerConnect/FieldConnect <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of DealerConnectLinks">
        <a href={{link?.URL}} target="_blank" (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>
  <div class="subnav">
    <button class="subnavbtn">
      Qlik Sense Dashboard <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of QlikSenseLinks">
        <a href={{link?.URL}} target="_blank" (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>

  <div class="subnav">
    <button class="subnavbtn">
      Palantir Tools <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of PalantirLinks">
        <a href={{link?.URL}} target="_blank" (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>
  <div class="subnav">
    <button class="subnavbtn">
      Text Analytics & SOG <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of TextAnalyticsLinks">
        <a href={{link?.URL}} target="_blank" (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>
  <div class="md-form search-bar search-bar-mopar">
    <input class="form-control search-bar" type="search" placeholder="Search" aria-label="Search"
      (input)="searchReport($event)" mdbInput>
  </div>
  <div class="subnav">
    <button class="subnavbtn">
      Technical Services <em class="fa fa-caret-down"></em>
    </button>
    <div class="subnav-content">
      <ng-container *ngFor="let link of TechnicalServicesLinks">
        <a href={{link?.URL}} target="_blank" (click)="setURL(link?.URL, $event, link?.name)">{{link?.name}}</a>
      </ng-container>
    </div>
  </div>
</div>

<!-- dashboard grid icons -->

<div *ngIf="!environment.mopar" [hidden]="leftIframeIsOpen || rightIframeIsOpen" class="workstream-grid">
  <div id="demand" class="workstream">
    <h2 class="workstream-header">Demand</h2>
    <div class="grid-container">
      <div class="grid-icon three-column" *ngFor="let link of DemandLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>

  <div class="vertical-line"></div>

  <div id="performance" class="workstream">
    <h2 class="workstream-header">Performance</h2>
    <div class="grid-container">
      <div class="grid-icon three-column" *ngFor="let link of PerformanceLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div id="competitiveInsights" class="workstream">
    <h2 class="workstream-header">Competitive Insights</h2>
    <div class="grid-container">
      <div class="grid-icon" *ngFor="let link of CompetitiveInsightLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>

  <div id="volumes" class="workstream">
    <h2 class="workstream-header">Volume</h2>
    <div class="grid-container">
      <div class="grid-icon" *ngFor="let link of VolumesLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>

  <div id="network" class="workstream">
    <h2 class="workstream-header">Network</h2>
    <div class="grid-container">
      <div class="grid-icon" *ngFor="let link of NetworkLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>

  <div id="network" class="workstream">
    <h2 class="workstream-header">Customer</h2>
    <div class="grid-container">
      <!-- one-column -->
      <div class="grid-icon" [ngClass]="!isMobile ? 'one-column' : '' " *ngFor="let link of CDPLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
</div>

<div *ngIf="environment.mopar && !environment.cx" [hidden]="leftIframeIsOpen || rightIframeIsOpen"
  class="workstream-grid">
  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">Global</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of GlobalLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">Connected Srvc</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of ConnectedServicesLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>

  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">Customer Care</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of CustomerCareLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">Executive</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of ExecutiveLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">Fleet</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of FleetLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>

  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">MVP</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of MVPLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>

  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">Quality</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of QualityLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>

  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">Sales & Mkt</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of SalesAndMarketingLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>

  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">Supply Chain</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of SupplyChainLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>

  <div class="workstream">
    <h2 class="workstream-header workstream-header-mopar">TSO</h2>
    <div class="grid-container">
      <div class="grid-icon " *ngFor="let link of TSOLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
</div>

<div *ngIf="environment.mopar && environment.cx" [hidden]="leftIframeIsOpen || rightIframeIsOpen"
  class="workstream-grid">
  <div class="workstream cx">
    <h2 class="workstream-header workstream-header-mopar">DealerConnect/FieldConnect</h2>
    <div class="grid-container">
      <div class="grid-icon three-column" *ngFor="let link of DealerConnectLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="workstream cx">
    <h2 class="workstream-header workstream-header-mopar">Qlik Sense Dashboard</h2>
    <div class="grid-container">
      <div class="grid-icon three-column" *ngFor="let link of QlikSenseLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="workstream cx">
    <h2 class="workstream-header workstream-header-mopar">Palantir Tools</h2>
    <div class="grid-container">
      <div class="grid-icon three-column" *ngFor="let link of PalantirLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="workstream cx">
    <h2 class="workstream-header workstream-header-mopar">Text Analytics and SOG</h2>
    <div class="grid-container">
      <div class="grid-icon three-column" *ngFor="let link of TextAnalyticsLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="workstream cx">
    <h2 class="workstream-header workstream-header-mopar">Technical Services</h2>
    <div class="grid-container">
      <div class="grid-icon three-column" *ngFor="let link of TechnicalServicesLinks">
        <app-report-link [link]="link" [isAdmin]="isAdmin"></app-report-link>
      </div>
    </div>
  </div>
</div>
<app-spinner-overlay overlay="true" *ngIf="isLoading | async">
</app-spinner-overlay>

<app-spinner-overlay overlay="true" *ngIf="loader">
</app-spinner-overlay>
<span style="clear: both;"></span>

<div class="dual-iframe-holder">
  <div [style.width.%]="iframeWidth" class="iframe-holder" style="float:left">
    <button (click)="closeLeftIframe()" [hidden]="!leftIframeIsOpen" mat-flat-button id="IdrButton"
      class="iframe-close-button">
      Close
    </button>

    <iframe title="leftIframe" *ngIf="leftIframeIsOpen" #leftIframe height="800px"
      [src]="leftIframeUrl | safe: 'resourceUrl'" id="IDR"></iframe>
  </div>
  <div class="iframe-spacer"></div>
  <div [style.width.%]="iframeWidth" class="iframe-holder" style="float:right">
    <button (click)="closeRightIframe()" [hidden]="!rightIframeIsOpen" mat-flat-button id="MddButton"
      class="iframe-close-button">
      Close
    </button>
    <iframe title="rightIframe" *ngIf="rightIframeIsOpen" #rightIframe height="800px"
      [src]="rightIframeUrl | safe: 'resourceUrl'" id="MDD"></iframe>
  </div>
</div>