import { Injectable, Injector } from "@angular/core";
import { Subject, Observable, Subscription } from "rxjs";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { retryWithBackoff } from "src/rxjs-helpers";
import { catchError } from "rxjs/operators";
import { tap } from "underscore";
@Injectable({
  providedIn: "root",
})
export class DataService {
  name: string;
  userID: string;
  userEmail: string;
  brand: string;
  apiUrl: string;

  link: string;
  prod: boolean;
  public name$ = new Subject<string>();
  public userID$ = new Subject<string>();
  public iframeUrl$ = new Subject();
  public reports$ = new Subject<any[]>();
  private http = this.injector.get(HttpClient);

  constructor(private injector: Injector) {
    this.apiUrl = environment.apiUrl;
  }

  getAllReports(): Subject<any[]> {
    this.http
      .get<any[]>(this.apiUrl + "api/reports")
      .pipe(
        retryWithBackoff(400, 5, 1000),
        catchError((error) => {
          console.error(error);
          return null;
        })
      )
      .subscribe((reports) => this.reports$.next(reports));

    return this.reports$;
  }

  logError(error = ""): Observable<any> {
    const err = { name: error };
    const errorLink = this.apiUrl + "api/logs";
    return this.http.post(errorLink, err);
  }

  getJobLogs(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + "api/job_logs").pipe(
      retryWithBackoff(400, 5, 1000),
      catchError((error) => {
        console.error(error);
        return null;
      })
    );
  }

  getStatusReport(month, dropdown): Observable<any[]> {
    const options = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type', 'application/json')
    return this.http.get<any[]>(environment.apiUrl + 'api/StatusReport/' + dropdown + '/' + month, { headers: options })
  }
  // getStatusReportNew(): Observable<any[]> {
  //   // return this.http.get<any[]>(
  //   //   this.apiUrl + "api/statusReportNew", { observe: "response" }
  //   // );
  //   const options = new HttpHeaders()
  //     .set('Access-Control-Allow-Origin', '*')
  //     .set('Content-Type', 'application/json')
  //   return this.http.get<any[]>(environment.apiUrl + "api/statusReportNew", { headers: options })
  // }
  getSalesTempo(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + "api/salesTempo").pipe(
      retryWithBackoff(400, 5, 1000),
      catchError((error) => {
        console.error(error);
        return null;
      })
    );
  }

  getSuccessfulJobLogs(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + "api/successful_job_logs").pipe(
      retryWithBackoff(400, 5, 1000),
      catchError((error) => {
        console.error(error);
        return null;
      })
    );
  }

  getPopupAlert(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + "api/popup_text").pipe(
      retryWithBackoff(400, 5, 1000),
      catchError((error) => {
        console.error(error);
        return null;
      })
    );
  }

  setPopupAlert(text): Observable<any[]> {
    return this.http.post<any[]>(this.apiUrl + "api/popup_text", { text }).pipe(
      retryWithBackoff(400, 5, 1000),
      catchError((error) => {
        console.error(error);
        return null;
      })
    );
  }

  setIframeUrl(url: string, event: Event, name: string) {
    this.iframeUrl$.next({ url, event, name });
  }

  requestAccess(report: string, owner: string) {
    return this.http.post<any[]>(
      `${environment.apiUrl}api/reports/requestaccess`,
      {
        owner,
        email: JSON.parse(localStorage.getItem("msalUser")).idTokenClaims.email,
        tid: JSON.parse(localStorage.getItem("msalUser")).idTokenClaims
          .unique_name,
        report,
        name: JSON.parse(localStorage.getItem("msalUser")).idTokenClaims
          .given_name,
      }
    );
  }

  // const headers = new Headers({ 'Content-Type': 'application/json' });

  addReport(body): Subscription {
    return this.http
      .post(this.apiUrl + "api/reports", body)
      .subscribe((response) => {
        this.http
          .get<any[]>(this.apiUrl + "api/reports")
          .pipe(
            retryWithBackoff(400, 5, 1000),
            catchError((error) => {
              console.error(error);
              return null;
            })
          )
          .subscribe((reports) => {
            this.reports$.next(reports);
          });
      });
  }

  editReport(body): Subscription {
    return this.http
      .put(this.apiUrl + "api/reports", body)
      .subscribe((response) => {
        this.http
          .get<any[]>(this.apiUrl + "api/reports")
          .pipe(
            retryWithBackoff(400, 5, 1000),
            catchError((error) => {
              console.error(error);
              return null;
            })
          )
          .subscribe((reports) => {
            this.reports$.next(reports);
          });
      });
  }

  deleteReport(body): Subscription {
    return this.http
      .delete(this.apiUrl + `api/reports/${body.id}`)
      .subscribe((response) => {
        this.http
          .get<any[]>(this.apiUrl + "api/reports")
          .pipe(
            retryWithBackoff(400, 5, 1000),
            catchError((error) => {
              console.error(error);
              return null;
            })
          )
          .subscribe((reports) => {
            this.reports$.next(reports);
          });
      });
  }
  getIconLibrary(): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>(this.apiUrl + "api/icons", {
      observe: "response",
    });
  }
  getJeepGlobal(
    region: string = "global",
    date: string = "202201"
  ): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>(
      this.apiUrl + "api/jeepglobal/" + region + "/" + date,
      { observe: "response" }
    );
  }
  getKpi(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + "api/kpi/");
  }

}
