import { Component, OnInit, Input } from "@angular/core";
import { capitalizeAcronyms, intelligentShortening, toTitleCase } from "src/string-helpers";
import { DrawerService } from "src/app/services/drawer.service";
import { DataService } from "src/app/services/data.service";
import { KPIService } from "src/app/services/kpi.service";

//import { bcMapping } from "src/bc-mapping";

@Component({
  selector: "app-insight-leads-breakdown-popup",
  templateUrl: "./insight-leads-breakdown-popup.component.html",
  styleUrls: ["./insight-leads-breakdown-popup.component.scss"],
})
export class InsightLeadsBreakdownPopupComponent implements OnInit {
  brand;

  data;
  @Input() nameplate;
  @Input() bc;
  @Input() mom;
  @Input() salesType;
  loading = false;
  capitalizeAcronyms = capitalizeAcronyms;
  intelligentShortening = intelligentShortening;
  top5: any;

  toTitleCase = toTitleCase;
  constructor(
    public drawerService: DrawerService,
    public dataService: DataService,
    public kpiService: KPIService
  ) {
    this.kpiService.filter$.subscribe(({ brand, nameplate, bc }) => {
      this.brand = brand;
      this.nameplate = nameplate;
      this.bc = bc;
    });

  }
  ngOnInit() {
    this.load()
  }

  load() {

    this.kpiService.getLeadsBreakdown(this.nameplate, this.bc).subscribe((insights) => {
      this.data = insights;
    })
  }

}
