<div class="modal-wrapper">
  <button mat-button class="close-icon" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
  <a
    href='https://discovery-ba.extra.chrysler.com/QvAJAXZfc/opendoc.htm?document=commercial%20analytics%5Crefreshdata.qvw&lang=en-US&host=QVS%40shmwp146'>Qlikview
    Status</a>
  <iframe *ngIf="showIframe" width='1000px' height='600px'
    src='https://discovery-ba.extra.chrysler.com/QvAJAXZfc/opendoc.htm?document=commercial%20analytics%5Crefreshdata.qvw&lang=en-US&host=QVS%40shmwp146'></iframe>
  <a
    href='https://discovery-ba.extra.chrysler.com/QvAJAXZfc/opendoc.htm?document=commercial%20analytics%5Crefreshdata.qvw&lang=en-US&host=QVS%40shmwp146'>
    Direct Link to Status</a>
  <!-- Direct Link to Status -->
  <mat-form-field class="showMonthClass">
    <mat-label>Select Source</mat-label>
    <mat-select (selectionChange)="selectPipeline($event)">
      <mat-option *ngFor="let obj of pipeLineName" [value]="obj.value">
        {{obj.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div *ngIf="!!model && srcOld">
    <table>
      <tr *ngFor="let status of sqlData" [ngClass]="getColor(status.status, status.last_update)">
        <th>{{status.pipeline_name}}</th>
        <td>
          {{status.status}}
        </td>
        <td>
          {{status.last_update}}</td>
      </tr>
    </table>
  </div>
  <div *ngIf="!!model && !srcOld">

    <mat-form-field class="showMonthClass">
      <mat-label>Select Month</mat-label>
      <mat-select (selectionChange)="dropDownChange($event)">
        <mat-option *ngFor="let obj of monthName" [value]="obj.value">
          {{obj.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="recordsClass">
      <mat-label>No.of Records</mat-label>
      <mat-select (selectionChange)="dropDownChangeArray($event)">
        <mat-option *ngFor="let obj of recordsArray" [value]="obj">
          {{obj}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <table id="statusId">
      <tr>
        <th>Pipeline Name</th>
        <th>Source Job Status</th>
        <th>Source Job Timing</th>
        <th>Datalake Job Timing/Start Time</th>
        <th>Datalake Job Timing/End Time</th>
        <th>Datalake Job Status</th>
        <th>Cadence</th>
        <th>Average Datalake Job time</th>
        <th>Amount of records updated</th>
      </tr>

      <tr *ngFor="let obj of model | slice:0:selectRecordsCount" [ngClass]="getColorSf(obj.SF_TABLE_LOAD_STATUS)">

        <td>{{obj.DAG_ID}}</td>
        <td> {{obj.SF_TABLE_LOAD_STATUS}} </td>
        <td *ngIf="obj.DAG_START_DATE !== null"> {{getTimeFn(obj.DAG_START_DATE)}}</td>
        <td *ngIf="obj.DAG_START_DATE !== null">{{getTimeFn(obj.DAG_START_DATE)}}</td>
        <td *ngIf="obj.SF_TABLE_LOAD_TIME !== null">{{getTimeFn(obj.SF_TABLE_LOAD_TIME)}}</td>
        <td> {{obj.SF_TABLE_LOAD_STATUS}} </td>
        <td *ngIf="obj.DAG_SCHEDULE_TIMING !== 'None'">{{obj.DAG_SCHEDULE_TIMING}} {{obj.DAG_SCHEDULE_FREQUENCY}}</td>
        <!-- <td *ngIf="obj.DAG_SCHEDULE !== 'None'"> {{getDagSch(obj.DAG_SCHEDULE)}}</td> -->
        <td *ngIf="obj.SF_TABLE_LOAD_TIME !== null || obj.DAG_START_DATE !== null">
          {{getAvgTime(obj.DAG_START_DATE,obj.SF_TABLE_LOAD_TIME,obj)}}
        </td>
        <td>{{obj.RECORDS_UPDATED_DURING_RUN | number}}</td>
      </tr>
      <div class="noRecords" *ngIf="model.length === 0">No Records Exists</div>
    </table>

  </div>
  <app-spinner-overlay overlay="true" *ngIf="loader">
  </app-spinner-overlay>
</div>