<div class="kpi-wrapper">
  <!--<div class="main-header" *ngIf="announcement">
    <img src="https://st11798p010.blob.core.windows.net/$web/assets/images/logo_blue.jpg" />
    <div class="main-header__heading">Hello {{ user }}</div>
    <div>Welcome to the initial release of BIG Insights</div>
    <div class="main-header__updates"
      title='National dmas are chosen from the top 50 dmas, while inside a bc we show the top 5 dmas. Both are prioritized by month to date or year to date greatest volume fall based on the toggle button.'>
      How we sort</div>
  </div>
-->
  <!---->
  <div class="kpi-group-wrapper" [ngClass]="{'kpi-filter-wrapper': contents == 'State of Share'}">
    <filter [hideBC]="contents === 'State of Share'"> </filter>
    <div class='filter-margin'
      [ngClass]="contents == 'State of Share' ? 'filter-margin-problemModal' : 'filter-margin'">

      <div class='toggle-button-select'>
        <mat-icon svgIcon='help-circle' class='help-circle'
          title='National DMAs are chosen from the top 50 DMAs, while inside a BC we show the top 5 DMAs. Both are prioritized by month to date or year to date greatest volume fall based on the toggle button.'>
        </mat-icon>
        <mat-button-toggle-group #toggleGroup="matButtonToggleGroup" value="MoM" (change)="toggleSwitch($event.value)">
          <mat-button-toggle (change)="toggleSwitch($event.value)" value="MoM">MoM</mat-button-toggle>
          <mat-button-toggle (change)="toggleSwitch($event.value)" value="YoY">YoY</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <button mat-raised-button color="primary"
        [ngClass]="contents == 'State of Share' ? 'close-kpi' : 'close-kpi-problemModel'" class="close-kpi"
        (click)="toggleDrawer(null)">
        Close
      </button>
      <button mat-raised-button color="primary" *ngIf="contents == 'State of Share'" class="close-kpi"
        (click)="export(null)" [disabled]="pendingCount > 0">
        Export
      </button>
      <!-- <button  mat-raised-button color="primary" [ngClass]="{'kpi-filter-wrapper': contents !== 'State of Share'}"  class="close-kpi-problemModel" (click)="toggleDrawer(null)">
        Close
      </button> -->
    </div>
  </div>

  <div class='kpi-insight-wrapper' *ngIf="insights && contents == 'State of Share'">
    <app-insight-grid [insights]="insights" [mom]='buttonState'></app-insight-grid>-

  </div>

  <div class="kpi-grid-wrapper" *ngIf="underperformingKPIs && contents == 'Problem Board'">
    <app-kpi-grid [underperformingData]="underperformingKPIs.sales" [suffix]='"%"'></app-kpi-grid>
    <app-kpi-grid [underperformingData]="underperformingKPIs.lfas" [suffix]='"%"'></app-kpi-grid>
    <app-kpi-grid [underperformingData]="underperformingKPIs.visits" [suffix]='"%"'></app-kpi-grid>
    <app-kpi-grid [underperformingData]="underperformingKPIs.VDPVisits" [suffix]='"%"'></app-kpi-grid>
    <app-kpi-grid [underperformingData]="underperformingKPIs.leads" [suffix]='"%"'></app-kpi-grid>
    <app-kpi-grid [underperformingData]="underperformingKPIs.shareOfSegment"></app-kpi-grid>
    <app-kpi-grid [underperformingData]="underperformingKPIs.visitsToLFAsConversion">
    </app-kpi-grid>
    <app-kpi-grid [underperformingData]="underperformingKPIs.visitsToBrandSiteHevConversion">
    </app-kpi-grid>
    <app-kpi-grid [underperformingData]='underperformingKPIs.leaseMix'></app-kpi-grid>
    <app-kpi-grid [underperformingData]='underperformingKPIs.inventory' [inventory]=true [suffix]='" days"'>
    </app-kpi-grid>
    <app-kpi-grid [underperformingData]='underperformingKPIs.comboConversion' [inventory]=false>
    </app-kpi-grid>
    <app-kpi-grid [underperformingData]='underperformingKPIs.inventoryByTrim' [inventory]=true [suffix]='" days"'>
    </app-kpi-grid>
  </div>
  <app-spinner-overlay overlay="true" *ngIf="isLoading | async">
  </app-spinner-overlay>
</div>