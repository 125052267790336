import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { DrawerService } from 'src/app/services/drawer.service';
import { environment } from 'src/environments/environment';
import { TemporaryAlertComponent } from '../temporary-alert/temporary-alert.component';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  providers: [MatDialog]
})
export class DrawerComponent implements OnInit, OnDestroy {
  opened: boolean;
  contents: string = null;
  environment;
  constructor(private drawerService: DrawerService, public dialog: MatDialog, private dataService: DataService) {
    this.environment = environment;
  }

  ngOnInit() {
    this.opened = this.drawerService.opened;
    this.drawerService.opened$.subscribe(({ opened, contents }) => {
      this.opened = opened;
      this.contents = contents;
    });
    this.dataService.getPopupAlert().subscribe(popup_text => {
      if (popup_text && popup_text[0]?.alert) {
        const dialogRef = this.dialog.open(TemporaryAlertComponent, {
          width: '400px',
          data: { alert: popup_text[0].alert }
        });
      }
    })

  }

  ngOnDestroy() {
    //this.drawerService.opened$.unsubscribe();
  }

}
