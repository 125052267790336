import { Component, OnInit, Input } from '@angular/core';
import { capitalizeAcronyms, intelligentShortening } from 'src/string-helpers';
@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss']
})
export class KpiComponent implements OnInit {

  @Input() titleLength;
  @Input() kpiData;
  capitalizeAcronyms = capitalizeAcronyms;
  intelligentShortening: any;
  constructor() {
    this.intelligentShortening = intelligentShortening;
  }

  ngOnInit() {
  }

  getColor(value) {
    if (value > 0) return 'green';
    if (value <= -30) return 'red';
    if (value <= -10) return 'orange';
    if (value < 0) return 'yellow';
    return 'black';
  }

}
