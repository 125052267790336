import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;

  }

  postFeedback(feedback): Observable<any[]> {
      if (!feedback) {
      return null;
    }
    return this.http.post<any[]>(this.apiUrl + 'api/feedback', feedback);
  }
}
