export function toTitleCase(str) {
  if (!str) return ''

  return str.replace(
    /\b\w+/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export function capitalizeAcronyms(s) {
  let str = s

  if (!str) return ''
  str = str.replace(
    /\blfa/g,
    'LFA'
  )

  str = str.replace(
    /\bvdp/g,
    'VDP'
  );
  str = str.replace(
    " lfa",
    ' LFA'
  )

  str = str.replace(
    " Hev",
    ' HEV'
  )


  str = str.replace(
    " Lfa",
    ' LFA'
  )

  str = str.replace(
    ' vdp',
    ' VDP'
  );
  str = str.replace(
    ' Vdp',
    ' VDP'
  );

  return str;
}

export function intelligentShortening(length, str) {
  if (!str) return ''
  str = str.replace('-', ' ')
  let strArray = str.split(' ');
  str = strArray.shift();
  while (strArray.length) {
    if (strArray[0].length + str.length < length) {
      str = str + " " + strArray.shift()
    } else {
      str = str + "..."
      break;
    }
  }
  return str
}