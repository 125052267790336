import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/services/error.service';
@Injectable()

export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private errorService: ErrorService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          // debugger;
          if (error.url.includes("/api/users/newUser/") || error.status === 401) {
            return;
            // Suppress token refresh
          } else if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
            window.alert(errorMessage);

            return throwError(error);

          } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            window.alert(errorMessage);

            return throwError(error);
          }
          window.alert(errorMessage);
          return throwError(errorMessage);
        })
      );
  }
}