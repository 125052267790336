<div class="kpi">
  <div class="kpi-titles">
    <span class="kpi-title"
      title={{kpiData?.title}}>{{kpiData?.title?.toLowerCase() ? intelligentShortening(titleLength, capitalizeAcronyms(kpiData?.title?.toLowerCase())) : ""}}</span>
    <!--{{kpiData?.subtitle?.toLowerCase() ? capitalizeAcronyms(kpiData?.subtitle?.toLowerCase()) : ""}}-->
  </div>
  <div class="kpi-data">
    <span
      [ngClass]="getColor(kpiData.value)">{{kpiData.value?.toFixed(2) ? kpiData.value?.toFixed(2) + "%" : "No Data"}}</span>
  </div>
</div>