import { Component, OnInit, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Pipe } from '@angular/core';
import { KPIService } from 'src/app/services/kpi.service';

@Component({
  selector: 'ppd',
  templateUrl: './ppd.component.html',
  styleUrls: ['./ppd.component.scss'],
  providers: [Pipe]
})
export class PricePaidDistributionComponent implements OnInit {

  constructor(public kpiService: KPIService) {

  }

  ngOnInit() {

  }


}
