<div fxLayout fxLayoutAlign="center center" class="filterClass" fxLayoutGap="20px">
  <mat-form-field class="showMonthClass">
    <mat-label>Select column</mat-label>
    <mat-select (selectionChange)="columnName($event)">
      <mat-option *ngFor="let obj of columnsArray" [value]="obj.key">
        {{obj.key}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <input matInput type="text" (keyup)="doFilter($event.target)" placeholder="Filter">
  </mat-form-field>
</div>
<!-- <input type="file" [multiple]="multiple" #fileInput class="browse-btn" (change)="incomingfile($event)" accept=".xlsx"> -->
<!-- <input type="file" style="display: inline-block;" (change)="incomingfile($event)" placeholder="Upload file"
  accept=".xlsx">
<button type="button" class="btn btn-info btn-lg" (click)="fileUpload()">Upload</button> -->
<!-- <button type="button" class="btn btn-info" (click)="Upload()">Upload</button> -->
<article class="table-header">

  <button *ngIf="IsRemoveAllEnabled" class="button-remove-rows" mat-button (click)="removeSelectedRows()">
    Remove Rows
  </button>

  <button class="button-add-row" mat-button (click)="addRow('true')">Add Row</button>
</article>
<table mat-table [dataSource]="dataSource" matSort>
  <ng-container [matColumnDef]="col.key" *ngFor="let col of columnsSchema">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ col.label }}
    </th>
    <td mat-cell *matCellDef="let element" (keyup)="columKeyUp(element,col)">
      <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
        <ng-container *ngSwitchCase="'isSelected'">
          <mat-checkbox (change)="updateRemoveAllButton(element, $event)"
            (ngModelChange)="updateRemoveAllButton(element, $event)"></mat-checkbox>
        </ng-container>
        <div class="btn-edit" *ngSwitchCase="'isEdit'">
          <button mat-button
            (click)="element.isEdit = !element.isEdit && addRowEvent = true && insterRow = true && isDisabled = true;getEditData(element)">
            Edit
          </button>
          <button *ngIf="!IsRemoveAllEnabled" mat-button class="button-remove" (click)="removeRow(element.Id)">
            Delete
          </button>
        </div>
        <span *ngSwitchCase="'date'">
          {{ element[col.key] | date: 'mediumDate' }}
        </span>
        <span *ngSwitchDefault>
          {{ element[col.key] }}
        </span>
      </div>
      <div [ngSwitch]="col.type" *ngIf="element.isEdit">
        <div *ngSwitchCase="'isSelected'"></div>
        <div class="btn-edit" *ngSwitchCase="'isEdit'">
          <button mat-button [disabled]="isDisabled" (click)="editRow(element)">
            Done
          </button>
          <button mat-button (click)="cancelEdit(element)">
            Cancel
          </button>
        </div>
        <mat-form-field class="form-input" *ngSwitchCase="'date'" appearance="fill">
          <mat-label>Choose a date</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="element[col.key]" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="form-input" *ngSwitchDefault>
          <input [type]="col.type" matInput [(ngModel)]="element[col.key]" />
        </mat-form-field>
      </div>
      <ng-container *ngIf="addRowEvent && i_dlr_dim && col.key === 'I_DLR_DIM'">
        <span *ngIf="element.I_DLR_DIM == ''" class="error-class">*Required</span>
      </ng-container>
      <ng-container *ngIf="addRowEvent && IDR_DealerName && col.key === 'IDR_dealername'">
        <span *ngIf="element.IDR_dealername == ''" class="error-class">*Required</span>
        <!-- <span *ngIf="element.IDR_dealername.length > 128 " class="error-class">Maximum character is 128</span> -->
      </ng-container>
      <ng-container *ngIf="addRowEvent && dealer_id_is && col.key === 'dealer_id_is'">
        <span *ngIf="element.dealer_id_is == ''" class="error-class">*Required</span>
        <!-- <span *ngIf="element.IDR_dealername.length > 128 " class="error-class">Maximum character is 128</span> -->
      </ng-container>
      <ng-container *ngIf="addRowEvent && seller_name && col.key === 'seller_name_ss'">
        <span *ngIf="element.seller_name_ss == ''" class="error-class">*Required</span>
        <!-- <span *ngIf="element.seller_name_ss.length > 128 " class="error-class">Maximum character is 128</span> -->
      </ng-container>
      <ng-container *ngIf="addRowEvent && street && col.key === 'street_ss'">
        <span *ngIf="element.street_ss == ''" class="error-class">*Required</span>
        <!-- <span *ngIf="element.street_ss.length > 128 " class="error-class">Maximum character is 128</span> -->
      </ng-container>
      <ng-container *ngIf="addRowEvent && zip && col.key === 'zip_is'">
        <span *ngIf="element.zip_is == ''" class="error-class">*Required</span>
        <!-- <span *ngIf="element.zip_is.length > 128 " class="error-class">Maximum character is 128</span> -->
      </ng-container>
      <ng-container *ngIf="addRowEvent && state && col.key === 'state_ss'">
        <span *ngIf="element.state_ss == ''" class="error-class">*Required</span>
        <!-- <span *ngIf="element.state_ss.length > 128 " class="error-class">Maximum character is 128</span> -->
      </ng-container>
      <ng-container *ngIf="addRowEvent && city && col.key === 'city_ss'">
        <span *ngIf="element.city_ss == ''" class="error-class">*Required</span>
        <!-- <span *ngIf="element.city_ss.length > 128 " class="error-class">Maximum character is 128</span> -->
      </ng-container>
      <ng-container *ngIf="addRowEvent && car_city && col.key === 'car_city_ss'">
        <!-- <span *ngIf="element.car_city_ss == ''" class="error-class">*Required</span> -->
        <!-- <span *ngIf="element.car_city_ss.length > 128 " class="error-class">Maximum character is 128</span> -->
      </ng-container>
      <ng-container *ngIf="addRowEvent && group_flag && col.key === 'group_flag'">
        <!-- <span *ngIf="element.group_flag == ''" class="error-class">*Required</span> -->
        <!-- <span *ngIf="element.group_flag.length > 128 " class="error-class">Maximum character is 128</span> -->
      </ng-container>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator #scheduledOrdersPaginator showFirstLastButtons="firstLastButtons" [length]="20"
  [pageSizeOptions]="[20,30,50,100]"></mat-paginator>
<div class="overlay" *ngIf="IsWait">
  <div class="center">
    <mat-progress-spinner mode="indeterminate" diameter=100>
    </mat-progress-spinner>
  </div>
</div>